
export const portefeuille = {
    name: "bp6",
    object: "BusinessProject",
    category: {
        path: "technical",
        icon: "layers"
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            "country",
            {path: "customer", width: 200},
            {path: "typeOfOffer", width: 300},
            {path: "fullName", tKey: 'name', width: 400},
            {path: "description", width: 300, initiallyNotVisible: true},
            {path: "responseMode", initiallyNotVisible: true},
            {path: "beginYear", width: 80},
            {path: "originOfTheCase", initiallyNotVisible: true},
            {path: "specialFollowUp", initiallyNotVisible: true},

            {path: "businessRevueCurrentTurnOver", width: 80, tooltip: true},
            {path: "expectedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumCommittedNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},
            {path: "sumActualNumberOfDays", width: 80, tooltip: true, initiallyNotVisible: true},

            {path: "technicalTeamMember", display: "fullName", width: 150},
            {
                path: "commercialTeamMember",
                display: "fullName",
                width: 150,
                initiallyNotVisible: true
            },
            {path: "referentInSupport", width: 100, initiallyNotVisible: true},
            {path: "projectManager", width: 150, initiallyNotVisible: true},
            {path: "projectTeam", width: 150, initiallyNotVisible: true},
            {path: "nextDeadLine", width: 150, initiallyNotVisible: true},
            {path: "alert", width: 150, initiallyNotVisible: true},
            {path: "currentFunctions", width: 100},
            {path: "statusAndOrder", width: 100, initiallyNotVisible: true},

            {path: "file", tKey: "FA"}
        ],
        form: {
            fields: [
                "identifier",
                {path: "typeOfOffer"},
                {path: "name", writable: false},
                {path: "commercialTeamMember", display: "fullName", filters: ["commercialReferent"], writable: false},
                {path: "technicalTeamMember", display: "fullName", filters: ["technicalReferent"]},
                "referentInSupport",
                "projectManager",
                {path: "projectTeam", type: "textarea"},
                "businessRevueCurrentTurnOver",
                "expectedTurnOver",

                "nextDeadLine",
                "alert",

                {path: "operationsAndServicesContracts", type: 'creatableTags', disabled: true},
                {path: "comments", editable: false},
                {path: "files", editable: false},

                {path: "workflow", hidden: true},

                {path: "businessProject", hidden: true}
            ],
            buttons: [{type: "cancel"}]
        }
    },
    filters: [
        "byZone",
        "byWorkflowStepPF"
    ]
}
