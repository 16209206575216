const _ = require('lodash')
const moment = require('moment')
const staticEntities = require('./staticEntities')
const Errors = require('../../utils/Errors').default
const { generateExcel } = require('./extractionExcel')
const { setFieldVisibility} = require("../../../apps/KpModule/actions")
const { getStatusAndOrder } = require("./workflow/workflow")


const transformRichText = richText => {
    if(!richText) return ""
    const hexToString = {
        '27': "'"
    }
    const text = richText.replace(/(<([^>]+)>)/gi, "")
    return text.replace(/&#x([a-fA-F0-9]+);/g, function(match, code) {
        return hexToString[code];
    })
}
const getFieldsModuleForm = (element, fieldPaths, module) => {
    return fieldPaths.map(fieldPath => (
        module.viewMap.form.fields.find(
            field => field.path === element
        ).viewMap.form.fields.find(
            field => field.path === fieldPath
        )
    ))
}

const valueSubscription = (display) => ({
    onChange: (newValue, oldValue, {formInitialize, module, store}) => {
        if(newValue) {
            const valueField = getFieldsModuleForm(
                'criteria',
                ['value'],
                module
            )

            if(_.isArray(newValue)) {
                valueField[0].setValue(newValue.map(o => o[display] || o.name || o).join(', '))
            } else if(_.isObject(newValue)) {
                valueField[0].setValue(newValue[display] || newValue.name)
            } else {
                valueField[0].setValue(newValue)
            }
        }
    }
})

export const entities = [
    {
        name: 'BooleansList',
        type: "static",
        fields: [{path: 'id', type: 'string'}, 'name'],
        objects: [
            {id: 'true', name: 'true'},
            {id: 'false', name: 'false'},
        ]
    },
    {
        name: 'CriteriaAxes',
        type: "static",
        fields: [{path: 'id', type: 'string'}, 'name'],
        objects: [
            {id: 'zone', name: 'zone'},
            {id: 'country', name: 'country'},
            {id: 'countryType', name: 'countryType'},
            {id: 'securityLevel', name: 'countrySecurity'},
            {id: 'typeOfOffer', name: 'typeOfOffer'},
            {id: 'typeOfOfferPriority', name: 'typeOfOfferPriority'},
            {id: 'rangeOfOffer', name: 'rangeOfOffer'},
            {id: 'client', name: 'client'},
            {id: 'clientRelationship', name: 'clientRelationship'},
            {id: 'partner', name: 'partner'},
            {id: 'subcontractor', name: 'subcontractor'},
            {id: 'competitor', name: 'competitor'},
            {id: 'partnerRelationship', name: 'partnerRelationship'},
            {id: 'subcontractorRelationship', name: 'subcontractorRelationship'},
            {id: 'competitorRelationship', name: 'competitorRelationship'},
            {id: 'operationsAndServicesContract', name: 'operationsAndServicesContract'},
            {id: 'beginYear', name: 'beginYear'},
            {id: 'opportunity', name: 'opportunity'},
            {id: 'resourceType', name: 'resourceType'},
            {id: 'responseMode', name: 'responseMode'},
            {id: 'projectFunding', name: 'projectFunding'},
            {id: 'compensationMode', name: 'compensationModes'},
            {id: 'interventionMode', name: 'interventionMode'},
            {id: 'contractualSchema', name: 'contractualSchema'},
            {id: 'originOfTheCase', name: 'originOfTheCase'},
            {id: 'technicalTeamMember', name: 'technicalTeamMember'},
            {id: 'commercialTeamMember', name: 'commercialTeamMember'},
            {id: 'risk', name: 'risk'},
            {id: 'needLocalStructure', name: 'needLocalStructure'},
            {id: 'completedService', name: 'completedService'},
            {id: 'allPaymentsReceived', name: 'allPaymentsReceived'},
            {id: 'closingDocumentsReceived', name: 'closingDocumentsReceived'},
            {id: 'specialFollowUp', name: 'specialFollowUp'},

            {id: 'creationDate', name: 'creationDate'},
            {id: 'offerSubmissionDate', name: 'offerSubmissionDate'},
            {id: "effectiveOfferSubmissionDate", name: "effectiveOfferSubmissionDate"},
            {id: "offerAcceptedDate", name: "offerAcceptedDate"},
            {id: "estimatedResponseDate", name: "estimatedResponseDate"},
            {id: "reportedSubmissionDate", name: "reportedSubmissionDate"},
            {id: 'signatureDate', name: 'signatureDate'},
            {id: 'revueDate', name: 'revueDate'},
            {id: 'beginningOfRealisationDate', name: 'beginningOfRealisationDate'},


            {id: 'detectionTurnOver', name: 'detectionTurnOver'},
            {id: 'buildOfferTurnOver', name: 'buildOfferTurnOver'},
            {id: 'contractualizationTurnOver', name: 'contractualizationTurnOver'},
            {id: 'totalExpectedTurnOver', name: 'totalExpectedTurnOver'},
            {id: 'expectedNumberOfDays', name: 'expectedNumberOfDays'},
            {id: 'sumCommittedNumberOfDays', name: 'sumCommittedNumberOfDays'},
            {id: 'sumActualNumberOfDays', name: 'sumActualNumberOfDays'},
            {id: 'marginOverContract', name: 'marginOverContract'},
            {id: 'expectedContractMarginInPercent', name: 'expectedContractMarginInPercent'},

            {id: 'businessRevueCurrentTurnOver', name: 'businessRevueCurrentTurnOver'},
            {id: 'businessRevueExecutedTurnOver', name: 'businessRevueExecutedTurnOver'},
            {id: 'businessRevueCurrentYearProbability', name: 'businessRevueCurrentYearProbability'},
            {id: 'businessRevueNextYearTurnover', name: 'businessRevueNextYearTurnover'},

            {id: 'sumActualBilled', name: 'totalSumActualBilled'},
            {id: 'sumActualCashed', name: 'totalSumActualCashed'},
            {id: 'sumActualTurnOver', name: 'totalSumActualTurnover'},
            {id: 'expectedContractCost', name: 'totalExpectedContractCost'},
            {id: 'sumActualContractExpenses', name: 'totalSumActualContractExpenses'},
            {id: 'sumCommittedExpenses', name: 'totalSumCommittedExpenses'},



            {id: 'estimatedNumberOfDays', name: 'estimatedNumberOfDaysForRealisation'},
            {id: 'buildOfferNumberOfDays', name: 'buildOfferNumberOfDaysBuildOffer'},
            {id: 'globalTurnover', name: 'globalTurnover'},

            {id: 'step', name: 'step'},
            {id: 'projectManager', name: 'projectManager'}
        ]
    },
    {
        name: 'MongodbOperator',
        type: "static",
        fields: [{path: 'id', type: 'string'}, 'name'],
        objects: [
            {id: '$eq', name: 'égal'},
            {id: '$ne', name: 'inégal'},
            {id: '$lt', name: 'inférieur'},
            {id: '$lte', name: 'inférieur ou égal'},
            {id: '$gt', name: 'supérieur'},
            {id: '$gte', name: 'supérieur ou égal'},
            {id: '$in', name: 'dans'},
            //{id: '$regex', name: 'regex'}
        ]
    },
    {
        name: 'Criteria',
        fields: [
            {path: 'axis', type: 'CriteriaAxes', nullable: false},
            {path: 'country', type: 'Country', nullable: true},
            {path: 'countries', type: 'Country', link: 'MTM', nullable: true},
            {path: 'countryType', type: 'CountryType', nullable: true},
            {path: 'countryTypes', type: 'CountryType', link: 'MTM', nullable: true},
            {path: 'securityLevel', type: 'SecurityLevel', nullable: true},
            {path: 'securityLevels', type: 'SecurityLevel', link: 'MTM', nullable: true},
            {path: 'zone', type: 'Zone', nullable: true},
            {path: 'zones', type: 'Zone', link: 'MTM', nullable: true},
            {path: 'typeOfOffer', type: 'TypeOfOffer', nullable: true},
            {path: 'typesOfOffer', type: 'TypeOfOffer', link: 'MTM', nullable: true},
            {path: 'typeOfOfferPriority', type: 'BooleansList', nullable: true},
            {path: 'rangeOfOffer', type: 'RangeOfOffer', nullable: true},
            {path: 'rangesOfOffer', type: 'RangeOfOffer', link: 'MTM', nullable: true},
            {path: 'client', type: 'Customer', nullable: true},
            {path: 'clients', type: 'Customer', link: 'MTM', nullable: true},
            {path: 'clientRelationship', type: 'CustomerRelation', nullable: true},
            {path: 'clientRelationships', type: 'CustomerRelation', link: 'MTM', nullable: true},
            {path: 'partner', type: 'Provider', nullable: true},
            {path: 'partners', type: 'Provider', link: 'MTM', nullable: true},
            {path: 'partnerRelationship', type: 'ProviderRelation', nullable: true},
            {path: 'partnerRelationships', type: 'ProviderRelation', link: 'MTM', nullable: true},
            {path: 'subcontractor', type: 'Provider', nullable: true},
            {path: 'subcontractors', type: 'Provider', link: 'MTM', nullable: true},
            {path: 'subcontractorRelationship', type: 'ProviderRelation', nullable: true},
            {path: 'subcontractorRelationships', type: 'ProviderRelation', link: 'MTM', nullable: true},
            {path: 'competitor', type: 'Provider', nullable: true},
            {path: 'competitors', type: 'Provider', link: 'MTM', nullable: true},
            {path: 'competitorRelationship', type: 'ProviderRelation', nullable: true},
            {path: 'competitorRelationships', type: 'ProviderRelation', link: 'MTM', nullable: true},
            {path: 'operationsAndServicesContract', type: 'ContractMonitoring', nullable: true},
            {path: 'operationsAndServicesContracts', type: 'ContractMonitoring', link: 'MTM', nullable: true},
            {path: "beginYear", type: "Year", nullable: true},
            {path: 'beginYears', type: 'Year', link: 'MTM', nullable: true},
            {path: 'opportunity', type: 'Opportunity', nullable: true},
            {path: 'opportunities', type: 'Opportunity', link: 'MTM', nullable: true},
            {path: 'resourceType', type: 'ResourceType', nullable: true},
            {path: 'resourceTypes', type: 'ResourceType', link: 'MTM', nullable: true},
            {path: 'responseMode', type: 'ResponseMode', nullable: true},
            {path: 'responseModes', type: 'ResponseMode', link: 'MTM', nullable: true},
            {path: 'projectFunding', type: 'ProjectFunding', nullable: true},
            {path: 'projectFundings', type: 'ProjectFunding', link: 'MTM', nullable: true},
            {path: 'compensationMode', type: 'CompensationMode', nullable: true},
            {path: 'compensationModes', type: 'CompensationMode', link: 'MTM', nullable: true},
            {path: 'interventionMode', type: 'InterventionMode', nullable: true},
            {path: 'interventionModes', type: 'InterventionMode', link: 'MTM', nullable: true},
            {path: 'contractualSchema', type: 'ContractualSchema', nullable: true},
            {path: 'contractualSchemas', type: 'ContractualSchema', link: 'MTM', nullable: true},
            {path: 'originOfTheCase', type: 'OriginOfTheCase', nullable: true},
            {path: 'originsOfTheCase', type: 'OriginOfTheCase', link: 'MTM', nullable: true},
            {path: 'technicalTeamMember', type: 'TeamMember', nullable: true},
            {path: 'technicalTeamMembers', type: 'TeamMember', link: 'MTM', nullable: true},
            {path: 'commercialTeamMember', type: 'TeamMember', nullable: true},
            {path: 'commercialTeamMembers', type: 'TeamMember', link: 'MTM', nullable: true},
            {path: 'risk', type: 'Risk', nullable: true},
            {path: 'risks', type: 'Risk', link: 'MTM', nullable: true},
            {path: 'needLocalStructure', type: 'BooleansList', nullable: true},
            {path: 'completedService', type: 'BooleansList', nullable: true},
            {path: 'allPaymentsReceived', type: 'BooleansList', nullable: true},
            {path: 'closingDocumentsReceived', type: 'BooleansList', nullable: true},
            {path: 'specialFollowUp', type: 'BooleansList', nullable: true},

            {path: 'creationDate', type: 'date', nullable: true},
            {path: 'offerSubmissionDate', type: 'date', nullable: true},
            {path: "effectiveOfferSubmissionDate", type: 'date', nullable: true},
            {path: "offerAcceptedDate", type: 'date', nullable: true},
            {path: "estimatedResponseDate", type: 'date', nullable: true},
            {path: "reportedSubmissionDate", type: 'date', nullable: true},
            {path: 'signatureDate', type: 'date', nullable: true},
            {path: 'revueDate', type: 'date', nullable: true},
            {path: 'beginningOfRealisationDate', type: 'date', nullable: true},

            {path: 'detectionTurnOver', type: 'decimal', nullable: true},
            {path: 'buildOfferTurnOver', type: 'decimal', nullable: true},
            {path: 'contractualizationTurnOver', type: 'decimal', nullable: true},
            {path: 'totalExpectedTurnOver', type: 'decimal', nullable: true},
            {path: 'expectedNumberOfDays', type: 'decimal', nullable: true},
            {path: 'marginOverContract', type: 'MarginOverContract', nullable: true},
            {path: 'marginsOverContract', type: 'MarginOverContract', link: 'MTM', nullable: true},
            {path: 'expectedContractMarginInPercent', type: 'decimal', nullable: true},

            {path: 'sumActualBilled', type: 'decimal', nullable: true},
            {path: 'sumActualCashed', type: 'decimal', nullable: true},
            {path: 'sumActualTurnOver', type: 'decimal', nullable: true},
            {path: 'expectedContractCost', type: 'decimal', nullable: true},
            {path: 'sumActualContractExpenses', type: 'decimal', nullable: true},
            {path: 'sumCommittedExpenses', type: 'decimal', nullable: true},

            {path: 'businessRevueCurrentTurnOver', type: 'decimal', nullable: true},
            {path: 'businessRevueExecutedTurnOver', type: 'decimal', nullable: true},
            {path: 'businessRevueCurrentYearProbability', type: 'decimal', nullable: true},
            {path: 'businessRevueNextYearTurnover', type: 'decimal', nullable: true},

            {path: 'estimatedNumberOfDays', type: 'decimal', nullable: true},
            {path: 'buildOfferNumberOfDays', type: 'decimal', nullable: true},

            {path: 'globalTurnover', type: 'decimal', nullable: true},

            {path: 'step', type: 'ProjectStep', nullable: true},
            {path: 'steps', type: 'ProjectStep', link: 'MTM', nullable: true},
            {path: 'projectManager', nullable: true},

            {path: 'operator', type: 'MongodbOperator', nullable: false},
            'value'
        ]
    },
    {
        name: 'Extraction',
        facets: [
            "files", "comments",
            {name: "files", linkType: "OTO", path: "lastExtraction"}
        ],
        fields: [
            'code',
            {path: 'generateFile', type: 'boolean'},
            {path: 'owner', type: 'User'},
            {path: 'user', type: 'User', nullable: true},
            {path: 'shareWith', type: 'TeamMember', link: 'MTM', nullable: true},
            'date',
            {path: 'criteria', type: 'Criteria', link: 'OTM'},
            {
                path: "isOwner",
                ps: {object: [function(isOwner, object) {
                        const context = this.options.context;
                        return object.owner && object.owner.toString() === context.user.id
                    }]
                }
            },
        ],
        filters: [
            {
                name: 'isOwnerOrSharedWith',
                async: true,
                isDefault: true,
                query: function(context, callback) {
                    const userId = _.get(context, "user.id")
                    const groupId = _.get(context, "group.id")

                    global.app.I.TeamMember.collection.find({
                        user: new global.ObjectID(userId),
                        group: new global.ObjectID(groupId)
                    }).toArray(
                        (error, teamMembers) => {
                            const ids = teamMembers.map(team => team._id)

                            callback(
                                error,
                                userId
                                    ? {$or: [{owner: new global.ObjectID(userId)}, {shareWith: {$elemMatch: {$in: ids}} }]}
                                    : {}
                            );
                        });
                },
            }
        ],
        beforeSave: function (newObject, oldObject, context, callback) {
            const action  = context.restAction && context.restAction.crudType
            if(action === 'C') {
                newObject.owner = _.pick(context.user, ['id', 'name'])
            }

            newObject.user = _.pick(context.user, ['id', 'name'])
            newObject.date = moment().format("YYYY-MM-DD HH:mm")

            if(newObject.generateFile) {
                const fieldPaths = {
                    'country': {path: 'country._id', multi: 'countries'},
                    'countryType': {path: 'country.countryType', multi: 'countryTypes', static: true, entity: 'CountryType'},
                    'securityLevel': {path: 'country.securityLevel', multi: 'securityLevels', static: true, entity: 'SecurityLevel'},
                    'zone': {path: 'country.zone', multi: 'zones'},
                    'typeOfOffer': {path: 'typeOfOffer._id', multi: 'typesOfOffer'},
                    'typeOfOfferPriority': {path: 'typeOfOffer.priority', multi: 'priorities', static: true, boolean: true},
                    'rangeOfOffer': {path: 'typeOfOffer.rangeOfOffer', multi: 'rangesOfOffer'},
                    'client': {path: 'customer._id', multi: 'clients'},
                    'clientRelationship': {path: 'customer.customerRelation', multi: 'clientRelationships', static: true, entity: 'CustomerRelation'},
                    'partner': {path: 'partners._id', multi: 'partners'},
                    'subcontractor': {path: 'subcontractors._id', multi: 'subcontractors'},
                    'competitor': {path: 'competitors._id', multi: 'competitors'},
                    'partnerRelationship': {path: 'partners.providerRelations', multi: 'partnerRelationships', static: true, entity: 'ProviderRelation'},
                    'subcontractorRelationship': {path: 'subcontractors.providerRelations', multi: 'subcontractorRelationships', static: true, entity: 'ProviderRelation'},
                    'competitorRelationship': {path: 'competitors.providerRelations', multi: 'competitorRelationships', static: true, entity: 'ProviderRelation'},
                    'operationsAndServicesContract': {path: 'contracts._id', multi: 'operationsAndServicesContracts'},
                    'beginYear': {path: 'beginYear', multi: 'beginYears', static: true},
                    'opportunity': {path: 'opportunitys', multi: 'opportunities', static: true, entity: 'Opportunity'},
                    'resourceType': {path: 'resourceTypes', multi: 'resourceTypes', static: true, entity: 'ResourceType'},
                    'responseMode': {path: 'responseMode', multi: 'responseModes', static: true, entity: 'ResponseMode'},
                    'projectFunding': {path: 'projectFunding', multi: 'projectFundings', static: true, entity: 'ProjectFunding'},
                    'compensationMode': {path: 'compensationModes', multi: 'compensationModes', static: true, entity: 'CompensationMode'},
                    'interventionMode': {path: 'interventionMode', multi: 'interventionModes', static: true, entity: 'InterventionMode'},
                    'contractualSchema': {path: 'contractualSchema', multi: 'contractualSchemas', static: true, entity: 'ContractualSchema'},
                    'originOfTheCase': {path: 'originOfTheCase', multi: 'originsOfTheCase', static: true, entity: 'OriginOfTheCase'},
                    'technicalTeamMember': {path: 'technicalTeamMember._id', multi: 'technicalTeamMembers'},
                    'commercialTeamMember': {path: 'commercialTeamMember._id', multi: 'commercialTeamMembers'},
                    'risk': {path: 'risk', multi: 'risks', static: true},
                    'needLocalStructure': {path: 'needLocalStructure', static: true, boolean: true},
                    'completedService': {path: 'completedService', static: true, boolean: true},
                    'allPaymentsReceived': {path: 'allPaymentsReceived', static: true, boolean: true},
                    'closingDocumentsReceived': {path: 'closingDocumentsReceived', static: true, boolean: true},
                    'specialFollowUp': {path: 'specialFollowUp', static: true, boolean: true},
                    'marginOverContract': {path: 'marginOverContract', multi: 'marginsOverContract', static: true, entity: 'MarginOverContract'},
                    'step': {path: 'workflow.step', multi: 'steps', static: true, entity: 'ProjectStep'},

                    'businessRevueCurrentTurnOver': {path: 'lastBusinessRevue.revised', decimal: true},
                    'businessRevueExecutedTurnOver': {path: 'lastBusinessRevue.executedTurnOver', decimal: true},
                    'businessRevueCurrentYearProbability': {path: 'lastBusinessRevue.yearP', decimal: true},
                    'businessRevueNextYearTurnover': {path: 'lastBusinessRevue.yearNPlusOne', decimal: true},

                }
                const match = newObject.criteria.reduce((acc, criteria) => {
                    const axisId = criteria.axis.id
                    const operatorId = criteria.operator.id
                    let query
                    if(Object.keys(fieldPaths).includes(axisId)) {
                        if(acc[fieldPaths[axisId].path] && acc[fieldPaths[axisId].path][operatorId]) {
                            return callback(new Errors.ValidationError(`le couple axe opérateur doit être unique `))
                        }
                        const elementaryQuery = {
                            [operatorId]: operatorId === '$in'
                                ? criteria[fieldPaths[axisId].multi].map(elem => fieldPaths[axisId].static ? elem.id : new global.ObjectID(elem.id))
                                : fieldPaths[axisId].decimal
                                    ? criteria[axisId]
                                    : fieldPaths[axisId].boolean
                                        ? criteria[axisId].id === 'true'
                                        : fieldPaths[axisId].static
                                            ? criteria[axisId].id
                                            : new global.ObjectID(criteria[axisId].id)
                        }
                        const finalElementaryQuery = fieldPaths[axisId].elemMatch
                            ? {$elemMatch: elementaryQuery}
                            : elementaryQuery
                        query = {
                            [fieldPaths[axisId].path]: {
                                ...acc[fieldPaths[axisId].path],
                                ...finalElementaryQuery
                            }
                        }
                    } else {
                        if(acc[axisId] && acc[axisId][operatorId]) {
                            return callback(new Errors.ValidationError(`le couple axe opérateur doit être unique `))
                        }
                        const elementaryQuery = operatorId === '$regex'
                            ? {$regex: criteria[axisId], $options: 'i'}
                            : {[operatorId]: criteria[axisId]}
                        query = {
                            [axisId]: {
                                ...acc[axisId],
                                ...elementaryQuery
                            }
                        }
                    }
                    return {...acc, ...query}
                }, {})

                const countryCollectionName = global.app.I.Country.collectionName
                const zoneCollectionName = global.app.I.Zone.collectionName
                const typeOfOfferCollectionName = global.app.I.TypeOfOffer.collectionName
                const rangeOfOfferCollectionName = global.app.I.RangeOfOffer.collectionName
                const customerCollectionName = global.app.I.Customer.collectionName
                const providerCollectionName = global.app.I.Provider.collectionName
                const contractCollectionName = global.app.I.ContractMonitoring.collectionName
                const teamMemberCollectionName = global.app.I.TeamMember.collectionName
                const businessRevueCollectionName = global.app.I.BusinessRevue.collectionName
                const billingFollowUpCollectionName = global.app.I.BillingFollowUp.collectionName
                const executedFollowUpCollectionName = global.app.I.ExecutedFollowUp.collectionName
                const projectComponentCollectionName = global.app.I.ProjectComponent.collectionName
                global.app.I.BusinessProject.collection
                    .aggregate([
                        {
                            $lookup: {
                                from: businessRevueCollectionName,
                                let: { bp: "$_id"},
                                pipeline: [
                                    { $match: { $expr: { $eq: ['$businessProject', '$$bp'] }}},
                                    { $sort: { date: 1 }},
                                    { $project: {
                                            revised: 1,
                                            executedTurnOver: 1,
                                            yearNPlusOne: 1,
                                            yearN: 1,
                                            probability: 1,
                                            orderMonth: 1,
                                            comment: 1,
                                            yearP: { $multiply: [ "$yearN", "$probability", 1/100 ] },
                                            revisedP: { $multiply: [ "$revised", "$probability", 1/100 ] }
                                        }}
                                ],
                                as: 'businessRevues'
                            }
                        },
                        {
                            $lookup: {
                                from: billingFollowUpCollectionName,
                                let: { bp: "$_id"},
                                pipeline: [
                                    {
                                        $match: {
                                            $expr:
                                                { $and:
                                                        [
                                                            {$eq: ['$businessProject', '$$bp']},
                                                            {$ne: ['$date', null]}
                                                        ]
                                                }
                                        }
                                    },
                                ],
                                as: 'billingFollowUps'
                            }
                        },
                        {
                            $lookup: {
                                from: billingFollowUpCollectionName,
                                let: { bp: "$_id"},
                                pipeline: [
                                    {
                                        $match: {
                                            $expr:
                                                { $and:
                                                        [
                                                            {$eq: ['$businessProject', '$$bp']},
                                                            {$ne: ['$paymentdate', null]}
                                                        ]
                                                }
                                        }
                                    },
                                ],
                                as: 'billingFollowUpsCashed'
                            }
                        },
                        {
                            $lookup: {
                                from: executedFollowUpCollectionName,
                                localField: '_id',
                                foreignField: 'businessProject',
                                as: 'executedFollowUps'
                            }
                        },
                        {
                            $lookup: {
                                from: projectComponentCollectionName,
                                localField: '_id',
                                foreignField: 'businessProject',
                                as: 'projectComponents'
                            }
                        },
                        {
                            $lookup: {
                                from: countryCollectionName,
                                localField: 'country',
                                foreignField: '_id',
                                as: 'country'
                            }
                        },
                        {
                            $lookup: {
                                from: typeOfOfferCollectionName,
                                localField: 'typeOfOffer',
                                foreignField: '_id',
                                as: 'typeOfOffer'
                            }
                        },
                        {
                            $lookup: {
                                from: customerCollectionName,
                                localField: 'customer',
                                foreignField: '_id',
                                as: 'customer'
                            }
                        },
                        {
                            $lookup: {
                                from: providerCollectionName,
                                localField: 'partners',
                                foreignField: '_id',
                                as: 'partners'
                            }
                        },
                        {
                            $lookup: {
                                from: providerCollectionName,
                                localField: 'subcontractors',
                                foreignField: '_id',
                                as: 'subcontractors'
                            }
                        },
                        {
                            $lookup: {
                                from: providerCollectionName,
                                localField: 'competitors',
                                foreignField: '_id',
                                as: 'competitors'
                            }
                        },
                        {
                            $lookup: {
                                from: contractCollectionName,
                                localField: '_id',
                                foreignField: 'businessProjects',
                                as: 'contracts'
                            }
                        },
                        {
                            $lookup: {
                                from: teamMemberCollectionName,
                                localField: 'technicalTeamMember',
                                foreignField: '_id',
                                as: 'technicalTeamMember'
                            }
                        },
                        {
                            $lookup: {
                                from: teamMemberCollectionName,
                                localField: 'commercialTeamMember',
                                foreignField: '_id',
                                as: 'commercialTeamMember'
                            }
                        },
                        {
                            $project: {
                                code: 1,
                                name: 1,
                                description: 1,
                                developerPitch: 1,
                                developerPitch2: 1,
                                businessProject: 1,
                                lastBusinessRevue: { $arrayElemAt: ['$businessRevues', -1] },
                                billingFollowUps: 1,
                                sumActualBilled: { $sum: "$billingFollowUps.amount" },
                                sumActualCashed: { $sum: "$billingFollowUpsCashed.amount" },
                                sumActualTurnOver: { $sum: "$executedFollowUps.executedTurnOver"},
                                sumActualNumberOfDays: { $sum: "$executedFollowUps.notedNumberOfDays"},
                                sumActualContractExpenses: { $sum: "$executedFollowUps.notedExpenses"},
                                sumCommittedExpenses: { $sum: "$projectComponents.cost"},
                                sumCommittedNumberOfDays: { $sum: "$projectComponents.numberOfDays"},
                                opportunitys: 1,
                                referentInSupport: 1,
                                responseMode: 1,
                                projectFunding: 1,
                                resourceTypes: 1,
                                interventionMode: 1,
                                compensationModes: 1,
                                originOfTheCase: 1,
                                contractualSchema: 1,
                                contracts: 1,
                                securityLevel: 1,
                                globalTurnover: 1,
                                country: 1,
                                typeOfOffer: 1,
                                customer: 1,
                                partners: 1,
                                competitors: 1,
                                subcontractors: 1,
                                creationDate: 1,
                                beginYear: 1,
                                technicalTeamMember: 1,
                                commercialTeamMember: 1,
                                risk: 1,
                                needLocalStructure: 1,
                                specialFollowUp: 1,
                                marginOverContract: 1,
                                financialStatement: 1,
                                expectedNumberOfDays: '$financialStatement.numberOfDays.total',
                                expectedContractMarginInPercent: '$financialStatement.margeOnContractInPercent.total',
                                expectedContractCost: '$financialStatement.chargeOnContract.total',
                                detectionTurnOver: '$financialStatementDetection.turnOver.total',
                                buildOfferTurnOver: '$financialStatementBuildOffer.turnOver.total' ,
                                contractualizationTurnOver: '$financialStatementContractualization.turnOver.total',
                                estimatedNumberOfDays: 1,
                                buildOfferNumberOfDays: 1,
                                offerSubmissionDate: 1,
                                effectiveOfferSubmissionDate: 1,
                                offerAcceptedDate: 1,
                                estimatedResponseDate: 1,
                                reportedSubmissionDate: 1,
                                signatureDate: 1,
                                revueDate: 1,
                                projectManager: 1,
                                projectTeam: 1,
                                beginningOfRealisationDate: 1,
                                nextDeadLine: 1,
                                alert: 1,
                                completedService: 1,
                                allPaymentsReceived: 1,
                                closingDocumentsReceived: 1,
                                workflow: 1,
                                totalExpectedTurnOver: {
                                    $cond: {
                                        if: {$gt: [ "$financialStatement.turnOver.total", 0 ]},
                                        then: "$financialStatement.turnOver.total",
                                        else: "$estimatedTurnover"
                                    }
                                }
                            }
                        },
                        {$match: match},
                        {
                            $lookup: {
                                from: zoneCollectionName,
                                localField: 'country.zone',
                                foreignField: '_id',
                                as: 'zone'
                            }
                        },
                        {
                            $lookup: {
                                from: rangeOfOfferCollectionName,
                                localField: 'typeOfOffer.rangeOfOffer',
                                foreignField: '_id',
                                as: 'rangeOfOffer'
                            }
                        }
                    ])
                    .toArray()
                    .then(
                        projects => {
                            moment.locale("fr")

                            const staticObjects = staticEntities.reduce((acc, entity) => {
                                return {
                                    ...acc,
                                    [entity.name]: entity.objects.reduce((acc, object) => ({...acc, [object.id]: object.name || object.id}) ,{})
                                }
                            }, {})
                            const fieldGetters = {
                                zone: project => `${project.zone[0].code}-${project.zone[0].name}`,
                                country: project => `${project.country[0].code}-${project.country[0].name}`,
                                countryType: project => staticObjects[fieldPaths.countryType.entity][project.country[0].countryType],
                                project: project => `${project.code}-${project.name}`,
                                description: project => transformRichText(project.description),
                                detectionDeveloperPitch: project => transformRichText(project.developerPitch),
                                buildOfferDeveloperPitch: project => transformRichText(project.developerPitch2),
                                countrySecurity: project => staticObjects[fieldPaths.securityLevel.entity][project.country[0].securityLevel],
                                rangeOfOffer: project => `${project.rangeOfOffer[0].code}-${project.rangeOfOffer[0].name}`,
                                typeOfOffer: project => `${project.typeOfOffer[0].code}-${project.typeOfOffer[0].name}`,
                                typeOfOfferPriority: project => project.typeOfOffer[0].priority,
                                client: project => project.customer[0] && `${project.customer[0].code}-${project.customer[0].name}`,
                                clientRelationship: project => project.customer[0] && staticObjects[fieldPaths.clientRelationship.entity][project.customer[0].customerRelation],
                                contracts: project => project.contracts && project.contracts.map(contract => contract.contractFullId).join(', '),
                                beginYear: project => project.beginYear,
                                step: project => staticObjects[fieldPaths.step.entity][project.workflow.step],
                                opportunities: project => project.opportunitys && project.opportunitys.map(opportunity => staticObjects[fieldPaths.opportunity.entity][opportunity]).join(', '),
                                relatedToProject: project => project.businessProject,
                                securityRisk: project => project.securityLevel,
                                resourceTypes: project => project.resourceTypes && project.resourceTypes.map(resourceType => staticObjects[fieldPaths.resourceType.entity][resourceType]).join(', '),
                                responseMode: project => project.responseMode && staticObjects[fieldPaths.responseMode.entity][project.responseMode],
                                offerSubmissionDate: project => project.offerSubmissionDate && moment(project.offerSubmissionDate).format('YYYY-MM-DD'),
                                reportedSubmissionDate: project => project.reportedSubmissionDate && moment(project.reportedSubmissionDate).format('YYYY-MM-DD'),
                                effectiveOfferSubmissionDate: project => project.effectiveOfferSubmissionDate && moment(project.effectiveOfferSubmissionDate).format('YYYY-MM-DD'),
                                estimatedResponseDate: project => project.estimatedResponseDate && moment(project.estimatedResponseDate).format('YYYY-MM-DD'),
                                offerAcceptedDate: project => project.offerAcceptedDate && moment(project.offerAcceptedDate).format('YYYY-MM-DD'),
                                signatureDate: project => project.signatureDate && moment(project.signatureDate).format('YYYY-MM-DD'),
                                beginningOfRealisationDate: project => project.beginningOfRealisationDate && moment(project.beginningOfRealisationDate).format('YYYY-MM-DD'),
                                projectFunding: project => project.projectFunding && staticObjects[fieldPaths.projectFunding.entity][project.projectFunding],
                                compensationModes: project => project.compensationModes && project.compensationModes.map(compensationMode => staticObjects[fieldPaths.compensationMode.entity][compensationMode]).join(', '),
                                interventionMode: project => project.interventionMode && staticObjects[fieldPaths.interventionMode.entity][project.interventionMode],
                                contractualSchema: project => project.contractualSchema && staticObjects[fieldPaths.contractualSchema.entity][project.contractualSchema],
                                needLocalStructure: project => project.needLocalStructure,
                                partners: project => project.partners && project.partners.map(provider => `${provider.code}-${provider.name}`).join(', '),
                                partnersRelations: project => project.partners && project.partners.map(provider => provider.providerRelations && provider.providerRelations.map(relation => staticObjects[fieldPaths.partnerRelationship.entity][relation]).join(' ')).join(', '),
                                subcontractors: project => project.subcontractors && project.subcontractors.map(provider => `${provider.code}-${provider.name}`).join(', '),
                                subcontractorsRelations: project => project.subcontractors && project.subcontractors.map(provider => provider.providerRelations && provider.providerRelations.map(relation => staticObjects[fieldPaths.subcontractorRelationship.entity][relation]).join(' ')).join(', '),
                                competitors: project => project.competitors && project.competitors.map(provider => `${provider.code}-${provider.name}`).join(', '),
                                competitorsRelations: project => project.competitors && project.competitors.map(provider => provider.providerRelations && provider.providerRelations.map(relation => staticObjects[fieldPaths.competitorRelationship.entity][relation]).join(' ')).join(', '),
                                originOfTheCase: project => project.originOfTheCase && staticObjects[fieldPaths.originOfTheCase.entity][project.originOfTheCase],
                                commercialTeamMember: project => project.commercialTeamMember[0] && `${project.commercialTeamMember[0].firstName} ${project.commercialTeamMember[0].lastName}`,
                                technicalTeamMember: project => project.technicalTeamMember[0] && `${project.technicalTeamMember[0].firstName} ${project.technicalTeamMember[0].lastName}`,
                                referentInSupport: project => project.referentInSupport,
                                projectManager: project => project.projectManager,
                                projectTeam: project => project.projectTeam,
                                nextDeadLine: project => project.nextDeadLine,
                                alert: project => project.alert,
                                specialFollowUp: project => project.specialFollowUp,
                                completedService: project => project.completedService,
                                allPaymentsReceived: project => project.allPaymentsReceived,
                                closingDocumentsReceived: project => project.closingDocumentsReceived,
                                revueDate: project => project.revueDate && moment(project.revueDate).format('YYYY-MM-DD'),
                                risk: project => project.risk,
                                globalTurnover: project => project.globalTurnover,
                                detectionTurnOver: project => project.detectionTurnOver,
                                buildOfferTurnOver: project => project.buildOfferTurnOver,
                                contractualizationTurnOver: project => project.contractualizationTurnOver,
                                totalExpectedTurnOver: project => project.totalExpectedTurnOver,
                                businessRevueCurrentTurnOver: project => project.lastBusinessRevue && project.lastBusinessRevue.revised,
                                businessRevueCurrentTurnOverP: project => project.lastBusinessRevue && project.lastBusinessRevue.revisedP,
                                totalSumActualTurnover: project => project.sumActualTurnOver,
                                businessRevueExecutedTurnOver: project => project.lastBusinessRevue && project.lastBusinessRevue.executedTurnOver,
                                orderTakingMonth: project => project.lastBusinessRevue && project.lastBusinessRevue.orderMonth && moment(project.lastBusinessRevue.orderMonth, 'YYYY-MM').format('MMM YYYY'),
                                businessRevueCurrentYear: project => project.lastBusinessRevue && project.lastBusinessRevue.yearN,
                                businessRevueCurrentProbability: project => project.lastBusinessRevue && project.lastBusinessRevue.probability,
                                businessRevueCurrentYearProbability: project => project.lastBusinessRevue && project.lastBusinessRevue.yearP,
                                businessRevueNextYearTurnover: project => project.lastBusinessRevue && project.lastBusinessRevue.yearNPlusOne,
                                totalSumActualBilled: project => project.sumActualBilled,
                                totalSumActualCashed: project => project.sumActualCashed,
                                totalExpectedContractCost: project => project.expectedContractCost,
                                totalSumCommittedExpenses: project => project.sumCommittedExpenses,
                                totalSumActualContractExpenses: project => project.sumActualContractExpenses,
                                marginOverContract: project => project.originOfTheCase && staticObjects[fieldPaths.marginOverContract.entity][project.marginOverContract],
                                expectedContractMarginInPercent: project => project.expectedContractMarginInPercent,
                                estimatedNumberOfDaysForRealisation: project => project.estimatedNumberOfDays,
                                buildOfferNumberOfDaysBuildOffer: project => project.buildOfferNumberOfDays,
                                expectedNumberOfDays: project => project.expectedNumberOfDays,
                                sumCommittedNumberOfDays: project => project.sumCommittedNumberOfDays,
                                sumActualNumberOfDays: project => project.sumActualNumberOfDays,
                                comment: project => project.lastBusinessRevue && project.lastBusinessRevue.comment,
                                statusAndOrder: project => getStatusAndOrder({workflow: project.workflow}, context),
                                creationDate: project => project.creationDate && moment(project.creationDate).format('YYYY-MM-DD')
                            }

                            const date = moment().format("YYYY-MM-DD_HH-mm")
                            const fileName = `${newObject.code}_${date}`

                            generateExcel(fileName, projects, fieldGetters, context)
                                .then(file => {
                                    const lastExtraction = newObject.lastExtraction = {
                                        ...file,
                                        user: _.get(context, "user.name", "unknown user"),
                                        date: moment().format("YYYY-MM-DD HH:mm")
                                    }

                                    newObject.files.push(lastExtraction)

                                    newObject.comments.push({
                                        text: `${file.filename}`,
                                        user: _.pick(context.user, ['id', 'name']),
                                        date: moment().format("YYYY-MM-DD HH:mm")
                                    })
                                    callback(null, newObject, oldObject)
                                })
                                .catch(err => callback(err))
                        })
                    .catch(error => callback(error))
            }else {
                callback(null, newObject, oldObject)
            }
        }
    }
]
export const module_ = {
    name: 'mTitle_Extraction',
    object: 'Extraction',
    category: {
        path: "reporting",
        icon: 'layers'
    },
    defaultSortBy: 'date',
    defaultSortDirection: 'DESC',
    deletionConditions: {
        isOwner: [true]
    },
    viewMap: {
        dt: [
            'code',
            'user', 'date',
            {path: 'lastExtraction', tKey: 'file', width: 50, noTimestamp: true},
            {path: 'isOwner', hidden: true}
        ],
        form: [
            {path: 'code', required: true, editable: false},
            {
                path: 'criteria',
                removable: true,
                viewMap: {
                    dt: ['axis', 'operator', 'value'],
                    form: [
                        {
                            path: 'axis',
                            default: {id: "country", name: "country"},
                            clearable: false,
                            subscriptions: {
                                onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                                    if (newValue) {
                                        const state = store.getState()
                                        const operator = _.get(state, "form.criteriaEditObject.values.operator")

                                        const formFields = getFieldsModuleForm(
                                            'criteria',
                                            ['country', 'countryType', 'securityLevel', 'zone', 'typeOfOffer', 'typeOfOfferPriority', 'rangeOfOffer', 'client',
                                                'clientRelationship', 'partner', 'partnerRelationship', 'subcontractor', 'subcontractorRelationship', 'competitor',
                                                'competitorRelationship', 'operationsAndServicesContract', 'beginYear', 'opportunity', 'resourceType', 'responseMode',
                                                'projectFunding', 'compensationMode', 'interventionMode', 'contractualSchema', 'originOfTheCase', 'technicalTeamMember',
                                                'commercialTeamMember', 'risk', 'needLocalStructure', 'completedService', 'allPaymentsReceived', 'closingDocumentsReceived', 'specialFollowUp', 'detectionTurnOver', 'buildOfferTurnOver',
                                                'contractualizationTurnOver', 'totalExpectedTurnOver', 'expectedNumberOfDays', 'sumCommittedNumberOfDays', 'sumActualNumberOfDays',
                                                'sumActualBilled', 'sumActualCashed', 'sumActualTurnOver', 'expectedContractCost', 'sumActualContractExpenses'
                                                , 'sumCommittedExpenses', 'businessRevueCurrentTurnOver', 'businessRevueExecutedTurnOver', 'businessRevueCurrentYearProbability',
                                                'businessRevueNextYearTurnover', 'estimatedNumberOfDays', 'marginOverContract', 'expectedContractMarginInPercent', 'buildOfferNumberOfDays',
                                                'globalTurnover', 'offerSubmissionDate', 'effectiveOfferSubmissionDate', 'offerAcceptedDate', 'estimatedResponseDate', 'reportedSubmissionDate',
                                                'signatureDate', 'beginningOfRealisationDate', 'projectManager', 'step', 'creationDate'],
                                            module
                                        )
                                        const formTagsFields = getFieldsModuleForm(
                                            'criteria',
                                            ['countries', 'countryTypes', 'securityLevels', 'zones', 'typesOfOffer', 'rangesOfOffer', 'clients', 'clientRelationships',
                                                'partners', 'partnerRelationships', 'subcontractors', 'subcontractorRelationships', 'competitors', 'competitorRelationships',
                                                'operationsAndServicesContracts', 'beginYears', 'opportunities', 'resourceTypes', 'responseModes', 'projectFundings', 'compensationModes',
                                                'interventionModes', 'contractualSchemas', 'originsOfTheCase', 'technicalTeamMembers', 'commercialTeamMembers', 'risks', 'marginsOverContract', 'steps'],
                                            module
                                        )

                                        formFields.forEach(field => {
                                            const visible = operator.id !== '$in' && field.path === newValue.id
                                            if(!visible) field.setValue(null)
                                            store.dispatch(setFieldVisibility(`e_criteria.e_${field.path}`, visible))
                                        })

                                        formTagsFields.forEach(field => {
                                            const visible = operator.id === '$in' && field.axis === newValue.id
                                            if(!visible) field.setValue(null)
                                            store.dispatch(setFieldVisibility(`e_criteria.e_${field.path}`, visible))
                                        })
                                    }
                                }
                            }
                        },
                        {
                            path: 'operator',
                            default: {id: '$eq', name: 'equal'},
                            clearable: false,
                            subscriptions: {
                                onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                                    if (newValue) {
                                        const state = store.getState()

                                        const axis = _.get(state, "form.criteriaEditObject.values.axis")

                                        const formFields = getFieldsModuleForm(
                                            'criteria',
                                            ['country', 'countryType', 'securityLevel', 'zone', 'typeOfOffer', 'typeOfOfferPriority', 'rangeOfOffer', 'client',
                                                'clientRelationship', 'partner', 'partnerRelationship', 'subcontractor', 'subcontractorRelationship', 'competitor',
                                                'competitorRelationship', 'operationsAndServicesContract', 'beginYear', 'opportunity', 'resourceType', 'responseMode',
                                                'projectFunding', 'compensationMode', 'interventionMode', 'contractualSchema', 'originOfTheCase', 'technicalTeamMember',
                                                'commercialTeamMember', 'risk', 'needLocalStructure', 'completedService', 'allPaymentsReceived', 'closingDocumentsReceived', 'specialFollowUp', 'detectionTurnOver', 'buildOfferTurnOver',
                                                'contractualizationTurnOver', 'totalExpectedTurnOver', 'expectedNumberOfDays', 'sumCommittedNumberOfDays', 'sumActualNumberOfDays',
                                                'sumActualBilled', 'sumActualCashed', 'sumActualTurnOver', 'expectedContractCost', 'sumActualContractExpenses', 'sumCommittedExpenses',
                                                'businessRevueCurrentTurnOver', 'businessRevueExecutedTurnOver', 'businessRevueCurrentYearProbability', 'businessRevueNextYearTurnover',
                                                'estimatedNumberOfDays', 'marginOverContract', 'expectedContractMarginInPercent', 'buildOfferNumberOfDays', 'globalTurnover',
                                                'offerSubmissionDate', 'effectiveOfferSubmissionDate', 'offerAcceptedDate', 'estimatedResponseDate', 'reportedSubmissionDate', 'signatureDate',
                                                'beginningOfRealisationDate', 'projectManager', 'step', 'creationDate'],
                                            module
                                        )

                                        const formTagsFields = getFieldsModuleForm(
                                            'criteria',
                                            ['countries', 'countryTypes', 'securityLevels', 'zones', 'typesOfOffer', 'rangesOfOffer', 'clients', 'clientRelationships',
                                                'partners', 'partnerRelationships', 'subcontractors', 'subcontractorRelationships', 'competitors', 'competitorRelationships',
                                                'operationsAndServicesContracts', 'beginYears', 'opportunities', 'resourceTypes', 'responseModes', 'projectFundings', 'compensationModes',
                                                'interventionModes', 'contractualSchemas', 'originsOfTheCase', 'technicalTeamMembers', 'commercialTeamMembers', 'risks', 'marginsOverContract', 'steps'],
                                            module
                                        )

                                        formFields.forEach(field => {
                                            const visible = newValue.id !== '$in' && field.path === axis.id
                                            if(!visible) field.setValue(null)
                                            store.dispatch(setFieldVisibility(`e_criteria.e_${field.path}`, visible))
                                        })

                                        formTagsFields.forEach(field => {
                                            const visible = newValue.id === '$in' && field.axis === axis.id
                                            if(!visible) field.setValue(null)
                                            store.dispatch(setFieldVisibility(`e_criteria.e_${field.path}`, visible))
                                        })
                                    }
                                }
                            }
                        },
                        {path: 'country', axis: 'country', subscriptions: valueSubscription()},
                        {path: 'countries', axis: 'country', subscriptions: valueSubscription()},
                        {path: 'countryType', axis: 'countryType', subscriptions: valueSubscription()},
                        {path: 'countryTypes', axis: 'countryType', subscriptions: valueSubscription()},
                        {path: 'securityLevel', tKey: 'countrySecurity', axis: 'securityLevel', subscriptions: valueSubscription()},
                        {path: 'securityLevels', tKey: 'countriesSecurity', axis: 'securityLevel', subscriptions: valueSubscription()},
                        {path: 'zone', axis: 'zone', subscriptions: valueSubscription()},
                        {path: 'zones', axis: 'zone', subscriptions: valueSubscription()},
                        {path: 'typeOfOffer', axis: 'typeOfOffer', subscriptions: valueSubscription()},
                        {path: 'typesOfOffer', axis: 'typeOfOffer', subscriptions: valueSubscription()},
                        {path: 'typeOfOfferPriority', axis: 'typeOfOfferPriority', subscriptions: valueSubscription()},
                        {path: 'rangeOfOffer', axis: 'rangeOfOffer', subscriptions: valueSubscription()},
                        {path: 'rangesOfOffer', axis: 'rangeOfOffer', subscriptions: valueSubscription()},
                        {path: 'client', axis: 'client', subscriptions: valueSubscription()},
                        {path: 'clients', axis: 'client', subscriptions: valueSubscription()},
                        {path: 'clientRelationship', axis: 'client', subscriptions: valueSubscription()},
                        {path: 'clientRelationships', axis: 'client', subscriptions: valueSubscription()},
                        {path: 'partner', axis: 'partner', subscriptions: valueSubscription()},
                        {path: 'partners', axis: 'partner', subscriptions: valueSubscription()},
                        {path: 'partnerRelationship', axis: 'partnerRelationship', subscriptions: valueSubscription()},
                        {path: 'partnerRelationships', axis: 'partnerRelationship', subscriptions: valueSubscription()},
                        {path: 'subcontractor', axis: 'subcontractor', subscriptions: valueSubscription()},
                        {path: 'subcontractors', axis: 'subcontractor', subscriptions: valueSubscription()},
                        {path: 'subcontractorRelationship', axis: 'subcontractorRelationship', subscriptions: valueSubscription()},
                        {path: 'subcontractorRelationships', axis: 'subcontractorRelationship', subscriptions: valueSubscription()},
                        {path: 'competitor', axis: 'competitor', subscriptions: valueSubscription()},
                        {path: 'competitors', axis: 'competitor', subscriptions: valueSubscription()},
                        {path: 'competitorRelationship', axis: 'competitorRelationship', subscriptions: valueSubscription()},
                        {path: 'competitorRelationships', axis: 'competitorRelationship', subscriptions: valueSubscription()},
                        {path: 'operationsAndServicesContract', axis: 'operationsAndServicesContract', display: 'contractFullId', subscriptions: valueSubscription('contractFullId')},
                        {path: 'operationsAndServicesContracts', axis: 'operationsAndServicesContract', display: 'contractFullId', subscriptions: valueSubscription('contractFullId')},
                        {path: 'beginYear', axis: 'beginYear', subscriptions: valueSubscription()},
                        {path: 'beginYears', axis: 'beginYear', subscriptions: valueSubscription()},
                        {path: 'opportunity', axis: 'opportunity', subscriptions: valueSubscription()},
                        {path: 'opportunities', axis: 'opportunity', subscriptions: valueSubscription()},
                        {path: 'resourceType', axis: 'resourceType', subscriptions: valueSubscription()},
                        {path: 'resourceTypes', axis: 'resourceType', subscriptions: valueSubscription()},
                        {path: 'responseMode', axis: 'responseMode', subscriptions: valueSubscription()},
                        {path: 'responseModes', tKey: 'responseMode', axis: 'responseMode', subscriptions: valueSubscription()},
                        {path: 'projectFunding', axis: 'projectFunding', subscriptions: valueSubscription()},
                        {path: 'projectFundings', axis: 'projectFunding', subscriptions: valueSubscription()},
                        {path: 'compensationMode', axis: 'compensationMode', subscriptions: valueSubscription()},
                        {path: 'compensationModes', axis: 'compensationMode', subscriptions: valueSubscription()},
                        {path: 'interventionMode', axis: 'interventionMode', subscriptions: valueSubscription()},
                        {path: 'interventionModes', axis: 'interventionMode', subscriptions: valueSubscription()},
                        {path: 'contractualSchema', axis: 'contractualSchema', subscriptions: valueSubscription()},
                        {path: 'contractualSchemas', axis: 'contractualSchema', subscriptions: valueSubscription()},
                        {path: 'originOfTheCase', axis: 'originOfTheCase', subscriptions: valueSubscription()},
                        {path: 'originsOfTheCase', axis: 'originOfTheCase', subscriptions: valueSubscription()},
                        {path: 'technicalTeamMember', display: "fullName", axis: 'technicalTeamMember', subscriptions: valueSubscription('fullName')},
                        {path: 'technicalTeamMembers', display: "fullName", axis: 'technicalTeamMember', subscriptions: valueSubscription('fullName')},
                        {path: 'commercialTeamMember', display: "fullName", axis: 'commercialTeamMember', subscriptions: valueSubscription('fullName')},
                        {path: 'commercialTeamMembers', display: "fullName", axis: 'commercialTeamMember', subscriptions: valueSubscription('fullName')},
                        {path: 'risk', axis: 'risk', subscriptions: valueSubscription()},
                        {path: 'risks', axis: 'risk', subscriptions: valueSubscription()},
                        {path: 'needLocalStructure', axis: 'needLocalStructure', subscriptions: valueSubscription()},
                        {path: 'completedService', axis: 'completedService', subscriptions: valueSubscription()},
                        {path: 'allPaymentsReceived', axis: 'allPaymentsReceived', subscriptions: valueSubscription()},
                        {path: 'closingDocumentsReceived', axis: 'closingDocumentsReceived', subscriptions: valueSubscription()},
                        {path: 'specialFollowUp', axis: 'specialFollowUp', subscriptions: valueSubscription()},

                        {path: 'creationDate', axis: 'creationDate', subscriptions: valueSubscription()},
                        {path: 'offerSubmissionDate', axis: 'offerSubmissionDate', subscriptions: valueSubscription()},
                        {path: 'effectiveOfferSubmissionDate', axis: 'effectiveOfferSubmissionDate', subscriptions: valueSubscription()},
                        {path: 'offerAcceptedDate', axis: 'offerAcceptedDate', subscriptions: valueSubscription()},
                        {path: 'estimatedResponseDate', axis: 'estimatedResponseDate', subscriptions: valueSubscription()},
                        {path: 'reportedSubmissionDate', axis: 'reportedSubmissionDate', subscriptions: valueSubscription()},
                        {path: 'signatureDate', axis: 'signatureDate', subscriptions: valueSubscription()},
                        {path: 'beginningOfRealisationDate', axis: 'beginningOfRealisationDate', subscriptions: valueSubscription()},

                        {path: 'detectionTurnOver', axis: 'detectionTurnOver', subscriptions: valueSubscription()},
                        {path: 'buildOfferTurnOver', axis: 'buildOfferTurnOver', subscriptions: valueSubscription()},
                        {path: 'contractualizationTurnOver', axis: 'contractualizationTurnOver', subscriptions: valueSubscription()},
                        {path: 'totalExpectedTurnOver', axis: 'totalExpectedTurnOver', subscriptions: valueSubscription()},
                        {path: 'expectedNumberOfDays', axis: 'expectedNumberOfDays', subscriptions: valueSubscription()},
                        {path: 'sumCommittedNumberOfDays', axis: 'sumCommittedNumberOfDays', subscriptions: valueSubscription()},
                        {path: 'sumActualNumberOfDays', axis: 'sumActualNumberOfDays', subscriptions: valueSubscription()},
                        {path: 'marginOverContract', axis: 'marginOverContract', subscriptions: valueSubscription()},
                        {path: 'marginsOverContract', axis: 'marginOverContract', subscriptions: valueSubscription()},
                        {path: 'expectedContractMarginInPercent', axis: 'expectedContractMarginInPercent', subscriptions: valueSubscription()},

                        {path: 'sumActualBilled', tKey: 'totalSumActualBilled', axis: 'sumActualBilled', subscriptions: valueSubscription()},
                        {path: 'sumActualCashed', tKey: 'totalSumActualCashed', axis: 'sumActualCashed', subscriptions: valueSubscription()},
                        {path: 'sumActualTurnOver', tKey: 'totalSumActualTurnover', axis: 'sumActualTurnOver', subscriptions: valueSubscription()},
                        {path: 'expectedContractCost', tKey: 'totalExpectedContractCost', axis: 'expectedContractCost', subscriptions: valueSubscription()},
                        {path: 'sumActualContractExpenses', tKey: 'totalSumActualContractExpenses', axis: 'sumActualContractExpenses', subscriptions: valueSubscription()},
                        {path: 'sumCommittedExpenses', tKey: 'totalSumCommittedExpenses', axis: 'sumCommittedExpenses', subscriptions: valueSubscription()},

                        {path: 'businessRevueCurrentTurnOver', axis: 'businessRevueCurrentTurnOver', subscriptions: valueSubscription()},
                        {path: 'businessRevueExecutedTurnOver', axis: 'businessRevueExecutedTurnOver', subscriptions: valueSubscription()},
                        {path: 'businessRevueCurrentYearProbability', axis: 'businessRevueCurrentYearProbability', subscriptions: valueSubscription()},
                        {path: 'businessRevueNextYearTurnover', axis: 'businessRevueNextYearTurnover', subscriptions: valueSubscription()},

                        {path: 'estimatedNumberOfDays', tKey: 'estimatedNumberOfDaysForRealisation', axis: 'estimatedNumberOfDays', subscriptions: valueSubscription()},
                        {path: 'buildOfferNumberOfDays', tKey: 'buildOfferNumberOfDaysBuildOffer', axis: 'buildOfferNumberOfDays', subscriptions: valueSubscription()},

                        {path: 'globalTurnover', axis: 'globalTurnover', subscriptions: valueSubscription()},

                        {path: 'step', axis: 'step', subscriptions: valueSubscription()},
                        {path: 'steps', axis: 'step', subscriptions: valueSubscription()},
                        {path: 'projectManager', axis: 'projectManager', subscriptions: valueSubscription()},

                        {path: 'value', hidden: true}
                    ]
                }
            },
            {path: 'shareWith', display: 'userName'},
            {path: 'generateFile'},
            'comments',
            'files'
        ]
    }
}
