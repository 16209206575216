import async from "async"
import _ from 'lodash'
import Errors from "../../utils/Errors"
import { setFieldVisibility, changeFieldDisabled, setFieldListOptions} from '../../../apps/KpModule/actions'
import {generateFetchFieldListAction} from '../../../apps/KpModule/actions/api'
import {getDataListList} from "../../../apps/KpModule/selectors"

const numericalSourceFilter = {
    title: "Numerical sources",
    match: function(source) {
        return _.includes(
            ["type-float", "type-integer"],
            _.get(source, "dataType")
        );
    }
};

const getFieldsModuleForm = (element, fieldPaths, module) => {
    return fieldPaths.map(fieldPath => getFieldModuleForm(element, fieldPath, module))
}

export const getFieldModuleForm = (element, fieldPath, module) => {
    return module.viewMap.form.fields.find(
        field => field.path === element
    ).viewMap.form.fields.find(
        field => field.path === fieldPath
    )
}

const getFieldsModuleDt = (element, fieldPaths, module) => {
    return fieldPaths.map(fieldPath => (
        module.viewMap.form.fields.find(
            field => field.path === element
        ).viewMap.dt.fields.find(
            field => field.path === fieldPath
        )
    ))
}

function updateFieldsOptions({stream, store}) {
    const state = store.getState();

    const fieldsByDataLists = {
        "m-S-themeConfig.ThemeCondition_source": ["e_conditions.e_source", "e_conditions.e_source2", "e_mt.e_conditions.e_source", "e_mt.e_conditions.e_source2", "e_nb.e_conditions.e_source", "e_nb.e_conditions.e_source2"] ,

        "m-S-themeConfig.MtComponent_source": "e_mt.e_source",
        "m-S-themeConfig.MtComponent_lineTheme": "e_mt.e_lineTheme",

        "m-S-themeConfig.NbComponent_source": "e_nb.e_source",
        "m-S-themeConfig.NbComponent_lineTheme": "e_nb.e_lineTheme",
    }

    const dataLists = Object.keys(fieldsByDataLists)

    if (stream) {

        dataLists.forEach(dataList => {
            const options = getDataListList(
                state,
              dataList
            );

            const filteredOptions = options.filter(
                option => _.get(option, 'inputCorrespondenceByImportType.id', _.get(option, 'stream.id')) === stream.id
            )

            if( _.isString(fieldsByDataLists[dataList])) {
                store.dispatch(
                    setFieldListOptions(fieldsByDataLists[dataList], _.map(filteredOptions, "id"))
                );
            }
            if(_.isArray(fieldsByDataLists[dataList])) {
                fieldsByDataLists[dataList].forEach(fieldId => store.dispatch(
                    setFieldListOptions(fieldId, _.map(filteredOptions, "id"))
                ))
            }
        })
    } else {
        dataLists.forEach(
            dataList => store.dispatch(
                setFieldListOptions(fieldsByDataLists[dataList], [])
            )
        )
    }
}

export const dependencies = [
    {
        name: "ThemeCondition",
        type: "mongoInternal",
        fields: [
            {path: "source", type: "InputCorrespondence", uniqueWith: ["operator", "condValue", "source2"]},
            {path: "source2", type: "InputCorrespondence", nullable: true},
            {path: "operator", type: "CondOperator"},
            "condValue",
            "condValues"
        ]
    },
    {
        name: "MtComponent",
        type: "mongoInternal",
        fields: [
            {path: "source", type: "InputCorrespondence", nullable: true},
            {path: "negative", type: "boolean", default: false},
            {path: "conditions", type: "ThemeCondition", link: "OTM"},
            {path: "applyExchangeRate", type: "boolean", default: false, nullable: true},
            {path: "lineTheme", type: "ThemeConfig", nullable: true},
            {path: "lineThemeCondOperator", type: "CondOperator", nullable: true},
            {path: "lineThemeCondValue", nullable: true}
        ]
    },
    {
        name: "NbComponent",
        type: "mongoInternal",
        fields: [
            {path: "source", type: "InputCorrespondence", nullable: true},
            {path: 'operation', type: "Operation", nullable: true},
            {path: "negative", type: "boolean", default: false},
            {path: "conditions", type: "ThemeCondition", link: "OTM"},
            {path: "constantValue", type: "boolean", default: false},
            {path: "lineTheme", type: "ThemeConfig", nullable: true},
            {path: "lineThemeCondOperator", type: "CondOperator", nullable: true},
            {path: "lineThemeCondValue", nullable: true}
        ]
    }
]

export const entity = {
    name: "ThemeConfig",
    facets: ["translatedField"],
    fields: [
        {
            path: "code",
            ps: {object: ["mongoFieldName"]},
            notEmpty: true,
            index: true,
            unique: true
        },
        "name",
        {path: "stream", type:"InputCorrespondenceByImportType"},
        {path: "groupedTheme", type: "boolean"},
        {path: "dormant", type: "boolean"},
        {path: "conditions", type: "ThemeCondition", link: "OTM"},
        {path: "mt", type: "MtComponent", link: "OTM"},
        {path: "nb", type: "NbComponent", link: "OTM"},
        {
            path: "themeKeyName",
            f: function() { return `theme-${this.code}`; }
        },
        {
            path: "mtKeyName",
            f: function() { return `theme-${this.code}.Mt`; }
        },
        {
            path: "nbKeyName",
            f: function() { return `theme-${this.code}.Nb`; }
        },
        {
            path: "completeCode",
            f: function() { return this.themeKeyName; }
        },
        /*
        {
            path: "fullName",
            fieldPath: ["name", "importType.tKey"],
            f: function() { return `${this.importType.tKey}-${this.name}`; }
        },
         */
        {
            path: "allInputCorrespondencesIds",
            f: function() {
                const conditionsinputCorrespondences = _(this.conditions)
                    .filter(condition => condition)
                    .map(condition => condition.source)
                    .map(o => o.toString())
                    .value();

                const mtinputCorrespondences = _(this.mt)
                    .map(mtValue => [mtValue.source, ...mtValue.conditions.map(rule => rule.source)])
                    .flatten()
                    .filter(o => o)
                    .map(o => o.toString())
                    .value();

                const nbinputCorrespondences = _(this.nb)
                    .map(nbValue => [nbValue.source, ...nbValue.conditions.map(rule => rule.source)])
                    .flatten()
                    .filter(o => o)
                    .map(o => o.toString())
                    .value();

                return _([conditionsinputCorrespondences, mtinputCorrespondences, nbinputCorrespondences])
                    .flatten()
                    .uniq()
                    .value();
            }
        },
    ],
    filters: [
        {
            name: 'notEqualTo',
            isDefault: false,
            query: function(context) {
                const themeConfigId = _.get(context, "data.themeConfig.id")
                if(themeConfigId) return {_id: {$ne: new global.ObjectID(themeConfigId)}}
                return {}
            }
        }
    ],
    nameCannotContainHyphens: (newObject, oldObject, context, callback) => {
        const containsHyphens = newObject.name.indexOf('-') !== -1
        if(containsHyphens) return callback(new Errors.ValidationError('nameCannotContainHyphens'))
        return callback()
    },
    prerequisitesShouldBeUnique: (newObject, oldObject, context, callback) => {
        const hasDuplicates = newObject.conditions.some((conditionA, index, self) =>
                index !== self.findIndex((conditionB) => (
                    conditionA.source.id === conditionB.source.id
                    && conditionA.source2?.id === conditionB.source2?.id
                    && conditionA.operator.id === conditionB.operator.id
                    && conditionA.condValue === conditionB.condValue
                ))
        )
        if(hasDuplicates) return callback(new Errors.ValidationError('conditionsFieldHasDuplicates'))
        return callback()
    },
    mustHaveAtLeastOneRule: (newObject, oldObject, context, callback) => {

        if(newObject.mt.length === 0 && newObject.nb.length === 0 ) return callback(new Errors.ValidationError('themeConfigMustHaveAtLeastOneRule'))
        return callback()
    },
    cannotModifyUsedTheme: (newObject, oldObject, context, callback) => {
        const action  = context.restAction && context.restAction.crudType
        if(action === 'C' || newObject.code === oldObject.code) return callback()
        global.app.S.ElemDataLine.collection
            .findOne(
                {[`theme-${oldObject.code}`]: { $exists: true}},
                (e, theme) => {
                    if (e) return callback(e)
                    if (!!theme) return callback(new Errors.ValidationError('cannotChangeUsedThemeCode'))
                    return callback()
                })
    },
    validateSave: function(newObject, oldObject, context, callback) {
        async.series([
            callback => this.nameCannotContainHyphens(newObject, oldObject, context, callback),
            callback => this.prerequisitesShouldBeUnique(newObject, oldObject, context, callback),
            callback => this.mustHaveAtLeastOneRule(newObject, oldObject, context, callback),
            callback => this.cannotModifyUsedTheme(newObject, oldObject, context, callback)
        ], callback)
    },
    ps: {
        delete: [
            {
                $$v: function (object, callback) {
                    async.parallel({
                        alertConfiguration: callback =>  global.app.S.AlertConfiguration.collection.findOne({$or: [
                                {'alertFields.themeJoin': new global.ObjectID(object.id)},
                                {'alertFields.denominatorTheme': new global.ObjectID(object.id)},
                            ]}, callback),
                        scoringConfig: callback =>  global.app.S.ScoringConfig.collection.findOne({$or: [
                                {'scoringFields.numeratorTheme': new global.ObjectID(object.id)},
                                {'scoringFields.denominatorTheme': new global.ObjectID(object.id)},
                            ]}, callback),
                    }, (e, results) => {
                        if (e) return callback(e)
                        if(results.alertConfiguration) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteThemeUsedInAlertConfiguration', {themeCode: object.code})))
                        if(results.scoringConfig) return callback(new Errors.ValidationError(this.options.context.tc('cannotDeleteThemeUsedInScoringConfiguration', {themeCode: object.code})))

                        callback()
                        global.db.collection('slp.elemData').updateMany(
                            {[`theme-${object.code}`]:{$exists: true}},
                            {$unset: {[`theme-${object.code}`]: ''}}
                        )

                    })
                }
            }
        ]
    }
}

export const module_ = {
    object: "ThemeConfig",
    tKey: "mTitle_ThemeConfig",
    category: {
        path: 'setting',
        icon: 'settings'
    },
    defaultSortBy : "code",
    defaultSortDirection : "ASC",
    viewMap: {
        dt: [
            "code",
            {path: "name", type: "translatedText"},
            {path: "stream"},
            "groupedTheme"
        ],
        form: {
            fields : [
                {
                    path: "stream",
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            updateFieldsOptions({stream: newValue, store})
                        }
                    }
                },
                {path: "code",},
                {path: "name", type: "textarea", required: true},
                {
                    path: "groupedTheme",
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            const prerequisitesField = module.viewMap.form.fields.find(
                                field => field.path === 'conditions'
                            )

                            const isTicketTheme = newValue

                            if(isTicketTheme) prerequisitesField.setValue([])
                            store.dispatch(setFieldVisibility(prerequisitesField.id, !isTicketTheme))

                            const mtDtThemeFields = getFieldsModuleDt(
                                'mt',
                                ['source'],
                                module
                            )
                            const mtFormThemeFields = getFieldsModuleForm(
                                'mt',
                                ['source', 'applyExchangeRate', 'conditions'],
                                module
                            )

                            /*

                            const nbDtThemeFields = getFieldsModuleDt(
                                'nb',
                                ['source'],
                                module
                            )

                            const nbFormThemeFields = getFieldsModuleForm(
                                'nb',
                                ['source', 'conditions'],
                                module
                            )

                             */
                            _.concat(mtDtThemeFields, mtFormThemeFields).forEach(field => {
                                if(isTicketTheme) {
                                    if(field.setValue) {
                                        if(field.path === 'source') field.setValue(null)
                                        if(field.path === 'applyExchangeRate') field.setValue(false)
                                        if(field.path === 'conditions') field.setValue([])
                                    }
                                }
                                store.dispatch(setFieldVisibility(field.id, !isTicketTheme))
                            })

                            const mtDtIndicatorFields = getFieldsModuleDt(
                                'mt',
                                ['lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue'],
                                module
                            )

                            const mtFormIndicatorFields = getFieldsModuleForm(
                                'mt',
                                ['lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue'],
                                module)

                            const nbDtIndicatorFields = getFieldsModuleDt(
                                'nb',
                                ['lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue'],
                                module
                            )

                            const nbFormIndicatorFields = getFieldsModuleForm(
                                'nb',
                                ['lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue'],
                                module)

                            _.concat(mtDtIndicatorFields, mtFormIndicatorFields, nbDtIndicatorFields, nbFormIndicatorFields).forEach(field => {
                                if(!isTicketTheme && field.setValue) field.setValue(null)
                                store.dispatch(setFieldVisibility(field.id, isTicketTheme))
                            })


                        }
                    }
                },
                {
                    path: "conditions",
                    tKey: "prerequisites",
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "source", tKey: "cashierFileId"},
                            {path: "source2", tKey: "compareTo"},
                            {path: "operator", translate: true},
                            {path: "condValue", tKey: "value"}
                        ],
                        form: [
                            {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id']},
                            {
                                path: "source2",
                                tKey: "compareTo",
                                fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'],
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {

                                        const condValueField = getFieldModuleForm('conditions', 'condValue', module)

                                        if(!newValue) {
                                            condValueField.setValue(null)
                                        }

                                        store.dispatch(setFieldVisibility(condValueField.id, !newValue))
                                    }
                                }
                            },
                            {path: "operator", translate: true},
                            {path: "condValue", tKey: "value"}
                        ]
                    }
                },

                {
                    path: "mt",
                    tKey: "amount",
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: "source", tKey: "cashierFileId"},
                            {path: "lineTheme", tKey: "indicator", display: "tName"},
                            {path: "lineThemeCondOperator", tKey: 'condition', translate: true},
                            {path: "lineThemeCondValue", tKey: "value"},
                            {path: "negative", tKey: "changeSign"},
                        ],
                        form: [
                            {path: "negative", tKey: "changeSign"},
                            {path: "source", tKey: "cashierFileId", fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'], filters: [numericalSourceFilter]},
                            "applyExchangeRate",

                            {
                                path: "conditions",
                                removable: true,
                                viewMap: {
                                    dt: [
                                        {path: "source"},
                                        {path: "source2", tKey: "compareTo"},
                                        {path: "operator", translate: true},
                                        {path: "condValue", tKey: "value"}
                                    ],
                                    form: [
                                        {path: "source", fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id']},
                                        {
                                            path: "source2",
                                            tKey: "compareTo",
                                            fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id'],
                                            subscriptions: {
                                                onChange: (newValue, oldValue, {module, store}) => {

                                                    const conditionsField = getFieldModuleForm('mt', 'conditions', module)
                                                    const condValueField = conditionsField.viewMap.form.fields.find(
                                                        field => field.path === 'condValue'
                                                    )

                                                    console.log('condValueField', condValueField)

                                                    if(!newValue) {
                                                        condValueField.setValue(null)
                                                    }

                                                    store.dispatch(setFieldVisibility(condValueField.id, !newValue))
                                                }
                                            }
                                        },
                                        {path: "operator", translate: true},
                                        {path: "condValue", tKey: "value"}
                                    ]
                                }
                            },
                            {path: "lineTheme", tKey: "indicator", display: "tName", fieldPath: ['id', "code", 'tName', 'stream.id'], filters: ['notEqualTo'], mField: {reloadList: true}},
                            {path: "lineThemeCondOperator", tKey: 'condition', translate: true},
                            {path: "lineThemeCondValue", tKey: "value"},
                        ]
                    }
                },

                {
                    path: "nb",
                    tKey: "number",
                    removable: true,
                    viewMap: {
                        dt: [
                            "constantValue",
                            {path: "source", tKey: "cashierFileId"},
                            {path: "lineTheme", tKey: "indicator", display: "tName"},
                            {path: "lineThemeCondOperator", tKey: "condition", translate: true},
                            {path: "lineThemeCondValue", tKey: "value"},
                            {path: "negative", tKey: "changeSign"},
                        ],
                        form: [
                            {path: "negative", tKey: "changeSign"},
                            {
                                path: "constantValue",
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {

                                        const dataFields = getFieldsModuleForm('nb', ['source'], module)

                                        if (dataFields.length) {
                                            const sourceField = dataFields[0]
                                            if(newValue) sourceField.setValue(null)
                                            store.dispatch(changeFieldDisabled(`e_nb.e_${sourceField.path}`, newValue))
                                        }
                                    }
                                }
                            },
                            {
                                path: "source",
                                tKey: "cashierFileId",
                                fieldPath: ['id', 'code', 'name', 'inputCorrespondenceByImportType.id'],
                                //filters: [numericalSourceFilter],
                                subscriptions: {
                                    onChange: (newValue, oldValue, {module, store}) => {

                                        const nbFormSourceFields = getFieldsModuleForm(
                                            'nb',
                                            ['operation', 'conditions'],
                                            module
                                        )

                                        nbFormSourceFields.forEach(field => {
                                            if(!newValue) {
                                                if(field.path === 'operation') field.setValue(null)
                                                if(field.path === 'conditions') field.setValue([])
                                            }
                                            store.dispatch(setFieldVisibility(field.id, !!newValue))
                                        })

                                        const nbFormIndicatorFields = getFieldsModuleForm(
                                            'nb',
                                            ['lineTheme', 'lineThemeCondOperator', 'lineThemeCondValue'],
                                            module)

                                        nbFormIndicatorFields.forEach(field => {
                                            if(newValue) field.setValue(null)
                                            store.dispatch(setFieldVisibility(field.id, !newValue))
                                        })
                                    }
                                }
                            },
                            {path: "operation"},

                            {
                                path: "conditions",
                                removable: true,
                                viewMap: {
                                    dt: [
                                        {path: "source"},
                                        {path: "source2", tKey: "compareTo"},
                                        {path: "operator", translate: true},
                                        {path: "condValue", tKey: "value"}
                                    ],
                                    form: [
                                        {path: "source", fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id']},
                                        {
                                            path: "source2",
                                            tKey: "compareTo",
                                            fieldPath: ['id', 'name', 'inputCorrespondenceByImportType.id'],
                                            subscriptions: {
                                                onChange: (newValue, oldValue, {module, store}) => {

                                                    const conditionsField = getFieldModuleForm('nb', 'conditions', module)
                                                    const condValueField = conditionsField.viewMap.form.fields.find(
                                                        field => field.path === 'condValue'
                                                    )

                                                    if(!newValue) {
                                                        condValueField.setValue(null)
                                                    }

                                                    store.dispatch(setFieldVisibility(condValueField.id, !newValue))
                                                }
                                            }
                                        },
                                        {path: "operator", translate: true},
                                        {path: "condValue", tKey: "value"}
                                    ]
                                }
                            },
                            {path: "lineTheme", tKey: "indicator", display: "tName", fieldPath: ['id', 'code', 'tName', 'stream.id'], filters: ['notEqualTo'], mField: {reloadList: true}},
                            {path: "lineThemeCondOperator", tKey: "condition", translate: true},
                            {path: "lineThemeCondValue", tKey: "value"}
                        ]
                    }
                },
                { path: 'dormant'},
            ],
            onOpen: ({ store }) => {
                const state = store.getState()
                const themeConfigId = _.get(state, "form.editObject.values.id")
                const dataLists = ["m-S-themeConfig.MtComponent_lineTheme", "m-S-themeConfig.NbComponent_lineTheme"]

                dataLists.forEach(dataList => store.dispatch(
                    generateFetchFieldListAction(
                        dataList,
                        store.getState,
                        'form',
                        {
                            data: {themeConfig: {id: themeConfigId}}
                        }
                    )
                ))
                const stream = state.edit.object.data.stream

                updateFieldsOptions({stream, store})
            }
        }
    },
    filters: [
        {
            path: 'stream',
            display: 'name',
            object: 'InputCorrespondenceByImportType',
            client: true,
            clearable: false,
            query: (context) => {
                const streamId = _.get(context, 'data.stream.id')
                return streamId
                    ? {stream: global.ObjectID(streamId)}
                    : {}
            }
        }
    ]
}
