import _ from "lodash";
import { setEditDefaultObject } from '../../../../apps/KpModule/actions/index';
import { compose } from '../../../utils/functional';
import { getDataListList } from "../../../../apps/KpModule/selectors/index";
import { change } from 'redux-form';
const Errors = require('../../../utils/Errors').default

function getCountryConfigurationFromDataList(rawObject, dataList) {
    if (rawObject && rawObject.id) return dataList.list.byId[rawObject.id];
}

/*
* Checks if array is positive and sequential
* - true for [1, 2, 3]
* - false for [1, 3] or [-1, 2] ...
* */
const positiveAndSequentialArray = arr => arr[0] === 1 && arr[arr.length-1] === arr.length;

/*
* Generate one object of the shape TeamMemberProfileConfig from a list of WorkflowConfig.
* WorkflowConfig exist only if a previous configuration was saved.
* */
const teamMemberProfileConfigFromWorkflowConfigs = workFlowConfigsByTeamMemberProfile => teamMemberProfile => {
    const workflowConfigs = workFlowConfigsByTeamMemberProfile[teamMemberProfile.id] || [];
    const configsByProfile = _.groupBy(workflowConfigs, "profile");
    const profileSelected = profile => !!configsByProfile[profile]

    return {
        teamMemberProfile,
        formX_active: profileSelected("formX"),
        formX_order: _.get(configsByProfile, "formX[0].order", 0),
        buildOffer_active: profileSelected("buildOffer"),
        buildOffer_order: _.get(configsByProfile, "buildOffer[0].order", 0),
        submitOffer_active: profileSelected("submitOffer"),
        submitOffer_order: _.get(configsByProfile, "submitOffer[0].order", 0),
        realisation_active: profileSelected("realisation"),
        realisation_order: _.get(configsByProfile, "realisation[0].order", 0)
    }
}

function groupConfigsByTeamMemberProfiles(teamMemberProfiles, workflowConfigs) {
    const generateConfig = teamMemberProfileConfigFromWorkflowConfigs(
        _.groupBy(workflowConfigs, "teamMemberProfile.id")
    );

    return teamMemberProfiles.map(generateConfig);
}

function getNewWorkflowConfigs({country, previousWorkflowConfigs, teamMemberProfileConfigs}) {
    const workflowConfigsByTeamMemberProfile = _.groupBy(previousWorkflowConfigs, "teamMemberProfile.id")

    const transform = ({
                           teamMemberProfile,
                           formX_active, formX_order,
                           buildOffer_active, buildOffer_order,
                           submitOffer_active, submitOffer_order,
                           realisation_active, realisation_order
    }) => {
        const workflowConfigs = workflowConfigsByTeamMemberProfile[teamMemberProfile.id] || []
        const configsByProfile = _.groupBy(workflowConfigs, "profile");
        const baseWorkflowConfig = profile => {
            const previousConfig = _.get(configsByProfile, `${profile}[0]`, {})
            return {
                ...previousConfig,
                country,
                order: 0,
                teamMemberProfile: _.pick(teamMemberProfile, ['id','group']),
                profile
            }
        };

        return _.compact([
            formX_active && {...baseWorkflowConfig("formX"), order: formX_order},
            buildOffer_active && {...baseWorkflowConfig("buildOffer"), order: buildOffer_order},
            submitOffer_active && {...baseWorkflowConfig("submitOffer"), order: submitOffer_order},
            realisation_active && {...baseWorkflowConfig("realisation"), order: realisation_order}

        ])
    }

    return _.flatMap(
        teamMemberProfileConfigs,
        transform
    )
}

/*
* Extract a string like "TeamMemberProfile1, TeamMemberProfile2 → TeamMemberProfile3"
* for a set of workflowConfigs containing the properties TeamMemberProfile.name and order.
*  - the symbol ", " connects TeamMemberProfiles with the same order
*  - the symbol " → " connects groups with different orders
*  - everything is in ascending order
* */
function getOrderedStringForWorkflowConfigs(workflowConfigs) {
    const workflowConfigsByOrder = _.groupBy(workflowConfigs, "order");

    const orders = _.orderBy(Object.keys(workflowConfigsByOrder));

    return orders.map(
        order =>
            _.map(
                _.get(workflowConfigsByOrder, order),
                "teamMemberProfile.name"
            ).join(", ")
    ).join(" → ")
}

/*
* Checks if teamMemberProfile corresponds to the country configuration.
* */
const teamMemberProfileCorresponds = country => teamMemberProfile => {
    if (!country) return false;
    return teamMemberProfile.teamMembers.some(teamMember => teamMember.countrys.some( o => o.id === country.id))
}

function validateHabFunctionConfigs(teamMemberProfileConfigs, context) {

    const profiles = ["formX", "buildOffer", "submitOffer", "realisation"]

    // validate that those profiles are active
    profiles.forEach(profile => {
        const found = teamMemberProfileConfigs.some(config => config[`${profile}_active`])
        if (!found) {
            return new Errors.ValidationError(context.tc("profileIsMandatory", {profileName: context.t(profile)}));
        }

        //check that orders for validator profile make sense
        const orders = _(teamMemberProfileConfigs)
            .filter(`${profile}_active`)
            .map(`${profile}_order`)
            .uniq()
            .orderBy()
            .value();

        if (!positiveAndSequentialArray(orders)) {
            return new Errors.ValidationError(context.tc("profileOrderSequential", {profileName: context.t(profile)}));
        }
    });
}

/*
* WorkflowConfigsByCountry is an Entity containing workflowConfigurations for a given Country.
* There is a known issue coming from the ORM abstraction, in the save getter. We cannot get the previous
* workflowConfigs, so each time the entity is saved it removes previous linked configurations and creates new ones.
* The behaviour is indeed coherent if no other link to WorkflowConfig is persisted.
* */
const WorkflowConfigsByCountry = {
    name: "WorkflowConfigsByCountry",
    fields: [
        {type: "Country", unique: true},
        {
            type: "TeamMemberProfileConfig",
            lazy: true,
            fieldPath: [
                "workflowConfigs.id",
                "workflowConfigs.country.id",
                "workflowConfigs.teamMemberProfile.id"
            ],
            list: true,
            $f: (workflowConfigsByCountry, context, callback) => {
                global.app.I.TeamMemberProfile.find(
                    {
                        group: context.group,
                        fieldPath: ["id", "name"]
                    },
                    (error, teamMemberProfiles) => {
                        if (error) return callback(error);
                        callback(
                            null,
                            groupConfigsByTeamMemberProfiles(teamMemberProfiles, workflowConfigsByCountry.workflowConfigs)
                        );
                    }
                )
            },
            $s: (teamMemberProfileConfigs, workflowConfigsByCountry, context, callback) => {
                workflowConfigsByCountry.workflowConfigs = getNewWorkflowConfigs({
                    country: workflowConfigsByCountry.country,
                    previousWorkflowConfigs: workflowConfigsByCountry.workflowConfigs || [],
                    teamMemberProfileConfigs
                })

                callback();
            }
        },
        {
            path: "workflowConfigs",
            type: "WorkflowConfig",
            link: {
                type: "OTM",
                onParent: true,
                onChild: false,
            }
        },
        {
            path: 'teamMemberProfileNamesByProfile',
            lazy: true,
            fieldPath: [
                "workflowConfigs.id",
                "workflowConfigs.teamMemberProfile.id"
            ],
            f: function() {
                return _(this.workflowConfigs)
                    .groupBy("profile")
                    .mapValues(getOrderedStringForWorkflowConfigs)
                    .value()
            }
        },
        {
            path: 'formXTeamMemberProfiles',
            lazy: true,
            fieldPath: ['teamMemberProfileNamesByProfile'],
            f: function() {
                return this.teamMemberProfileNamesByProfile["formX"];
            }
        },
        {
            path: 'buildOfferTeamMemberProfiles',
            lazy: true,
            fieldPath: ['teamMemberProfileNamesByProfile'],
            f: function() {
                return this.teamMemberProfileNamesByProfile["buildOffer"];
            }
        },
        {
            path: 'submitOfferTeamMemberProfiles',
            lazy: true,
            fieldPath: ['habFunctionNamesByProfile'],
            f: function() {
                return this.teamMemberProfileNamesByProfile["submitOffer"];
            }
        },
        {
            path: 'realisationTeamMemberProfiles',
            lazy: true,
            fieldPath: ['habFunctionNamesByProfile'],
            f: function() {
                return this.teamMemberProfileNamesByProfile["realisation"];
            }
        }
    ],
    validateSave: (object, oldObject, context, callback) => callback(
        validateHabFunctionConfigs(object.teamMemberProfileConfigs, context)
    )
};

const TeamMemberProfileConfig = {
    name: "TeamMemberProfileConfig",
    type: "mongoInternal",
    fields: [
        "TeamMemberProfile",
        {path: "formX_active", type: "boolean"},
        {path: "formX_order", type: "integer"},
        {path: "buildOffer_active", type: "boolean"},
        {path: "buildOffer_order", type: "integer"},
        {path: "submitOffer_active", type: "boolean"},
        {path: "submitOffer_order", type: "integer"},
        {path: "realisation_active", type: "boolean"},
        {path: "realisation_order", type: "integer"}
    ]
};

const WorkflowConfig = {
    name: "WorkflowConfig",
    fields: [
        'profile',
        { path: 'order', type: 'integer' },
        'Country',
        'TeamMemberProfile'
    ]
};



const StatusUser = {
    name: 'StatusUser',
    fields: [
        {path: 'step', index: true},
        {path: 'order', type: 'integer', index: true},
        {path: 'active', type: 'boolean', index: true},
        {type: 'User', index: true},
        {type: "BusinessProject", index: true, link: {oppositeField: {link: {deleteType: "cascade"}}}},
        'Country',
        'TeamMember'
    ]
}
const StaticWorkflow = {
    name: 'StaticWorkflow',
    fields: [
        'step',
        {path: 'order', type: 'integer'},
        {path: 'active', type: 'boolean'},
        {type: "BusinessProject", index: true, link: {oppositeField: {link: {deleteType: "cascade"}}}},
        'Country',
        'TeamMember'
    ]
}

const WorkflowExceptionStep = {
    name: 'WorkflowExceptionStep',
    fields: [
        'ActiveProjectStep',
        {path: 'teamMemberProfiles', type: 'TeamMemberProfile', link: 'MTM'},
    ]
}

const WorkflowException = {
    name: 'WorkflowException',
    fields: [
        'Country',
        {path: 'minTurnover', type: 'integer'},
        {path: 'maxTurnover', type: 'integer'},
        {path: 'typeOfOffers', type: 'TypeOfOffer', link: 'MTM'},
        {
            type: "WorkflowExceptionStep",
            link: {
                type: 'OTM',
                external: false
            }
        },
    ]
}

const WorkflowExceptionModule = {
    object: "WorkflowException",
    tKey: "workflowException",
    category: {
        path: "configuration",
        icon: 'settings'
    },
    viewMap: {
        dt: [ "country",
            'minTurnover',
            'maxTurnover',
            'typeOfOffers'
        ],
        form: {
            fields: [
                'country',
                'minTurnover',
                'maxTurnover',
                {path: 'typeOfOffers', type: "tags"},
                {
                    path: "workflowExceptionSteps",
                    tKey: "exclusions",
                    viewMap: {
                        dt: [
                            {path: 'activeProjectStep', tKey: 'step'},
                            {path: 'teamMemberProfiles', type: "tags", tKey: 'functions'}
                        ],
                        form: [
                            {path: 'activeProjectStep', tKey: 'step'},
                            {path: 'teamMemberProfiles', type: "tags", tKey: 'functions'}
                        ]
                    }
                }
            ]
        }
    }
}
const WorkflowConfigModule = {
    object: "WorkflowConfigsByCountry",
    tKey: "workflow",
    category: {
        path: "configuration",
        icon: 'settings'
    },
    defaultSortBy: 'country',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            "country",
            {path: "formXTeamMemberProfiles", tKey: "formX"},
            {path: "buildOfferTeamMemberProfiles", tKey: "buildOffer"},
            {path: "submitOfferTeamMemberProfiles", tKey: "submitOffer"},
            {path: "realisationTeamMemberProfiles", tKey: "realisation"}
        ],
        form: {
            fields: [
                {path: "country", editable: false},
                {
                    path: "teamMemberProfileConfigs",
                    type: "dtObjects",
                    tKey: "workflow",
                    fields: [
                        {path: "teamMemberProfile", tKey: "workflowConfiguration"},
                        {path: "formX_active", tKey: "formX", type: "checkbox"},
                        {path: "formX_order", tKey: "order", type: "editText"},
                        {path: "buildOffer_active", tKey: "buildOffer", type: "checkbox"},
                        {path: "buildOffer_order", tKey: "order", type: "editText"},
                        {path: "submitOffer_active", tKey: "submitOffer", type: "checkbox"},
                        {path: "submitOffer_order", tKey: "order", type: "editText"},
                        {path: "realisation_active", tKey: "realisation", type: "checkbox"},
                        {path: "realisation_order", tKey: "order", type: "editText"},
                    ]
                }
            ],
            dataLists: ["workflowConfigsByCountry-teamMemberProfile"],
            onLoad: ({ module, store }) => {
                const state = store.getState();
                const teamMemberProfiles = getDataListList(
                    state,
                    'workflowConfigsByCountry-teamMemberProfile'
                );


                const teamMemberProfileConfigs = teamMemberProfiles.map(
                    teamMemberProfileConfigFromWorkflowConfigs({})
                );

                store.dispatch(
                    setEditDefaultObject({
                        teamMemberProfileConfigs
                    })
                );
            }
        }
    },
    stateSubscriptions: [{
        statePath: 'form.editObject.values.country',
        subscription: (newValue, previousValue, { store, formInitialize, formDestroy }) => {
            // we are not interested in the changes made by redux-form library
            if (!formInitialize && !formDestroy) {
                try {
                    const state = store.getState();

                    // teamMemberProfileConfigs are all possible teamMemberProfileConfigs
                    const teamMemberProfileConfigs = _.get(state, "edit.defaultObject.teamMemberProfileConfigs");

                    // recover a full country, because the newValue contains only id
                    const country = getCountryConfigurationFromDataList(
                        newValue,
                        state.dataLists.byId["m-I-workflowConfigsByCountry.WorkflowConfigsByCountry_country"]
                    )

                    // function to test if teamMemberProfileConfig corresponds to the country
                    const correspondsToCountry = compose([
                        teamMemberProfileCorresponds(country),
                        _.partialRight(_.get, "teamMemberProfile")
                    ])

                    const filteredObjects = teamMemberProfileConfigs.filter(
                        correspondsToCountry
                    );

                    // old objects to recover information like checks
                    const oldObjects = _.get(state, "form.editObject.values.teamMemberProfileConfigs", []);
                    const oldObjectsMap = oldObjects.reduce(
                        (acc, object) => ({
                            [_.get(object, "teamMemberProfile.id")]: object,
                            ...acc
                        }),
                        {}
                    );

                    // recover previous objects if some were already filled
                    const newObjects = filteredObjects.map(
                        teamMemberProfileConfig => oldObjectsMap[teamMemberProfileConfig.teamMemberProfile.id] || teamMemberProfileConfig
                    );

                    store.dispatch(change('editObject', 'teamMemberProfileConfigs', newObjects))

                } catch (error) {
                    console.log("error executing slp workflow subscription");
                    console.warn(error);
                }
            }
        }
    }],
    accesses: [
        {
            id: "workflowConfigsByCountry-teamMemberProfile",
            entity: "TeamMemberProfile",
            fieldPath: [
                "id",
                "name",
                "teamMembers.id",
                "teamMembers.countrys.id"
            ],
            filters: []
        }
    ]
};

export const entities = [
    WorkflowExceptionStep,
    WorkflowException,
    WorkflowConfigsByCountry,
    TeamMemberProfileConfig,
    WorkflowConfig,
    StatusUser,
    StaticWorkflow
];

export const modules = [
    WorkflowConfigModule,
    WorkflowExceptionModule
];
