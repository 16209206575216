import React from 'react'
import { Field } from 'redux-form'
import RichTextEditor from '../../../../components/Form/RichTextEditor'
import QuillTextEditor from '../../../../components/Form/QuillTextEditor'
import getFormValidations from './formValidations'

const Input = ({ input, meta:{touched, error}, path, placeholder, editable, required, disabled, readOnly, style, t, initialValue  }) => {
    const translatedError = t(error)
    return <QuillTextEditor key={initialValue} {...input} path={path} touched={touched ? 'true' : 'false'} error={translatedError} placeholder={placeholder} required={required} disabled={!editable || disabled} t={t} initialValue readOnly={readOnly} style={style} />
}

const FormTextField = ({ field, t, objectMode, initialValues }) => {
    const initialValue = initialValues && initialValues[field.path]
    const validations = getFormValidations(field, t)
    return (
        <Field
            name={field.path}
            path={field.tKey || field.path}
            component={Input}
            validate={validations}
            initialValue={initialValue}
            placeholder={field.placeholder}
            required={field.required}
            editable={!field.editable ? objectMode === 'newObject' : field.editable  }
            disabled={field.disabled || !field.writable}
            readOnly={field.readOnly}
            style={field.style}
            t={t}
        />
    )
}

export default FormTextField
