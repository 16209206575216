import async from "async";
import {fetchDtData, fetchFormObject, SAVE_OBJECT_SUCCESS} from "../../../apps/KpModule/actions/api"
import {setObjectMode} from "../../../apps/KpModule/actions"
const _ = require("lodash")
const moment = require("moment")
const { v4: uuid } = require('uuid')
const {encrypt, decrypt} = require("../../utils/crypto")
const Errors = require("../../utils/Errors").default
const {setFormButtons, setFieldEdition, setFieldVisibility, setFieldRemovable, setFieldNewable} = require("../../../apps/KpModule/actions")
const {getObjectMode, getUser, getGroupModel} = require("../../../apps/KpModule/selectors")
const {validateEmail} = require("../../../apps/KpModule/components/Form/formValidations")

const cacheEventsOnContext = function(object, context, callback) {
    // fetch events if are already fetched
    if (!context.data || !context.data.events || !context.data.events[object.id]) {
        global.db.collection("mailEvent").find({
            "message-id": {$in: _.compact(object.mails).map(messageId => `<${messageId}>`)}
        }).toArray((e, results) => {
            if(!context.data) context.data = {}
            if(!context.data.events) context.data.events = {}
            context.data.events[object.id] = results

            callback(null, context);
        })
    } else {
        callback(null, context);
    }
}

export const entities = [
    {
        name: "EmailLanguage",
        type: "static",
        facets: ["translatedField"],
        fields: [
            {path: "id", type: "string"},
            {path: "name", type: "string"},
        ],
        filters: [
            {
                name: 'ByClient',
                isDefault: true,
                match: function (object) {
                    return global.config.languages.includes(object.id)
                }
            }
        ],
        objects: [
            {id: "en", name: 'english'},
            {id: "es", name: 'español'},
            {id: "fr", name: 'français'},
            {id: "it", name: "italiano"},
            {id: "nl", name: "nederlands"}
        ]
    },
    {
        name: "BroadcastListPlatform",
        fields: [
            {path: "name", unique: true},
            {path: 'description'},
            {type: "Profile", link: "MTM"},
        ]
    },
    {
        name: "EmailState",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            {path: "name", type: "string"}
        ],
        objects: [
            {id: "active", name: "active"},
            {id: "archived", name: "archived"}
        ]
    },
    {
        name: "EmailMessage",
        facets: [{name: "files"}],
        fields: [
            {path: "language", type: "EmailLanguage"},
            {path: "emailSubject"},
            {path: "message"},
        ]
    },
    {
        name: "Recipients",
        type: "mongoInternal",
        fields: [
            {path: "mails"},
            {path: "language", type: "EmailLanguage"},
        ]
    },
    {
        name: "MessagingPlatform",
        fields: [
            {path: "title", unique: true},
            "description",
            {path: "broadcastLists", type: "BroadcastListPlatform", link: "MTM", nullable: true},
            {path: 'createdBy', type: 'User', nullable: true},
            {path: 'sentBy', type: 'User', nullable: true},
            {path: "recipients", type: "User", link: "MTM", nullable: true},
            {path: "testRecipients", type: "User", link: "MTM", nullable: true},
            {path: "otherRecipients", type: "Recipients", link: "OTM"},
            {path: "archived", type: "boolean"},
            {path: "delayedSending", type: "boolean", nullable: true},
            {path: "date"},
            {path: 'shareWith', type: 'User', link: 'MTM', nullable: true},
            {path: "mails", list: true},
            {
                path: "sent",
                fieldPath: ['mails', 'status'],
                $f: function (message, context, callback) {
                    cacheEventsOnContext(
                        message,
                        context,
                        (e, context) => {
                            const filteredEvents = context.data.events[message.id].filter(eventObject => eventObject.event === 'request')
                            const uniqEvents = _.uniqBy(filteredEvents, event => event["message-id"])
                            return callback(e, uniqEvents.length)
                        }
                    )
                }
            },
            {
                path: 'delivered',
                fieldPath: ['mails', 'status'],
                $f: function (message, context, callback) {
                    cacheEventsOnContext(
                        message,
                        context,
                        (e, context) => {
                            const filteredEvents = context.data.events[message.id].filter(eventObject => eventObject.event === 'delivered')
                            const uniqEvents = _.uniqBy(filteredEvents, event => event["message-id"])
                            return callback(e, uniqEvents.length)
                        }
                    )
                }
            },
            {
                path: 'opened',
                fieldPath: ['mails', 'status'],
                $f: function (message, context, callback) {
                    cacheEventsOnContext(
                        message,
                        context,
                        (e, context) => {
                            const filteredEvents = context.data.events[message.id].filter(eventObject => ['unique_opened', 'unique_proxy_open'].includes(eventObject.event))
                            const groupedByMessageId = _.groupBy(filteredEvents, 'message-id')
                            const uniqueMessageIds = Object.keys(groupedByMessageId)
                            return callback(e, uniqueMessageIds.length)
                        }
                    )
                }
            },
            {
                path: 'clicked',
                fieldPath: ['mails', 'status'],
                $f: function (message, context, callback) {
                    cacheEventsOnContext(
                        message,
                        context,
                        (e, context) => {
                            const filteredEvents = context.data.events[message.id].filter(eventObject => eventObject.event === 'click')
                            const groupedByMessageId = _.groupBy(filteredEvents, 'message-id')
                            const uniqueMessageIds = Object.keys(groupedByMessageId)
                            return callback(e, uniqueMessageIds.length)
                        }
                    )
                }
            },
            {
                path: 'blocked',
                fieldPath: ['mails', 'status'],
                $f: function (message, context, callback) {
                    cacheEventsOnContext(
                        message,
                        context,
                        (e, context) => {
                            const filteredEvents = context.data.events[message.id].filter(eventObject => ['hard_bounce', 'blocked'].includes(eventObject.event))
                            const groupedByMessageId = _.groupBy(filteredEvents, 'message-id')
                            const uniqueMessageIds = Object.keys(groupedByMessageId)
                            return callback(e, uniqueMessageIds.length)
                        }
                    )
                }
            },
            {
                path: "mailsFollowup",
                fieldPath: ['mails', 'recipients.mail', 'recipients.name', 'status'],
                $f: function (message, context, callback) {
                    if(message.status !== 'sent') return callback(null, [])

                    global.db.collection("mailEvent").aggregate([
                        {
                            $match: {"message-id": {$in: _.compact(message.mails).map(messageId => `<${messageId}>`)}}
                        },
                        {
                            $group: {
                                _id: "$message-id",
                                messageId: {$first: "$message-id"},
                                email: {$first: "$email"},
                                events: {$push: {event: "$event", date: "$event_date"}},
                            }
                        }
                    ]).toArray((e, results) => {
                        return callback(e, results.map(result => {
                            const recipient = message.recipients.find(recipient => decrypt(recipient.mail) === result.email)
                            const sendEvent = result.events.find(object => object.event === 'request')
                            const deliveredEvent = result.events.find(object => object.event === 'delivered')
                            const opened = result.events.find(object => object.event === 'unique_opened')
                            const proxyOpen = result.events.find(object => object.event === 'unique_proxy_open')
                            const clickedEvent = result.events.find(object => object.event === 'click')
                            const blockedEvent = result.events.find(object => object.event === 'blocked')
                            const hardBounceEvent = result.events.find(object => object.event === 'hard_bounce')

                            return ({
                                id: result.messageId,
                                recipients: recipient ? recipient.name : result.email,
                                sent: sendEvent && moment(sendEvent.date).format('YYYY-MM-DD HH:mm:ss'),
                                delivered: deliveredEvent && moment(deliveredEvent.date).format('YYYY-MM-DD HH:mm:ss'),
                                opened: opened
                                    ? moment(opened.date).format('YYYY-MM-DD HH:mm:ss')
                                    : proxyOpen
                                        ? moment(proxyOpen.date).format('YYYY-MM-DD HH:mm:ss')
                                        :'',
                                clicked: clickedEvent && moment(clickedEvent.date).format('YYYY-MM-DD HH:mm:ss'),
                                blocked: !!blockedEvent || !!hardBounceEvent ,
                            })
                        }))
                    })
                }
            },
            {path: "numberOfRecipients", fieldPath: ["otherRecipients"], type: 'integer', $f: (object, context, callback) => {
                    callback(
                        null,
                        _.concat(
                            object.recipients,
                            object.otherRecipients ?
                                _.flatMap(object.otherRecipients, line => line.mails.split(';'))
                                : []
                        ).length
                    )
                }},
            {path: 'messages', type: 'EmailMessage', link: 'OTM'},
            {path: 'status'},
            {path: 'sendDate'},
            {
                path: "noDeleteButtonAccess",
                fieldPath: ['createdBy.id', 'shareWith.id'],
                $f: function (message, context, callback) {
                    const isMessageCreator = _.get(message, 'createdBy.id') === _.get(context, 'user.id')
                    const isSharedWith = message.shareWith?.map(user => user.id).includes(_.get(context, 'user.id'))
                    callback(null, !isMessageCreator && !isSharedWith)
                }
            },
            {
                path: "sender",
                fieldPath: ['sentBy.mail'],
                $f: function (message, context, callback) {
                    const senderMail = _.get(message, 'sentBy.mail')
                    callback(null, senderMail && decrypt(senderMail))
                }
            }
        ],
        correctEmailFormat: function(object, oldObject, context, callback) {
            let wrongEmail
            const otherRecipients = _.flatMap(object.otherRecipients, line => line.mails.split(';'))

            const duplicates = otherRecipients.some(
                recipient => {
                    const isValid = validateEmail(recipient)
                    if(!isValid) {
                        wrongEmail = recipient
                    }
                    return !isValid
                }
            )

            if(duplicates) return callback(new Errors.ValidationError(context.tc('incorrectEmailFormat', {mail: wrongEmail})))
            return callback()
        },
        uniqueRecipients: function(object, oldObject, context, callback) {
            let duplicateMail
            const recipients = object.recipients.map(user => ({mail: user.mail && decrypt(user.mail)}))
            const otherRecipients = _.flatMap(object.otherRecipients, line => line.mails.split(';').map(mail => ({mail, language: line.language.id})))
            const duplicates = otherRecipients.some(
                otherRecipient => recipients.some(
                    recipient => {
                        const isDuplicate = recipient.mail === otherRecipient.mail
                        if(isDuplicate) {
                            duplicateMail = recipient.mail
                        }
                        return isDuplicate
                    }
                )
            )
            if(duplicates) return callback(new Errors.ValidationError(context.tc('duplicateUser', {mail: duplicateMail})))
            return callback()
        },
        uniqueOtherRecipients: function(object, oldObject, context, callback) {
            let duplicateMail
            const otherRecipients = _.flatMap(object.otherRecipients, line => line.mails.split(';'))

            const duplicates = otherRecipients.some(
                (recipient, index) => {
                    const isDuplicate = otherRecipients.indexOf(recipient) !== index
                    if(isDuplicate) {
                        duplicateMail = recipient
                    }
                    return isDuplicate
                }
            )

            if(duplicates) return callback(new Errors.ValidationError(context.tc('duplicateOtherRecipient', {mail: duplicateMail})))
            return callback()
        },
        uniqueMessageByLanguage: function(object, oldObject, context, callback) {
            let duplicateLanguage

            const messagesLanguages = object.messages.map(message => _.get(message, 'language.name'))

            const duplicates = messagesLanguages.some(
                (language, index) => {
                    const isDuplicate = messagesLanguages.indexOf(language) !== index
                    if(isDuplicate) {
                        duplicateLanguage = language
                    }
                    return isDuplicate
                }
            )

            if(duplicates) return callback(new Errors.ValidationError(context.tc('duplicateMessageLanguage', {language: duplicateLanguage})))
            return callback()
        },
        requiredEmailLanguages: function(object, oldObject, context, callback) {
            let missingLanguage

            const recipients = object.otherRecipients
                ? _.concat(
                    object.recipients,
                    _.flatMap(object.otherRecipients, line => line.mails.split(';').map(mail => ({mail, language: line.language})))
                )
                : object.recipients

            const isMissingLanguage = recipients.some(
                recipient => {
                    const isLanguagePresent = object.messages.some(message => message.language.id === recipient.language.id)
                    if(!isLanguagePresent) {
                        missingLanguage = recipient.language.name
                    }
                    return !isLanguagePresent
                }
            )
            if(isMissingLanguage) {
                return callback(new Errors.ValidationError(context.tc('emailIsMissingLanguage', {language: missingLanguage})))
            }
            return callback()
        },
        requiredRecipients: function(object, oldObject, context, callback) {
            if(context.action === "validate" && !object.recipients?.length && !object.otherRecipients?.length) return callback(new Errors.ValidationError(context.tc('noRecipientsFound')))
            return callback()
        },
        testRecipientsEmpty: function(object, oldObject, context, callback) {
            if(context.action === "sendTest" && !object.testRecipients?.length) return callback(new Errors.ValidationError(context.tc('testRecipientsEmpty')))
            return callback()
        },
        validateSave: function(object, oldObject, context, callback) {
            async.series([
                callback => this.correctEmailFormat(object, oldObject, context, callback),
                callback => this.testRecipientsEmpty(object, oldObject, context, callback),
                callback => this.uniqueRecipients(object, oldObject, context, callback),
                callback => this.uniqueOtherRecipients(object, oldObject, context, callback),
                callback => this.requiredEmailLanguages(object, oldObject, context, callback),
                callback => this.requiredRecipients(object, oldObject, context, callback),
                callback => this.uniqueMessageByLanguage(object, oldObject, context, callback)
            ], callback)
        },
        beforeSave: async (object, oldObject, context, callback) => {
            const action  = context.restAction && context.restAction.crudType
            if(action === 'C') {
                object.createdBy = {
                    id: _.get(context, 'user.id'),
                    name: _.get(context, 'user.name'),
                    mail: encrypt(_.get(context, 'user.mail'))
                }
                object.status = 'draft'
            }
            if(context.action === "toArchive") {
                object.archived = true
                return callback(null, object, oldObject)
            }

            if (context.action === "duplicate") {
                return handleDuplicateAction(object, context, e => callback(e, object, oldObject))
            }

            if(["sendTest", "validate"].includes(context.action) && object.status === 'draft') {
                object.sentBy = {
                    id: _.get(context, 'user.id'),
                    name: _.get(context, 'user.name'),
                    mail: encrypt(_.get(context, 'user.mail'))
                }
                return registerMailsInQueue(object, context, (e, messageIds) => {
                    if(!e && context.action === "validate") {
                        object.status = 'sent'
                        object.sendDate = moment().format('YYYY-MM-DD HH:mm:ss')
                        object.mails = messageIds
                    }
                    callback(e, object, oldObject)
                })
            }

            return callback(null, object, oldObject)
        }
    }
]

function registerMailsInQueue(object, context, callback) {
    const mails = prepareEmails(object, context);
    const messageIds = []
    return global.app[context.model.id].EmailsQueue.collection.insertMany(
        mails.map(mail => {
            const messageId = uuid()
            messageIds.push(messageId)
            return {
                ..._.pick(mail, ["from", "to", "replyTo", "subject", "content", "signature", "signatureFiles", "attachments"]),
                contentType: "html",
                messageId,
                delayedSending: !!object.delayedSending,
                date: object.date,
                group: global.ObjectID(context.group.id),
                creationDate: new Date(),
                status: "waiting"
            }
        }),
        (e) => callback(e, messageIds)
    )
}

function prepareEmails(object, context) {
    const defaultMail = prepareDefaultMail(object, context)
    const recipients = context.action === "sendTest"
        ? getTestRecipients(object)
        : getUniqueRecipients(object)

    const groupModelUrl = global.isProd
        ? `https://${context.host}/business/${context.groupModel.id}`
        : `http://localhost:3000/business/${context.groupModel.id}`

    return recipients.map(user => {
        const messageElements = getMessageElements(object, user);

        return _.defaults({
            to: user.mail,
            content: `<div class="kp-container">${messageElements.message}<br/><div style="font-size: 11px"><a href="${groupModelUrl}/communication/m-${context.model.id}-userSubscription">${context.tl('unsubscribe', user.language?.id)}</a></div></div>`,
            subject: messageElements.emailSubject,
            attachments: messageElements.files.map(file => _.pick(file, ['id', 'filename']))
        }, defaultMail)
    })
}

function prepareDefaultMail(object, context) {
    const userName = _.get(object, 'sentBy.name')
    const userMail = _.get(object, 'sentBy.mail')

    const replyTo = context.group.useNoReply
        ? context.group.noReply
        : decrypt(userMail)

    const alias = context.group.useNoReply
        ? context.group.alias
        : userName

    return {
        from: `"${alias}"${replyTo}`,
        replyTo: replyTo,
        signature: false,
        signatureFiles: context.user.signature.map(signature => _.pick(signature, ["id", "date", "filename", "password"])),
        verbose: {
            general: true,
        }
    }
}

function getTestRecipients(object) {
    return object.testRecipients.map(user => ({ mail: user.mail && decrypt(user.mail), language: user.language }))
}

function getUniqueRecipients(object) {
    return object.otherRecipients
        ? _.concat(
            object.recipients.filter(user => !user.unsubscribed).map(user => ({ mail: user.mail && decrypt(user.mail), language: user.language })),
            _.flatMap(object.otherRecipients, line => line.mails.split(';').map(mail => ({ mail, language: line.language.id })))
        )
        : object.recipients.map(user => ({ mail: user.mail && decrypt(user.mail), language: user.language }))
}

function getMessageElements(object, user) {
    let messageElements = object.messages.find(message => message.language.id === (user.language?.id));
    if (!messageElements) messageElements = object.messages.find(message => message.language.id === 'fr');
    if (!messageElements) messageElements = object.messages[0];

    return messageElements;
}

function handleDuplicateAction(object, context, callback) {
    const messageCopy = createDuplicateMessage(object, context);

    global.app[context.model.id].MessagingPlatform.collection.insertOne(messageCopy, (e, result) => {
        const messages = prepareMessagesForDuplicate(object, result, context);

        global.app[context.model.id].EmailMessage.collection.insertMany(messages, callback)
    })
}

function createDuplicateMessage(object, context) {
    return {
        title: object.title + ' copie',
        description: object.description,
        createdBy: global.ObjectID(context.user.id),
        recipients: object.recipients.map(recipient => global.ObjectID(recipient.id)),
        otherRecipients: object.otherRecipients.map(recipients => ({
            mails: recipients.mails,
            language: _.get(recipients, 'language.id')
        })),
        status: 'draft',
        archived: false,
        group: global.ObjectID(context.group.id)
    };
}

function prepareMessagesForDuplicate(object, result, context) {
    return object.messages.map(message => ({
        language: _.get(message, 'language.id'),
        emailSubject: message.emailSubject,
        message: message.message,
        files: message.files.map(file => ({
            _id: global.ObjectID(file.id),
            ..._.pick(file, ['filename', 'date', 'user']),
            group: global.ObjectID(context.group.id)
        })),
        messagingPlatform: result.insertedId,
        group: global.ObjectID(context.group.id)
    }));
}

export const modules = [
    {
        tKey: "m-T-BroadcastLists",
        object: "BroadcastListPlatform",
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        objectIdentifier: 'name',
        defaultSortBy: "name",
        defaultSortDirection: "ASC",
        viewMap: {
            dt: [
                {path: "name"},
                {path: "description", type: "textarea", width: 250},
                {path: "profiles", translateName: true, width: 400}
            ],
            form: [
                {path: "name", required: true},
                {path: "description", type: "textarea"},
                {path: "profiles", type: "tags", filters: ["thisModel"], editable: false, translateName: true}
            ]
        }
    },
    {
        tKey: "m-T-Messaging",
        object: "MessagingPlatform",
        defaultSortBy: 'sendDate',
        objectIdentifier: 'title',
        defaultSortDirection: 'DESC',
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        workflowActions: [
            'toArchive'
        ],
        viewMap: {
            dt: [
                "title",
                {path: 'status', translate: true, width: 70},
                {path: 'sender'},
                {path: 'numberOfRecipients', tKey: 'recipients', width: 120},
                {path: 'sendDate', tKey: "dateAndTime", width: 150},
                {path: 'sent', width: 80},
                {path: 'delivered', width: 80},
                {path: 'opened', width: 80},
                {path: 'clicked', width: 80},
                {path: 'blocked', width: 80},
                {path: "noDeleteButtonAccess", hidden: true}
            ],
            form: {
                fields: [
                    {path: "title", required: true},
                    {path: "description", type: "textarea"},
                    {path: 'sender', disabled: true},
                    {
                        path: "broadcastLists",
                        fieldPath: ['id', 'name', 'profiles.id'],
                        subscriptions: {
                            onChange: (newValue, oldValue, { module, store, formInitialize, getObjectSuccessAction }) => {
                                if(!formInitialize && !getObjectSuccessAction) {
                                    const statusField = module.viewMap.form.fields.find(
                                        field => field.path === 'status'
                                    )

                                    if(statusField.getValue() === 'sent') return

                                    const state = store.getState()
                                    const recipientsField = module.viewMap.form.fields.find(
                                        field => field.path === 'recipients'
                                    )

                                    const list = state.dataLists.byId[`m-${module.model}-messagingPlatform.MessagingPlatform_recipients`].list
                                    recipientsField.setValue(
                                        newValue && !!newValue.length
                                            ? list.allIds
                                                .filter(id => list.byId[id].groupModelProfiles.some(
                                                    profileObject => newValue.some(
                                                        broadcastList => broadcastList.profiles.some(profile => profile.id === profileObject.id))
                                                    )
                                                )
                                                .map(id => list.byId[id])
                                            : []
                                    )
                                }
                            }
                        }
                    },
                    {
                        path: "recipients",
                        tKey: "recipient(s)",
                        type: 'dualBoxList',
                        display: 'completeInfo',
                        fieldPath: ['id', 'language.id', 'mail', 'name', 'groupModelProfiles', 'completeInfo', 'unsubscribed'],
                        filters: ['isActive', 'isInGroupModel', 'userKNotAllowed', 'notUnsubscribed']
                    },
                    {
                        path: "otherRecipients",
                        tKey: "otherRecipient(s)",
                        defaultSortBy: "language",
                        defaultSortDirection: "ASC",
                        removable: true,
                        viewMap: {
                            dt: [
                                'language',
                                {path: 'mails', tKey: 'mail(s)'},
                            ],
                            form: [
                                {path: 'language', fieldPath: ['id', 'name'], required: true, default: {id: 'fr'}},
                                {path: 'mails', tKey: 'mail(s)', type: 'creatableTags', placeholder: 'typeEmailAddressAndPressEnter', required: true},
                            ]
                        }
                    },
                    {
                        path: "testRecipients",
                        tKey: "testRecipient(s)",
                        display: 'completeInfo',
                        fieldPath: ['id', 'language.id', 'mail', 'name', 'groupModelProfiles', 'completeInfo', 'unsubscribed'],
                        filters: ['isActive', 'isInGroupModel', 'userKNotAllowed', 'notUnsubscribed']
                    },
                    {
                        path: "messages",
                        removable: true,
                        required: true,
                        defaultSortBy: 'language',
                        defaultSortDirection: 'ASC',
                        viewMap: {
                            dt: ["language", "emailSubject"],
                            form: [
                                {path: "language", display: 'name', required: true, default: {id: 'fr'}},
                                {path: "emailSubject", required: true},
                                {path: "message", type: 'richTextEditor', required: true},
                                {path: "files"}
                            ]
                        }
                    },
                    {
                        path: "mailsFollowup",
                        type: 'dtObjects',
                        fields: [
                            {path: 'recipients'},
                            {path: 'sent'},
                            {path: 'delivered'},
                            {path: 'opened'},
                            {path: 'clicked'},
                            {path: 'blocked', type: 'failureIndicator'}
                        ]
                    },
                    {path: "shareWith", tKey: "share"},
                    {
                        path: "delayedSending",
                        subscriptions: {
                            onChange: (newValue, oldValue, { store }) => {
                                store.dispatch(setFieldVisibility("e_date", !!newValue))
                            }
                        }
                    },
                    {path: "date", tKey: "dateAndTime", type: "datePicker", showTime: true},
                    {path: "archived", tKey: "toArchive", default: false},
                    {path: "status", hidden: true},
                    {path: "mails", hidden: true},
                    {path: "createdBy", hidden: true},
                    {path: "sentBy", hidden: true},
                ],
                onOpen: ({ store }) => {
                    const state = store.getState()
                    const objectMode = getObjectMode(state)
                    const isNewObject = objectMode === 'newObject'

                    const user = getUser(state)
                    const isOwner = _.get(state, 'edit.object.data.createdBy.id') === user.id
                    const isSharedWith = state.edit.object.data.shareWith?.map(user => user.id).includes(user.id)
                    const userHasRights = isOwner || isSharedWith

                    const status = _.get(state, 'edit.object.data.status')
                    const isSent = status === 'sent'

                    const objectIsEditable =  isNewObject || (userHasRights && !isSent)

                    if(objectMode === 'newObject') {
                        store.dispatch(setFieldVisibility(`e_date`, false))
                    }

                    // visible only when mail is sent not editable
                    const group1 = ['sender', 'mailsFollowup']
                    group1.forEach(
                        path => store.dispatch(setFieldVisibility(`e_${path}`, isSent))
                    )


                    // visible id mail not sent
                    const group2 = ['broadcastLists', 'recipients', 'otherRecipients', 'testRecipients']
                    group2.forEach(
                        path => store.dispatch(setFieldVisibility(`e_${path}`, isNewObject || !isSent))
                    )


                    // editable if not sent and id user had rights
                    const group3 = ['title', 'description', 'broadcastLists', 'recipients', 'otherRecipients', 'testRecipients', 'shareWith', 'messages', 'delayedSending', 'date']
                    group3.forEach(
                        path => store.dispatch(setFieldEdition(`e_${path}`, objectIsEditable))
                    )

                    // only newable and removable when updatable
                    const group4 = ['otherRecipients', 'messages']
                    group4.forEach(
                        path => {
                            store.dispatch(setFieldNewable(`e_${path}`, objectIsEditable))
                            store.dispatch(setFieldRemovable(`e_${path}`, objectIsEditable))
                        }
                    )

                    store.dispatch(setFormButtons(
                        objectIsEditable
                            ? [
                                {type: "action", tKey: 'send', bsStyle: 'primary', action: 'validate'},
                                {type: "action", tKey: 'saveAndSendTest', bsStyle: 'warning', action: 'sendTest'},
                                {type: "save", tKey: 'save', bsStyle: 'success'},
                                {type: "return", tKey: 'return', bsStyle: 'default'}
                            ]
                            : userHasRights
                                ? [
                                    {type: "action", tKey: "makeACopy", bsStyle: 'primary', action: "duplicate"},
                                    {type: "save", tKey: 'save', bsStyle: 'success'},
                                    {type: "return", tKey: 'return', bsStyle: 'default'}
                                ]
                                : [{type: "return", tKey: 'return', bsStyle: 'default'}]
                    ))
                }
            }
        },
        actionSubscriptions: [
            {
                actionType: SAVE_OBJECT_SUCCESS,
                subscription: ({ store }) => {
                    const state = store.getState()
                    const moduleId = _.get(state, 'module.id')
                    store.dispatch(fetchDtData(moduleId))
                }
            }
        ],
        filters: [
            {
                path: 'state',
                object: 'EmailState',
                client: true,
                display: "name",
                translate: true,
                default: {id: 'active'},
                query: function(context) {
                    const state = context.data && context.data.state

                    return state
                        ? { archived : state.id === "archived"}
                        : {}
                }
            }
        ]
    },
    {
        tKey: "m-T-Subscription",
        kp: true,
        object: "User",
        name: "UserSubscription",
        category: {
            path: 'communication',
            icon: 'messageCircle'
        },
        objectIdentifier: 'name',
        defaultSortBy: "name",
        defaultSortDirection: "ASC",
        defaultFormButtons: [
            {
                type: 'save',
                tKey: 'validate',
                bsStyle: 'primary'
            },
            {
                type: 'return',
                tKey: 'return',
                bsStyle: 'default'
            }
        ],
        viewMap: {
            dt: {
                fields: [
                    {path: "name"},
                    {path: "unsubscribed"},
                ],
                onLoad: ({state, store}) => {
                    const userId = _.get(state, 'user.id')
                    store.dispatch(fetchFormObject(userId))
                    store.dispatch(setObjectMode('editObject'))
                }
            },
            form: [
                {path: "unsubscribed", tKey: 'Je souhaite me désabonner de toutes les communications par email'},
            ]
        },
        filters: [
            {
                title: "byUser",
                query: context => {
                    return {_id: global.ObjectID(context.user.id)}
                }
            }
        ],
        actionSubscriptions: [
            {
                actionType: SAVE_OBJECT_SUCCESS,
                subscription: ({store}) => {
                    const state = store.getState()
                    const groupModel = getGroupModel(state)
                    const history = require('../../../history').default
                    history.push(`/business/${groupModel.id}`)
                }
            }
        ]
    }
]
