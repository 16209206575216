const _ = require("lodash")
const Errors = require('../../utils/Errors').default

export const entity = {
    name: "Store",
    facets: ["codeName", "translatedField"],
    fields: [
        {
            type: "Organization",
            link: "MTM",

            ps: {
                object: [
                    {
                        $v: function (organizations) {
                            const organizationAxes = organizations.map(organization => organization.organizationAxis.id);
                            const countedAxes = _.countBy(organizationAxes);
                            if (_.some(countedAxes, value => value > 1)) return new Errors.ValidationError(this.options.context.tc("eachStoreShouldBeLinkedToDistinctOrganizationAxis"));
                        }
                    },
                    {
                        $v: function (organizations) {
                            if (!organizations || !organizations.length) return new Errors.ValidationError(this.options.context.tc("storeShouldBeLinkedToAtLeastOneOrganization"));
                        }
                    }
                ]
            }
        },
        {
            path: "organizationNb",
            lazy: true,
            fieldPath: ["organizations.id"],
            f: function()  {
                return this.organizations.length;
            }
        },
    ],

    // context process example
    // TODO doc this somewhere

    // ps: {
    //     object: [{$$u: function (store, callback) {
    //         if(this.options.$v){
    //             return callback(null, store);
    //         }
    //         callback(null, store);
    //
    //     }}],
    //     context: [{$$u: function(context, callback) {
    //         if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "U") {
    //             context.internalFieldPath.push(...["organizations.id", "organizations.code", "organizations.name"]);
    //         }
    //         callback(null, context);
    //     }}]
    // }
}

export const module_ = {
    object: "Store",
    tKey: "mTitle_store",
    category: {
        path: "referential",
        icon: 'server'
    },
    defaultSortBy: "code",
    defaultSortDirection: "ASC",
    // facets: [{name: "codeName", editableCode: false}],
    viewMap: {
        dt: [
            {path: "code", tooltip: true},
            {path: "name", type: "translatedText"},
            {path: "organizationNb", tKey: "attachment_number"}
        ],
        form: [
            {path: "code", editable: false},
            {path: "name", type: "textarea"},
            {path: "organizations", tKey: 'attachments', display: "completeName", required: false}
        ]
    },

    filters: [
        {
            path: "organizations",
            object: "Organization",
            display: "completeName",
            client: true,
            query: function (context) {
                const organizationId = context.data && context.data.organizations && context.data.organizations.id;
                if (organizationId) return {
                    "organizations": global.ObjectID(organizationId)
                }
            },
            clearable: false
        }
    ]
}
