const _ = require('lodash')
const moment = require('moment')
const async = require('async')
const path = require('path')
const mails = require('../mails').default
const { newContext } = require('../../../utils/contextUtils')
const workflow = require('../workflow')
const { translateName } = require('../../../../utils/i18n')
const { decrypt } = require('../../../utils/crypto')

const defaultMail = language => ({
    from: '"PilotFlow" <pilotflow@keenpoint.com>',
    replyTo: 'support@keenpoint.com',
    content: `${language}_newsAlertsFollowUp.html`,
    verbose: {
        general: true
    }
})

const filterAlertByUser = (userType, period, user, data) => {
    const alerts = _(data)
        .filter(o => o[userType].find(u => u.mail === user.mail))
        .groupBy(o => o.alertConf.name)
        .value()
    return Object.keys(alerts).map(o => ({
        name: o,
        [period]: alerts[o].length
    }))
}

const unionP1AndP2 = (data1, data2) => {
    const tmp = _.groupBy(_.concat(data1, data2), o => o.name)
    return Object.keys(tmp).map(o => ({
        name: o,
        p1: tmp[o].reduce((a, b) => {
            return a + (b.p1 || 0)
        }, 0),
        p2: tmp[o].reduce((a, b) => {
            return a + (b.p2 || 0)
        }, 0)
    }))
}

const filterNextUsers = (period, user, data) => {
    const nextUsersTmp = _.countBy(
        _(data)
            .filter(o => o.nextUsers.find(u => u.mail === user.mail))
            .flatMap(o => o.currentUsers)
            .value(),
        user => `${user.firstname} ${user.lastname}`
    )
    return Object.keys(nextUsersTmp).map(o => ({
        name: o,
        [period]: nextUsersTmp[o]
    }))
}

const structureData = data => {
    console.log('--> Structure the Data')

    const users = _.uniqBy(
        _.concat(data.p1.users, data.p2.users),
        user => user.mail
    )

    return users.map(user => {
        const p1CurrentAlert = filterAlertByUser(
            'currentUsers',
            'p1',
            user,
            data.p1.data
        )
        const p1NextAlert = filterAlertByUser(
            'nextUsers',
            'p1',
            user,
            data.p1.data
        )

        const p2CurrentAlert = filterAlertByUser(
            'currentUsers',
            'p2',
            user,
            data.p2.data
        )
        const p2NextAlert = filterAlertByUser(
            'nextUsers',
            'p2',
            user,
            data.p2.data
        )

        const currentAlert = unionP1AndP2(p1CurrentAlert, p2CurrentAlert)
        const nextAlert = unionP1AndP2(p1NextAlert, p2NextAlert)

        const totalCurrentAlert = currentAlert.reduce(
            (a, b) => ({
                p1: a.p1 + (b.p1 || 0),
                p2: a.p2 + (b.p2 || 0)
            }),
            { p1: 0, p2: 0 }
        )
        const totalNextAlert = nextAlert.reduce(
            (a, b) => ({
                p1: a.p1 + (b.p1 || 0),
                p2: a.p2 + (b.p2 || 0)
            }),
            { p1: 0, p2: 0 }
        )

        const p1NextUsers = filterNextUsers('p1', user, data.p1.data)
        const p2NextUsers = filterNextUsers('p2', user, data.p2.data)

        const nextUsers = unionP1AndP2(p1NextUsers, p2NextUsers)

        return {
            user,
            currentAlert,
            nextAlert,
            totalCurrentAlert,
            totalNextAlert,
            nextUsers
        }
    })
}

const sendMails = function(usersData, p1, p2, context, callback) {
    const link = global.isProd
        ? `https://${context.host}/business/${context.groupModel.id}`
        : `http://localhost:3000/business/${context.groupModel.id}`
    async.each(
        usersData,
        (userData, callback) => {
            const userLng = ['en', 'fr', 'es', 'it', 'nl'].includes(
                _.get(userData, 'user.language')
            )
                ? _.get(userData, 'user.language')
                : 'en'

            const userMail = decrypt(userData.user.mail)
            const mail = _.defaults(
                {
                    to: userMail,
                    subject: { template: context.tl('alertsFollowUp', userLng)},
                    context: {
                        user: {
                            ...userData.user,
                            mail: decrypt(userData.user.mail)
                        },
                        currentAlert: _.orderBy(
                            userData.currentAlert.map(alert => ({
                                name: translateName(alert.name, userLng),
                                p1: alert.p1,
                                p2: alert.p2
                            })),
                            'name'
                        ),
                        nextAlert: _.orderBy(
                            userData.nextAlert.map(alert => ({
                                name: translateName(alert.name, userLng),
                                p1: alert.p1,
                                p2: alert.p2
                            })),
                            'name'
                        ),
                        totalCurrentAlert: userData.totalCurrentAlert,
                        totalNextAlert: userData.totalNextAlert,
                        nextUsers: _.orderBy(userData.nextUsers, 'name'),
                        hasCurrentAlerts: !!userData.currentAlert.length,
                        hasNextAlerts: !!userData.nextAlert.length,
                        hasNextUsers: !!userData.nextUsers.length,
                        p1,
                        p2,
                        link
                    },
                    templateDir: path.join(
                        global.appRoot,
                        mails.path,
                        'templates/news'
                    )
                },
                defaultMail(userLng)
            )

            mailer.sendMail(mail, callback)
        },
        callback
    )
}

async function loadData(context) {
    console.log('--> Load Data News')

    const startDate = moment.utc(context.data.start, 'YYYY-MM-DD').toDate()
    const endDate = moment.utc(context.data.end, 'YYYY-MM-DD').toDate()

    const alertContext = newContext(context, {
        fieldPath: [
            'store.id',
            'store.organizations.id',
            'organizations.id',
            'alertConfiguration.id',
            'alertConfiguration.name',
            'workflow.profileConfigs.habFunction.habilitations.user.id',
            'workflow.profileConfigs.habFunction.habilitations.organizationsJoin.id'
        ],
        query: {
            'date.gte': { $lte: endDate },
            'date.lt': { $gt: startDate },
            workflow: { $ne: null }
        }
    })

    const alerts = await app.S.Alert.find(alertContext)

    const constructAlert = async alert => ({
        date: new Date(),
        baseDay: alert.baseDay,
        creationDate: alert.alertCreationTime,
        dateHic: alert['date'],
        alertConf: alert.alertConfiguration,
        currentUsers: await workflow.getCurrentUsers(alert, context),
        nextUsers: await workflow.getNextUsers(alert, context)
    })

    const data = await Promise.all(alerts.map(constructAlert))

    const users = _(data)
        .flatMap(alertConf => [
            ...alertConf.currentUsers,
            ...alertConf.nextUsers
        ])
        .uniqBy(user => user.mail)
        .value()

    return { users, data }
}

async function generateNews(context) {
    const period1 = {
        start: moment()
            .subtract(8, 'days')
            .format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
    }

    const period2 = {
        start: moment()
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
        end: moment()
            .subtract(9, 'days')
            .format('YYYY-MM-DD')
    }

    const p1Context = newContext(context, { data: period1 })
    const p2Context = newContext(context, { data: period2 })

    const [p1, p2] = await Promise.all([
        loadData(p1Context),
        loadData(p2Context)
    ])

    const usersData = structureData({ p1, p2 })

    return await new Promise((resolve, reject) => {
        sendMails(usersData, period1, period2, context, error => {
            if (error) reject(error)
            else resolve()
        })
    })
}

export default generateNews
