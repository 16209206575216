import React from 'react'
import DataTableBoardButton from '../../DataTable/DataTableBoardButton'
import {Filter, Plus, Minus} from 'react-feather'
import {Select} from '../../Form/index'

import './GraphBoard.css'

const GraphBoard = (props) => {
    const {
        filters,
        currentFilter,
        setSize,
        setCriteria,
        setFiltersOpened,
        filtersOpened,
        type
    } = props

    const changeFiltersVisibility = () => setFiltersOpened(!filtersOpened)
    return (
        <div
            className="GraphBoard"
        >
            <div className="GraphBoard-left">
                {filters && (
                    <div className="GraphBoard-select">
                        <Select
                            value={currentFilter}
                            options={filters}
                            onChange={(object) => setCriteria(object)}
                            multi={false}
                            clearable={false}
                            styles={{
                                control: styles => ({
                                    ...styles,
                                    width: '130px'
                                })}
                            }
                            t={t => t}
                        />
                    </div>
                )}
                {!["barChart", "dynamicBarChart"].includes(type) && (
                    <div>
                        <DataTableBoardButton
                            onClick={() => setSize('moins')}
                        >
                            <Minus size={16} />
                        </DataTableBoardButton>
                        <DataTableBoardButton
                            onClick={() => setSize('plus')}
                        >
                            <Plus size={16} />
                        </DataTableBoardButton>
                    </div>
                )}
            </div>
            <div className="GraphBoard-right">
                <DataTableBoardButton
                    onClick={changeFiltersVisibility}
                >
                    <Filter size={16} />
                </DataTableBoardButton>
            </div>
        </div>
    )
}

export default GraphBoard
