import { INITIALIZE_MODULE } from '../actions/index'
import _ from 'lodash'
export default (state = {}, action) => {
    switch (action.type) {
        case INITIALIZE_MODULE:
            return _.pick(action.module, [
                'id',
                'name',
                'tKey',
                'category',
                'defaultPanel',
                'alerts',
                'noLeftBoard',
                'protectedExport',
                'useSocketsOnGet',
                'useSocketsOnFind',
                'useSocketsOnSave',
                'parentHeader',
                'headerClassName',
                'headerRowClassName',
                'headerHeight',
                'model',
                'accessUrl',
                'removable',
                'hideScroll',
                'sortable',
                'removableGroup',
                'newable',
                'listable',
                'updatable',
                'chartParams',
                'impExp',
                'workflowActions',
                'defaultFormButtons',
                'deletionConfirmationMessage',
                'deletionConditions',
                'manualFilters'
            ])
        default:
            return state
    }
}
