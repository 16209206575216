import _ from 'lodash'
import createWorkflowManager from '../../../utils/workflow'
import {basicContext} from "../../../utils/contextUtils"
import {insertCursorInBatches} from "../../../../utils/mongoUtils"
import Errors from '../../../utils/Errors'

/**
 * Setup the workflow using the library
 */
export const workflowParameters = {
    steps: [
        {
            name: 'formX',
            multiOrder: true,
            previousSteps:[],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'buildOffer', getUserConfirmation: true },
                { name: 'question', nextStep: 'formX', ignoreOrder: true, getUserConfirmation: true },
                { name: 'noGo', nextStep: 'noGo', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'buildOffer',
            multiOrder: true,
            previousSteps:['formX'],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'submitOffer', getUserConfirmation: true },
                { name: 'question', nextStep: 'buildOffer', ignoreOrder: true, getUserConfirmation: true },
                { name: 'notSubmitted', nextStep: 'notSubmitted', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'submitOffer',
            multiOrder: true,
            previousSteps:['formX', 'buildOffer'],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'realisation', getUserConfirmation: true },
                { name: 'validateAmi', nextStep: 'acceptedAmi', ignoreOrder: true, getUserConfirmation: true },
                { name: 'question', nextStep: 'submitOffer', ignoreOrder: true, getUserConfirmation: true },
                { name: 'lost', nextStep: 'lost', ignoreOrder: true, getUserConfirmation: true },
                { name: 'abandoned', nextStep: 'abandoned', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'realisation',
            multiOrder: true,
            previousSteps:['formX', 'buildOffer', 'submitOffer'],
            actions: [
                { name: 'validate', tKey: 'validateStep', nextStep: 'finished', getUserConfirmation: true },
                { name: 'question', nextStep: 'realisation', ignoreOrder: true, getUserConfirmation: true }
            ],
            type: 'inProgress'
        },
        {
            name: 'finished',
            type: 'validated',
            previousSteps:['formX', 'buildOffer', 'submitOffer', 'realisation']
        },
        {
            name: 'acceptedAmi',
            type: 'validated',
            previousSteps:['formX', 'buildOffer', 'submitOffer']
        },
        { name: 'noGo', type: 'refused' },
        { name: 'notSubmitted', type: 'refused' },
        { name: 'lost', type: 'refused' },
        { name: 'abandoned', type: 'refused' }
    ],
    profiles: [
        {
            name: 'formX',
            stepProfiles: [
                { step: 'formX', active: true },
                { step: 'buildOffer', active: false },
                { step: 'submitOffer', active: false },
                { step: 'realisation', active: false },
                { step: 'finished', active: false },
                { step: 'acceptedAmi', active: false },
                { step: 'noGo', active: false },
                { step: 'notSubmitted', active: false },
                { step: 'lost', active: false },
                { step: 'abandoned', active: false }
            ]
        },
        {
            name: 'buildOffer',
            stepProfiles: [
                { step: 'buildOffer', active: true },
                { step: 'submitOffer', active: false },
                { step: 'realisation', active: false },
                { step: 'finished', active: false },
                { step: 'acceptedAmi', active: false },
                { step: 'noGo', active: false },
                { step: 'notSubmitted', active: false },
                { step: 'lost', active: false },
                { step: 'abandoned', active: false }
            ]
        },
        {
            name: 'submitOffer',
            stepProfiles: [
                { step: 'submitOffer', active: true },
                { step: 'realisation', active: false },
                { step: 'finished', active: false },
                { step: 'acceptedAmi', active: false },
                { step: 'noGo', active: false },
                { step: 'notSubmitted', active: false },
                { step: 'lost', active: false },
                { step: 'abandoned', active: false }
            ]
        },
        {
            name: 'realisation',
            stepProfiles: [
                { step: 'realisation', active: true },
                { step: 'finished', active: false },
                { step: 'acceptedAmi', active: false },
                { step: 'noGo', active: false },
                { step: 'notSubmitted', active: false },
                { step: 'lost', active: false },
                { step: 'abandoned', active: false }
            ]
        }
    ]
}
export const workflowManager = createWorkflowManager(workflowParameters)

/**
 * Gets the workflow configs corresponding to the county
 * @param countryId
 * @param context
 * @returns {*}
 */
export function getWorkflowConfigs(countryId, context) {
    const workflowConfigContext = {
        group: context.group,
        fieldPath: [
            'id',
            'teamMemberProfile.id',
            'teamMemberProfile.teamMembers.id',
            'teamMemberProfile.teamMembers.countrys.id',
            'teamMemberProfile.teamMembers.user.id',
            'teamMemberProfile.teamMembers.user.active'
        ],
        query: {
            country: new global.ObjectID(countryId)
        }
    }

    return global.app.I.WorkflowConfig.find(workflowConfigContext)
}

/**
 * Generates the default object for the workflow configs
 * @param workflowConfigs
 * @returns {*}
 */
export function getWorkflowObject(workflowConfigs) {
    if (!workflowConfigs || !workflowConfigs.length) return null

    const initialStatus = workflowManager.getInitialStatus()
    const maxOrders = _(workflowConfigs)
        .groupBy('profile')
        .mapValues(
            o => _(o).map('order').max()
        )
        .omitBy(o => o === 0)
        .value()

    return {
        ...initialStatus,
        maxOrders
    }
}

/*
* Status users are generated for only one combination of step and order.
*
* We project all teamMember users, for static workflows that match step and order.
*
* */
function projectStatusUsers({ staticWorkflows, step, order }) {
    return _(staticWorkflows)
        .filter({ step, order })
        .map(staticWorkflow => ({
                ...staticWorkflow,
                user: staticWorkflow.teamMember.user
        }))
        .value()
}

/**
* StaticWorkflow = {
    *   step: String,
    *   order: Number,
    *   active: Boolean,
    *   teamMember: TeamMem
    *   country: Country
    *   businessProject: BusinessProject
    * }
*
* StatusUser = {
    *   step: String,
    *   order: Number,
    *   active: Boolean,
    *   teamMember: TeamMember,
    *   user: User
    *   country: Country
    *   businessProject: BusinessProject
* }
*
* */
export function initializeWorkflowData(
    businessProject,
    workflowObject,
    workflowConfigs
) {

    const step1 = workflowConfigs.reduce((acc, config) => {
            return [
                ...config.teamMemberProfile.teamMembers
                    .filter(teamMember => {
                            return teamMember.user.active === true && teamMember.countrys.some(
                                country => country.id === config.country.toString()
                            )
                        }
                    )
                    .map(teamMember => ({ config, teamMember })),
                ...acc
            ]
        }, [])

    const staticWorkflows = _(step1).flatMap(({ config, teamMember }) => {
            const staticWFs = getStaticWorkFlowObjectsForProfile(
                config,
                workflowObject.maxOrders
            )
            return _.flatten(staticWFs).map(staticWorkflow => ({
                ...staticWorkflow,
                teamMember
            }))
        })
        .value()

    const statusUsers = projectStatusUsers({
        staticWorkflows,
        step: 'formX',
        order: 1
    })

    return [staticWorkflows, statusUsers]
}

/**
*
* Generates workflow objects for a given profile
*
* For the multi-order intervention step :
    *  - the manger profile is active on pending and review_ and passive on others
*  - the validator profile is active on it's order, and passive on the next orders
*  - the controller profile is passive on risk and noRisk
* */

export function getStaticWorkFlowObjectsForProfile(config, maxOrders) {
    let result = []
    const profile = workflowParameters.profiles.find(
        profile => profile.name === config.profile
    )
    if (profile) {
        result = _.flatMap(profile.stepProfiles, stepProfile => {
            const step = workflowParameters.steps.find(
                step => step.name === stepProfile.step
            )
            const orders = maxOrders[step.name]
                ? _.range(1, maxOrders[step.name] + 1)
                : []
            if (step.multiOrder) {
                if (!stepProfile.active) {
                    return orders.map(order => ({
                        step: stepProfile.step,
                        order,
                        active: false
                    }))
                } else {
                    return orders.reduce((results, order) => {
                        if (order === config.order) {
                            results.push({
                                step: stepProfile.step,
                                order,
                                active: true
                            })
                        } else if (order > config.order) {
                            results.push({
                                step: stepProfile.step,
                                order,
                                active: false
                            })
                        }
                        return results
                    }, [])
                }
            } else {
                return [{
                    step: stepProfile.step,
                    order: 0,
                    active: stepProfile.active
                }]
            }
        })
    }

    return result
}

/*
* Get a string describing the current status of the workflow
* */
export function getStatusAndOrder({ workflow }, context) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    const stepTitle = context.tc ? context.tc(workflow.step) : workflow.step;
    return _.compact([
        stepTitle,
        workflow.order > 0 ? workflow.order : undefined
    ]).join(' / ')
}

export function getStatus({ workflow }) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    return workflow.step
}

function getCurrentStepOrder({ step, order }) {
    switch (step) {
        case 'formX':
        case 'buildOffer':
        case 'submitOffer':
        case 'realisation':
            return order + 1
        case 'finished':
        case 'noGo':
        case 'notSubmitted':
        case 'lost':
        case 'abandoned':
            return 1
        default:
            return 0

    }
}

export function getStepStatus({ workflow }) {
    if (!workflow) {
        console.warn('object without workflow!')
        return 'no workflow'
    }

    // for the finished steps, we return a special string
    if (['finished', 'noGo', 'notSubmitted', 'lost', 'abandoned'].includes(workflow.step)) return '-'

    const totalSteps = workflow.maxOrders.inProgress + 1

    return `${getCurrentStepOrder(workflow)}/${totalSteps}`
}


/**
 * Get All users in the static workflow
 * @param businessProject
 * @param context
 * @returns {Promise<Array>}
 */
export async function getUsersInStaticWorkflow(businessProject, context) {
    const staticWorkflowContext = {
        ...basicContext(context),
        query: {
            businessProject: new global.ObjectID(businessProject.id)
        },
        fieldPath: ['teamMember.user.id']
    }

    const staticWorkflows = await global.app.I.StaticWorkflow.find(
        staticWorkflowContext
    )

    return staticWorkflows.map(staticWF => staticWF.teamMember.user)
}

/**
 * Get list of current users for the businessProject
 * @param businessProject
 * @param context
 * @returns {Promise<*>}
 */
export async function getCurrentUsers(businessProject, context) {
    const statusUserContext = {
        ...basicContext(context),
        query: {
            active: true,
            businessProject: new global.ObjectID(businessProject.id)
        },
        fieldPath: [
            'teamMember.teamMemberProfile.id',
            'user.id'
        ]
    }

    const statusUsers = await global.app.I.StatusUser.find(statusUserContext)

    const wfExceptionQuery = {
        ...basicContext(context),
        query: {
            country: new global.ObjectID(businessProject.country.id),
            minTurnover: { $lte: businessProject.estimatedTurnover},
            maxTurnover: { $gte: businessProject.estimatedTurnover},
            typeOfOffers: {$eq: new global.ObjectID(businessProject.typeOfOffer.id)},
            'workflowExceptionSteps.activeProjectStep': businessProject.workflow.step
        }
    }

    const wfExceptions = await global.app.I.WorkflowException.find(wfExceptionQuery)

    let teamMemberProfileExeptions = []
    if(wfExceptions.length) {
        teamMemberProfileExeptions = wfExceptions[0].workflowExceptionSteps
            .find(o => o.activeProjectStep.id === businessProject.workflow.step)
            .teamMemberProfiles.flatMap(o => o.id)
    }

    return statusUsers
        .filter(su => !teamMemberProfileExeptions.includes(su.teamMember.teamMemberProfile.id))
        .map(o => o.teamMember)
}

/**
 * Get list of next users for the businessProject
 * @param businessProject
 * @param context
 * @returns {Promise<ReactWrapper|ShallowWrapper|Array|*|any[]>}
 */
export async function getNextUsers(businessProject, context) {
    const staticWorkflowContext = {
        ...basicContext(context),
        query: {
            active: true,
            businessProject: new global.ObjectID(businessProject.id)
        },
        fieldPath: ['teamMember.user.id']
    }

    const staticWorkflows = await global.app.I.StaticWorkflow.find(
        staticWorkflowContext
    )


    const {step, order, maxOrders} = businessProject.workflow

    return staticWorkflows.filter(staticWorkflow => {
            switch (step) {
                case 'formX':
                    if(maxOrders['formX'] === order){
                        return staticWorkflow.step === 'buildOffer' &&
                            staticWorkflow.active === true &&
                            staticWorkflow.order === 1

                    } else {
                        return staticWorkflow.step === 'formX' &&
                            staticWorkflow.active === true &&
                            staticWorkflow.order > order
                    }
                case 'buildOffer':
                    if(maxOrders['buildOffer'] === order){
                        return staticWorkflow.step === 'submitOffer' &&
                            staticWorkflow.active === true &&
                            staticWorkflow.order === 1

                    } else {
                        return staticWorkflow.step === 'buildOffer' &&
                            staticWorkflow.active === true &&
                            staticWorkflow.order > order
                    }
                case 'submitOffer':
                    if(maxOrders['submitOffer'] === order){
                        return staticWorkflow.step === 'realisation' &&
                            staticWorkflow.active === true &&
                            staticWorkflow.order === 1

                    } else {
                        return staticWorkflow.step === 'submitOffer' &&
                            staticWorkflow.active === true &&
                            staticWorkflow.order > order
                    }
                case 'realisation':
                    if(maxOrders['realisation'] !== order){
                        return staticWorkflow.step === 'buildOffer' &&
                            staticWorkflow.active === true &&
                            staticWorkflow.order > order

                    }
                    return false
                case 'finished':
                case 'noGo':
                case 'notSubmitted':
                case 'lost':
                case 'abandoned':
                    return false
                default:
                    return false
            }
        })
        .map(o => o.teamMember.user)
}

/*
* Get a string with the names of all the active HabFunctions for the project
* */
export async function getBusinessProjectTeamMemberProfile(businessProject, context) {
    const statusUserContext = {
        ...basicContext(context),
        query: {
            active: true,
            businessProject: new global.ObjectID(businessProject.id)
        },
        fieldPath: [
            'teamMember.teamMemberProfile.id',
            //'teamMember.teamMemberProfile.name'
            'teamMember.teamMemberProfile.abreviation'
        ]
    }

    const statusUsers = await global.app.I.StatusUser.find(statusUserContext)


    const wfExceptionQuery = {
        ...basicContext(context),
        query: {
            country: new global.ObjectID(businessProject.country.id),
            minTurnover: { $lte: businessProject.estimatedTurnover},
            maxTurnover: { $gte: businessProject.estimatedTurnover},
            typeOfOffers: {$eq: new global.ObjectID(businessProject.typeOfOffer.id)},
            'workflowExceptionSteps.activeProjectStep': _.get(businessProject, 'workflow.step')
        }
    }

    const wfExceptions = await global.app.I.WorkflowException.find(wfExceptionQuery)

    let teamMemberProfileExeptions = []
    if(wfExceptions.length) {
        teamMemberProfileExeptions = wfExceptions[0].workflowExceptionSteps
            .find(o => o.activeProjectStep.id === businessProject.workflow.step)
            .teamMemberProfiles.flatMap(o => o.id)
    }

    return _(statusUsers)
        .filter(su => !teamMemberProfileExeptions.includes(su.teamMember.teamMemberProfile.id))
        .map('teamMember.teamMemberProfile.abreviation')
        .uniq()
        .join(', ')
}


/*
* Defines the bootstrap button style for the action
* */
function bsStyleForAction(action) {
    switch (action) {
        case 'save':
            return 'success'
        case 'question':
            return 'warning'
        case 'noGo':
        case 'notSubmitted':
        case 'lost':
        case 'abandoned':
            return 'danger'
        case 'validate':
        case 'validateAmi':
        default:
            return 'primary'
    }
}



function getActiveButtonsForStep(statusUser, businessProject) {
    const isAmiBP = _.get(businessProject, 'responseMode.id') === 'ami'
    const {step, order} = statusUser
    const stepObject =
        workflowParameters.steps.find(stepObject => stepObject.name === step) ||
        {}

    const filteredActions = order === 1
        ? stepObject.actions.filter(o => o.name !== 'question')
        : stepObject.actions

    const actions = stepObject.actions
        ? [
            { name: 'save' },
            ...filteredActions
        ]
        : []

    const reformObject = action => ({
        action: action.name,
        bsStyle: bsStyleForAction(action.name),
        getUserConfirmation: !!action.getUserConfirmation,
        tKey: action.tKey || action.name,
        type: 'action'
    })

    if(step === 'submitOffer') {
        return isAmiBP
            ? actions.filter(o => o.name !== 'validate').map(reformObject)
            : actions.filter(o => o.name !== 'validateAmi').map(reformObject)
    }else {
        return actions.map(reformObject)
    }
}

/*
* Get workflow buttons for business project.
*
*
* */
export async function getBusinessProjectButtons(businessProject, context){
    const statusUserContext = {
        ...basicContext(context),
        fieldPath: ['id', 'step', 'active', 'teamMember.teamMemberProfile.id'],
        query: {
            businessProject: new global.ObjectID(businessProject.id),
            user: new global.ObjectID(_.get(context, 'user.id'))

        }
    }

    const statusUsers = await global.app.I.StatusUser.find(statusUserContext)

    const statusUser = statusUsers.find(statusUser => statusUser.active === true)

    const wfExceptionQuery = {
        ...basicContext(context),
        query: {
            country: new global.ObjectID(businessProject.country.id),
            minTurnover: { $lte: businessProject.estimatedTurnover},
            maxTurnover: { $gte: businessProject.estimatedTurnover},
            typeOfOffers: {$eq: new global.ObjectID(businessProject.typeOfOffer.id)},
            workflowExceptionSteps: { "$elemMatch": {
                activeProjectStep: _.get(businessProject, 'workflow.step'),
                teamMemberProfiles: new global.ObjectID(_.get(statusUser, 'teamMember.teamMemberProfile.id'))
            }}
        }
    }

    const wfExceptions = await global.app.I.WorkflowException.find(wfExceptionQuery)

    return getBusinessProjectButtonsForStatusUser(
        wfExceptions.length ? {} : statusUser,
        businessProject,
        context.module && context.module.name && context.module.name === 'adminDelegation'
    )
}
/*
* Get workflow buttons for the status user.
*
* Passive status users show only return button.
* Active status users show one button for each step action.
*
* */
export function getBusinessProjectButtonsForStatusUser(statusUser = {}, businessProject = {}, adminDelegation) {

    return _([
            ...adminDelegation
            ? [{
                action: 'delegate',
                bsStyle: bsStyleForAction('validate'),
                getUserConfirmation: true,
                tKey: 'delegate',
                type: 'action'
            }]
            : [
                statusUser.active && getActiveButtonsForStep(statusUser, businessProject),
                statusUser.active && {
                    action: 'delegate',
                    bsStyle: bsStyleForAction('validate'),
                    getUserConfirmation: true,
                    tKey: 'delegate',
                    type: 'action'
                }]
        , {
            tKey: 'return',
            bsStyle: 'default',
            type: 'return'
        }
    ])
        .flatten()
        .compact()
        .value()
}

/*
* Update status users
*
* This function is called to update the status users to match the next status.
* It will check the workflow config for the alert to compute the new status users.
* */
export async function updateStatusUsers(businessProject, nextStatus, context) {
    const statusUserCollection = global.app.I.StatusUser.collection
    const businessProjectQuery = {businessProject: new global.ObjectID(businessProject.id) }

    const staticWorkflowContext = {
        ...basicContext(context),

        // we clone the businessProjectQuery to avoid object modification by the platform
        query: { ...businessProjectQuery },

        // ask the platform to join the teamMember collection for us
        fieldPath: ['id', 'teamMember.id']
    }

    const staticWorkflows = await global.app.I.StaticWorkflow.find(
        staticWorkflowContext
    )

    const statusUsers = projectStatusUsers({
        staticWorkflows,
        step: nextStatus.step,
        order: nextStatus.order
    }).map(({ id, teamMember, ...other }) => ({
        teamMember: new global.ObjectID(teamMember.id),
        ...other
    }))

    // remove the status users corresponding to the previous status
    await statusUserCollection.deleteMany({
        ...businessProjectQuery,
        group: new global.ObjectID(context.group.id)
    })

    if(statusUsers.length) {
        await statusUserCollection.insertMany(statusUsers)
    }
    else {
        throw new Errors.ValidationError('Workflow error, please contact administrator')
    }
}



/*
* Update status users after a change in teamMember
* */
export async function updateTeamMemberUsers({
                                                  teamMemberId,
                                                  users,
                                                  group
                                              }) {
    const businessProjectCollectionName = global.app.I.BusinessProject.collectionName
    const statusUserCollection = global.app.I.StatusUser.collection
    const staticWorkflowCollection = global.app.I.StaticWorkflow.collection

    await statusUserCollection.removeMany({
        teamMember: new global.ObjectID(teamMemberId)
    })

    if (users.length) {
        const projectionFields = {
            active: 1,
            step: 1,
            order: 1,
            businessProject: 1,
            teamMember: 1,
            country: 1,
            group: 1
        }

        const aggregationCursor = staticWorkflowCollection.aggregate([
            {
                $match: {
                    group: new global.ObjectID(group.id),
                    teamMember: new global.ObjectID(teamMemberId)
                }
            },
            {
                $lookup: {
                    from: businessProjectCollectionName,
                    localField: 'businessProject',
                    foreignField: '_id',
                    as: 'lookedBusinessProjects'
                }
            },
            {
                $project: {
                    lookedBusinessProject: { $arrayElemAt: ['$lookedBusinessProjects', 0] },
                    ...projectionFields
                }
            },
            {
                $project: {
                    orderEq: { $eq: ['$order', '$lookedBusinessProject.workflow.order'] },
                    stepEq: { $eq: ['$step', '$lookedBusinessProject.workflow.step'] },
                    ...projectionFields
                }
            },
            {
                $match: {
                    orderEq: true,
                    stepEq: true
                }
            },
            {
                $project: {
                    _id: 0,
                    ...projectionFields,
                    user: users.map(user => new global.ObjectID(user.id))
                }
            },
            {
                $unwind: '$user'
            }
        ])

        await insertCursorInBatches({
            cursor: aggregationCursor,
            collection: statusUserCollection,
            batchSize: 1000
        })
    }
}
