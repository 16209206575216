const crypto = require("crypto")
const _ = require("lodash")
const moment = require("moment")
const async = require("async")
const Errors = require('../utils/Errors').default
const {STRONG_PASS} = require("../../utils/regExps")
const {newContext} = require("./../utils/contextUtils")
const {changeFieldDisabled, setFieldVisibility} = require("../../apps/KpModule/actions")
const {DT_REQUEST, generateFetchFieldListAction} = require("../../apps/KpModule/actions/api")

let jobCount = 0

const queue = async.queue((task, callback) => {
    const {emails, modelId} = task;

    const formattedEmails = emails.map(email => {

        const defaultObject = {
            ..._.pick(email, ['from', "to", "replyTo", "context", "signature", "signatureFiles", "attachments", "messageId"]),
            subject: {
                template: email.subject
            },
            verbose: {
                general: true,
            }
        }

        return email.contentType === "template"
            ? _.defaults({content: email.content, templateDir: email.templateDir}, defaultObject)
            : _.defaults({content: {html: email.content}}, defaultObject)
    })

    global.mailer.sendMail(formattedEmails, e => {
        if(e) {
            console.log('error sending emails', e)
            return callback(e)
        }
        const sendingDate = new Date()
        global.app[modelId].EmailsQueue.collection.updateMany(
            {_id: {$in: emails.map(email => email._id)}},
            { $set: { status: 'sent', sendingDate} },
            callback
        )
    })
});

const adminDescription = function(modules) {
    return "A (" + modules.length + " module(s))";
};

const modelAdminFromUser = function(user, context) {
    const userModel = global.UserGroupModel.getByUser(context, user);
    const super_ = userModel.super_ || false;
    const superCom_ = userModel.superCom_ || false;
    return {
        id: user.id,
        user,
        description: adminDescription(_.flatMap(userModel.profiles, profile => profile.modules)),
        super_,
        superCom_,
        profiles: userModel.profiles,

    };
};

export const userRolesFieldPath = [
    "id", "name",
    "language.id",
    "groups.super_",
    "groups.groupModels.super_",
    "groups.groupModels.superCom_",
    "groups.groupModels.profiles.id",
    "groups.groupModels.profiles.name",
];

export const userRolesSetterFieldPath = [
    "id", "name",
    "groups.super_",
    "groups.group",
    "groups.groupModels.groupModel",
    "groups.groupModels.super_",
    "groups.groupModels.superCom_",
    "groups.groupModels.profiles.id",
];

const defaultTranslation = {
    t: path => path,
    tc: path => _.upperFirst(path)
}

const translation = (context={}) => ({
    t: context.t || defaultTranslation.t,
    tc: context.tc || defaultTranslation.tc,
})

const moduleFields = ["id", "name", "tKey", "title", "kp", "category", "step", "externalLink", "model_"];
const modelFields = ["id", "name", "languages", "modules", "full"];


//To be used in find that replaces get
const findOrGet = (entity, id) => id
    ? (context, callback) => entity.get(id, context, (e, object) => callback(e, object ? [object] : null))
    : entity.find;


const getAdminModules = model => model.modules.map(module => ({
    id: module.id,
    module
}));
const getKUserModel = (groupModel, userGroup) => ({
    userGroup,
    groupModel: groupModel,
    super_: true,
    modules: getAdminModules(groupModel.model),
    profiles: []
});

const getUserModules = (groupModel, profiles) => {
    const modules = _.flatMap(profiles, profile => profile.modules)
    //const modules = _.flatMap(profiles, profile => profile.modules.map(module => module.id))
    return _.get(groupModel, 'model.modules', []).filter(module => modules.includes(module.id)).map(module => ({
        id: module.id,
        module
    }));
}

const getUserDashboard = (groupModel, profiles) => {
    const dashboardElements = _.flatMap(profiles, profile => profile.dashboardElements)
    const dashboardElementEntity = global.app[groupModel.model.id].DashboardElement
    return dashboardElementEntity && dashboardElementEntity.objects.filter(
        element => dashboardElements.includes(element.id)
    )
}

const getKUserGroup = (group, user) => ({
    user,
    group,
    super_: true
});

const model = {
    technical: true,
    id: "T",
    name: "technical",
    jobs: [{
        name: 'sendQueuedEmails',
        title: 'Send Queued emails',
        cron: '0,30 * * * * *',
        single: false,
        execute: async function(context, callback) {
            const jobNum = jobCount++

            const groupModels = await global.GroupModel.find({
                fieldPath: ["id", "model.id", "group.id"],
                query: {}
            })

            async.series(
                groupModels.map(groupModel => callback => {
                    const modelId = _.get(groupModel, 'model.id')
                    const groupId = _.get(groupModel, 'group.id')

                    let emailsNb = 0
                    global.app[modelId].EmailsQueue.collection
                        .find({
                            $or: [
                                {status: 'waiting', delayedSending: false, group: new global.ObjectID(groupId)},
                                {
                                    status: 'waiting',
                                    delayedSending: true,
                                    date: {$lte: moment().format('YYYY-MM-DD HH:mm:ss')},
                                    group: new global.ObjectID(groupId)
                                }
                            ]
                        })
                        .sort({ creationDate: 1 })
                        .limit(100)
                        .toArray((e, batch) => {
                            emailsNb = batch.length

                            if(!emailsNb) return callback(null, emailsNb)

                            global.app[modelId].EmailsQueue.collection.updateMany(
                                {_id: {$in: batch.map(email => email._id)}},
                                { $set: { status: 'sending'} },
                                e => {
                                    if(!e) queue.push({emails: batch, modelId})
                                    return callback(e, emailsNb)
                                }
                            )
                        })

                }
            ), (e, results) => {

                if(e) {
                    console.log(e)
                    return callback(e)
                }
                const total = results?.reduce((acc, nb) => acc + nb, 0)
                callback(null, {message: `Email job ${jobNum}: ${total} emails sent !`})
            })
        }
    }],
    entities: [
        {
            name: "Language",
            type: "static",
            facets: ["translatedField"],
            fields: [
                {path: "id", type: "string"},
                {path: "name", type: "string"},
            ],
            objects: [
                {id: "en", name: 'English'},
                {id: "es", name: 'Español'},
                {id: "fr", name: 'Français'},
                {id: "it", name: "Italiano"},
                {id: "nl", name: "Nederlands"}
            ]
        },
        {
            name: "Browser",
            mongoInternal: true,
            fields: ["browser", "os", "device"]
        },
        {
            name: "User",
            facets: [{name: "files", path: "signature"}],
            fields: [
                {path: "mail", encrypted: true, unique: true, notEmpty: true},
                {path: "username", unique: true},
                {path: "firstname"},
                {path: "lastname"},
                {path: "password", nullable: true},
                {path: "protectedPassword", nullable: true},
                {path: "previousPassword", nullable: true},
                {path: "newPassword", nullable: true},
                {path: "passwordConfirmation", nullable: true},
                {path: "salt"},
                {path: "language", type: "Language"},
                {type: "Browser", link: "OTM"},
                // {path: "name", fieldPath: ["firstname", "lastname", "active"], f: function() {
                //     return this.firstname + " " + this.lastname;
                // }},
                {
                    path: "name",
                    $f: function (object, context, callback) {
                        callback(null, `${object.firstname} ${object.lastname} ${!object.active ? '('+translation(context).tc('inactive')+')' : ''}`);
                    }
                },
                {path: "phone", encrypted: true},
                {path: "mobilePhone"},
                {path: "k", type: "boolean", default: false},
                {path: "active", type: "boolean", default: true},
                {path: "unsubscribed", type: "boolean", default: false},
                {path: "groups", type: "UserGroup", link: "OTM", notEmpty: true},
                {
                    path: "fullName",
                    f: function () {
                        return `${this.firstname} ${this.lastname}`
                    }
                }
            ],
            filters: [
                {
                    name: "isActive",
                    isDefault: false,
                    query: (context) => {
                        return {active: true}
                    }
                },
                {
                    name: "isInGroup",
                    query: context => {
                        const group = context.group;
                        if(group){
                            return {
                                $or: [
                                    {k: true},
                                    {"groups.group": new global.ObjectID(group.id)}
                                ]
                            }
                        }
                    }
                },
                {
                    name: "isInGroupModel",
                    isDefault: false,
                    query: context => {
                        const groupModel = context.groupModel;
                        return groupModel
                            ? {"groups.groupModels.groupModel": new global.ObjectID(groupModel.id)}
                            : {_id: null}
                    }
                },
                {
                    name: "hasProfile",
                    isDefault: false,
                    query: context => {
                        const profiles = _.get(context, 'data.profiles')
                        if(profiles){
                            return {
                                "groups.groupModels.profiles": {
                                    $elemMatch: { $in: profiles.map(profile => new global.ObjectID(profile.id))  }
                                }
                            }
                        }
                    }
                },
                {
                    name: "usersOnGroups",
                    isDefault: false,
                    query: context => {
                        if(! context.user.k) {
                            const userGroups = context.user.groups
                                ? context.user.groups.map(o => global.ObjectID(o.group.id))
                                : []
                            return {
                                $or: [
                                    {"groups.group": {$in: userGroups}},
                                ]
                            }
                        }
                    }
                },
                {
                    name: "connectedUser",
                    isDefault: false,
                    query: context => {
                        const user = context.user;
                        if(user) return {"_id": new global.ObjectID(context.user.id)};
                    }
                },
                {
                    name: "userKNotAllowed",
                    isDefault: false,
                    match: user => {
                        return !user.k
                    }
                },
                {
                    name: "notUnsubscribed",
                    isDefault: false,
                    query: () => ({
                        $or: [
                            {unsubscribed: {$exists: false}},
                            {unsubscribed: false}
                        ]
                    })
                }
            ],
            getUserGroupFromGroup: function(user, group, throwError, userIsMe) {
                if(! user) {
                    if(throwError) throw new Errors.ValidationError("No user");
                    return;
                }
                if(user.k) return getKUserGroup(group, user);

                //const userGroup = _.find(user.groups, {group: {id: group.id}});
                const userGroup = _.find(user.groups, userGroup => userGroup.group?.toString() === group.id);
                if(throwError && ! userGroup) throw new Errors.ValidationError(`${userIsMe ? "You are" : user.name} not assigned to the group ${group.name}`);

                return  _.pick(userGroup, ["group", "super_", "groupModels"]);
            },
            getUserModelFromGroupModel: function(user, groupModel, throwError, userIsMe) {
                const userGroup = groupModel && global.User.getUserGroupFromGroup(user, groupModel.group, throwError, userIsMe);
                if(! userGroup) return;

                if(user.k) return getKUserModel(groupModel, userGroup);

                //const userModel = _.find(userGroup.groupModels, {groupModel: {id: groupModel.id}});
                const userModel = _.find(userGroup.groupModels, userGroupModel => userGroupModel.groupModel?.toString() === groupModel.id);
                if(throwError && ! userModel) throw new Errors.ValidationError(`${userIsMe ? "You" : user.name} do not have access to this model : ${groupModel.model.name}`);

                return userModel ? Object.assign(userModel, {
                    profiles: userModel.profiles,
                    modules: getUserModules(groupModel, userModel.profiles),
                    dashboard: getUserDashboard(groupModel, userModel.profiles)
                }) : userModel;
            },
            getUserProfilesFromGroupModel: function(user, groupModel) {
                const userGroup = global.User.getUserGroupFromGroup(user, groupModel.group)
                if(! userGroup) return []
                const userModel = _.find(userGroup.groupModels, {groupModel: {id: groupModel.id}})
                return userModel ? userModel.profiles : []
            },
            beforeSave: async (object, oldObject, context, callback) => {
                // hash the password here in object before the first save

                const module = _.get(context, 'clientContext.moduleId')
                const action  = context.restAction && context.restAction.crudType

                const groups = await global.Group.find({query: {}, fieldPath: ["id", "name", "groupModels.id"]})

                if("m-T-users" === module) {
                    object.groups = object.groups.map(userGroup => {
                        if(!userGroup.super_) {
                            return userGroup
                        }
                        else {
                            const group = groups.find(group => group.id === userGroup.group.id)

                            group.groupModels.forEach(groupModel => {
                                let userModel = userGroup.groupModels && userGroup.groupModels.find(userGroupModel => userGroupModel.groupModel.toString() === groupModel.id )
                                if(! userModel) {
                                    if(! userGroup.groupModels) userGroup.groupModels = [];
                                    userModel = {groupModel: groupModel, super_: true, profiles: []};
                                    userGroup.groupModels.push(userModel);
                                } else userModel.super_ = true
                            })
                            return userGroup
                        }
                    })
                }

                if (action === "U") {

                    if("m-T-my-account" === module && object.id !== context.user.id) {
                        return callback(new Errors.ValidationError("PermissionDenied"))
                    }

                    /*
                    if(_.has(object, 'mail') && (oldObject.mail !== object.mail)) {
                        return callback(new Errors.ValidationError("CannotChangeEmailAddress"))
                    }
                     */

                    if(!object.newPassword || !object.passwordConfirmation) return callback(null, object, oldObject)

                    const passwordTester = new RegExp(STRONG_PASS, "g")

                    if(!passwordTester.test(object.newPassword)) return (callback(new Errors.ValidationError('weakPassword')))

                    if(object.newPassword !== object.passwordConfirmation) return callback(new Errors.ValidationError('passwordConfirmationDoesNotMatchPassword'))

                    global.User.get(object.id, {log: false}, (e, user) => {
                        if (e) return callback(e)
                        crypto.pbkdf2(object.previousPassword, user.salt, 1000, 64, 'sha512', (err, derivedKey) => {
                            if (err) return callback(err)
                            const key = derivedKey.toString('hex')
                            if(user.password === key) {
                                return crypto.randomBytes(16, (err, buf) => {
                                    if (err) return callback(err)
                                    const salt = buf.toString('hex')

                                    crypto.pbkdf2(object.newPassword, salt, 1000, 64, 'sha512', (err, derivedKey) => {
                                        if (err) return callback(err);
                                        object.salt = salt
                                        object.password = derivedKey.toString('hex')

                                        if( global.config.password.expirationPeriod) {
                                            object.passwordExpires = Date.now() + global.config.password.expirationPeriod
                                        }

                                        object.protectedPassword = oldObject.protectedPassword
                                            ? oldObject.protectedPassword.length % 2
                                                ? oldObject.protectedPassword.slice(0, -1)
                                                : oldObject.protectedPassword + '*'
                                            : '**********'
                                        object.previousPassword = null
                                        object.newPassword = null
                                        object.passwordConfirmation = null

                                        return callback(null, object, oldObject)
                                    });

                                })
                            }
                            return callback(new Errors.ValidationError("wrongPreviousPassword"))
                        })

                    })
                }

                else if (action === "C" && object.password) {
                    crypto.randomBytes(16, (err, buf) => {
                        const salt = buf.toString('hex')

                        crypto.pbkdf2(object.password, salt, 1000, 64, 'sha512', (err, derivedKey) => {
                            if (err) throw callback(err);
                            console.log('expiration', global.config.password.expirationPeriod)
                            object.salt = salt
                            object.password = derivedKey.toString('hex')

                            if( global.config.password.expirationPeriod) {
                                object.passwordExpires = Date.now() + global.config.password.expirationPeriod
                            }

                            callback(null, object, oldObject)
                        });

                    })
                }
            }
        },
        {
            name: "GroupImages",
            type: "mongoInternal",
            fields: [
                {path: 'name', notEmpty: true},
                'User',
                {path: 'date', notEmpty: true}
            ]
        },
        {
            name: "KpLogo",
            type: "static",
            fields: [
                {path: 'id'},
                {path: 'name'}
            ],
            objects: [
                {id: 'black', name: 'black'},
                {id: 'white', name: 'white'},
                {id: 'colored', name: 'colored'}
            ]
        },
        {
            name: "Group",
            fields: [
                {path: 'logo', type: 'GroupImages', link: "OTO"},
                {path: 'images', type: 'GroupImages', link: 'OTM'},
                {path: "name", unique: true},
                {path: "useNoReply", type: 'boolean'},
                {path: "noReply", notEmpty: true},
                {path: "alias", notEmpty: true},
                {path: "groupModels", type: "GroupModel", link: "OTM"}
            ],
            filters: [
                {
                    name: "connectedUserInGroup",
                    isDefault: false,
                    match: function(group, context) {
                        return context.user && (context.user.k || context.user.groups.some(o => o.group.toString() === group.id))
                    }
                }
            ],
            beforeSave: (object, oldObject, context, callback) => {
                object.logo = _.sortBy(object.images, 'date' ).reverse()[0]

                callback(null, object, oldObject)
            }
        },
        {
            name: "GroupModel",
            fields: [
                "name",
                {path: "authorizedModules", type: "Module", link: "MTM"},
                {path: "defaultTheme", type: "boolean"},
                "backgroundColor",
                "menuButtonsColor",
                "selectedColor",
                "KpLogo",
                {type: "Model", link: "MTO"},
                {path: "fullName", fieldPath: ["model.name", "group.name"], f: function() {
                    return `${this.name} (${this.group.name})`;
                }},
                {path: "modelName", fieldPath: ["model.name"], f: function() {
                    return _.get(this, 'model.name');
                }},
                {path: "groupName", fieldPath: ["group.name"], f: function() {
                    return _.get(this, 'group.name');
                }},
                {path: "super_", type: "boolean", lazy: true, $f: function(groupModel, context, callback) {
                    const userModel = global.User.getUserModelFromGroupModel(context.user, groupModel);
                    const super_ = userModel && userModel.super_;
                    callback(null, super_);
                }}
            ]
        },
        {
            name: "UserGroup",
            type: "mongoInternal",
            fields: [
                "Group",
                {path: "super_", type: "boolean", default: true},
                {path: "groupModels", type: "UserGroupModel", link: "OTM"}
            ]
        },
        {
            name: "UserGroupModel",
            type: "mongoInternal",
            fields: [
                {path: "groupModel", type: "GroupModel"},
                {path: "super_", type: "boolean"},
                {path: "superCom_", type: "boolean"},
                {path: "profiles", type: "Profile", link: "MTM"},
                {path: "dashboardElements", type: "DashboardElement", link: "MTM"}
            ],
            getByUser: function(context, user) {
                if(! user) user = context.user;

                const userModel = global.User.getUserModelFromGroupModel(user, context.groupModel) || {
                    id: null,
                    super_: null,
                    superCom_: null,
                    //groupModel : context.groupModel,
                    profiles: [],
                    //modules: [],
                };
                return {
                    id: userModel.id,
                    super_: userModel.super_,
                    superCom_: userModel.superCom_,
                    //name: userModel.groupModel.model.name,
                    //groupModel: _.pick(userModel.groupModel.model, ["id", "name"]),
                    profiles: userModel.profiles || [],
                    //modules: getUserModules(userModel.groupModel, userModel.profiles)
                };
            }
        },
        {
            name: "DashboardElement",
            type: "sync",
            fields: [
                {path: "id", type: "string"},
                "name",
                "type",
                "url",
                "suffix",
                "link",
                "keys",
                "colors",
                "width",
                "sum",
                "sumColor",
                "absolute"
            ],
            db: {
                findSync: function() {
                    return global.app.models
                        .filter(model => {
                            return model.id !== 'T' && global.app[model.id].DashboardElement
                        })
                        .flatMap(model => {
                            return global.app[model.id].DashboardElement.objects.map(
                                element => {
                                    element.model = model.id
                                    return element
                                }
                            )

                    });
                }
            },
            filters: [
                {
                    name: "thisModel",
                    isDefault: false,
                    match: function(module, context) {
                        return module.model === context.model.id;
                    }
                }
            ]
        },
        {
            name: "Module",
            type: "sync",
            fields: [
                {path: "id"},//TODO: type: "string"
                "name",
                "tKey",
                "title",
                "category",
                "step",
                "externalLink",
                {path: "kp", type: "boolean", default: false},
                {path: "full", f: function() { return _.find(global.modules, {id: this.id}); }},
                {
                    path: "translatedCategoryName",
                    $f: function (object, context, callback) {
                        callback(null, `${translation(context).tc( object.category ? object.category.path || object.category : object.category)} - ${translation(context).tc(object.tKey)}`);
                    }
                }
            ],
            db: {
                findSync: function(context) {
                    return global.app.modules.map(module => {
                        const modelId = _.get(module, 'model_.id')
                        module = _.pick(module, moduleFields);
                        module.model_ = _.pick(module.model_, ["id"]);//TODO: replace with .full.model_ instead
                        const translate = context.tn
                            ? context.tn
                            : w => w
                        module.translatedFullName = `${translate(module.category?.path, modelId)} / ${translate(module.tKey, modelId)}`
                        return module;
                    });
                }
            },
            filters: [
                {
                    name: "authorizedInGroupModel",
                    isDefault: false,
                    match: (module, context) => {
                        return !!context.groupModel?.authorizedModules.map(module => module.id).includes(module.id)
                    }
                },
                {
                    name: "byModel",
                    isDefault: false,
                    match: function(module, context) {
                        const modelId = _.get(context, 'data.model.id')
                        if(!modelId) return false
                        return module.model_.id === modelId
                    }
                },
                {
                    name: "thisModel",
                    isDefault: false,
                    match: function(module, context) {
                        return module.id === 'm-T-my-account' || module.model_.id === context.model.id;
                    }
                },
                {
                    name: "excludeMyAccountModule",
                    isDefault: false,
                    match: function(module) {
                        return module.id !== 'm-T-my-account'
                    }
                },
                {
                    name: "notKp",
                    isDefault: false,
                    match: module => !module.kp
                }
            ]
        },
        {
            name: "Model",
            type: "sync",
            fields: [
                {path: "id", type: "string"},
                "name",
                {type: "Module", link: "OTM", lazy: false},
                {path: "full", f: function() { return _.find(global.models, {id: this.id}); }}//TODO: type
            ],
            db: {
                findSync: function() {
                    return global.app.models.map(model => {
                        model = _.pick(model, modelFields);
                        model.modules = model.modules.map(module => _.pick(module, moduleFields));
                        return model;
                    });
                }
            }
        },

        {
            name: "ModelAdmin",
            type: null,
            fields: [
                "User",
                {path: "super_", type: "boolean", nullable: true, default: false},
                {path: "superCom_", type: "boolean", nullable: true, default: false},
                {type: "Profile", link: "MTM", nullable: true},
                {type: "DashboardElement", link: "MTM", nullable: true},
            ],
            validateSave: (object, oldObject, context, callback) => {
                const userGroup = object.user.groups.find(userGroup => userGroup.group.toString() === context.group.id)
                if(oldObject.super_ && !object.super_ && userGroup.super_) {
                    return callback(new Errors.ValidationError("userIsGroupSuperUser"))
                }
                callback()
            },
            db: {
                find: function(context, callback) {
                    findOrGet(global.User, context.id)(
                        newContext(
                            context,
                            {
                                fieldPath: userRolesFieldPath,
                                filters: ["userKNotAllowed"]
                            }
                        ),
                        (e, users) => {
                            if(e) return callback(e);

                            const modelAdmins = users.map(user => modelAdminFromUser(user, context));
                            callback(null, modelAdmins);
                    });
                },
                save: function(newModelAdmin, oldModelAdmin, context, callback) {
                    const user = oldModelAdmin.user;

                    if(user.k) return callback(new Errors.ValidationError("Roles non supportés pour les keenpointers"));

                    const userGroup = global.User.getUserGroupFromGroup(user, context.group, true);
                    let userModel = global.User.getUserModelFromGroupModel(user, context.groupModel);

                    if(! userModel) {
                        if(! userGroup.groupModels) userGroup.groupModels = [];
                        userModel = {groupModel: context.groupModel, modules: [], profiles: []};
                        userGroup.groupModels.push(userModel);
                    }

                    userModel.super_ = newModelAdmin.super_;
                    userModel.superCom_ = newModelAdmin.superCom_;
                    userModel.profiles = newModelAdmin.profiles;

                    //newModelAdmin.description = adminDescription(_.flatMap(newModelAdmin.profiles, profile => profile.modules));

                    global.User.save(user, newContext(context, {fieldPath: userRolesSetterFieldPath}), e => {
                        if(e) return callback(e);

                        callback(e, newModelAdmin);
                    });
                }
            }
        },
        {
            name: "Profile",
            fields: [
                {path: "code", unique: true},
                {path: "name", uniqueWith: "groupModel"},
                {path: "groupModel", type: "GroupModel"},
                {type: "Module", link: "MTM", nullable: true},
                {type: "DashboardElement", link: "MTM", nullable: true},
                {
                    path: "numberOfModules",
                    $f: function (object, context, callback) {
                        callback(null, object.modules ? object.modules.length : 0);
                    }
                },
                {
                    path: "numberOfDashboardElements",
                    $f: function (object, context, callback) {
                        callback(null, object.dashboardElements ? object.dashboardElements.length : 0);
                    }
                },
            ],
            filters: [
                {
                    name: "thisModel",
                    isDefault: false,
                    query: context => {
                        const groupModel = context.groupModel;
                        if(groupModel) return {groupModel: new global.ObjectID(context.groupModel.id)};
                    }
                }
            ],
            beforeSave: (newObject, oldObject, context, callback) => {
                newObject.groupModel = context.groupModel
                callback(null, newObject, oldObject)
            }
        },
        {
            name: "Log",
            fields: [
                "date",
                "type",
                "main",
                "isParent",
                "objectId",

            ]
        }
    ].map(
        entitity => ({...entitity, technical: true})
    ),
    modules: [
        {
            name: "users",
            category: {
                path: "technical",
                icon: "users"
            },
            object: "User",
            viewMap: {
                dt: [
                    "mail",
                    {path: "name", tKey: "firstNameAndLastName"}
                ],
                form: {
                    fields: [
                        {path: "mail", placeholder: "...@..."},
                        {path: "username", noSpaces: true},
                        {path: "firstname", required: true},
                        {path: "lastname", required: true},
                        {path: "language", required: true},
                        {path: "phone", tKey: "mobile", type: 'phoneNumber'},
                        //"mobilePhone",
                        {path: "password", type: "password", required: true, editable: false, creationOnly: true, strongPassword: true},
                        {
                            path: "groups",
                            tKey: "group(s)",
                            viewMap: {
                                dt: [
                                    "group",
                                    {path: "super_", tKey: "groupAdmin", width: 200}
                                ],
                                form: [
                                    {path: "group", filters: ["connectedUserInGroup"]},
                                    {path: "super_", tKey: "groupAdmin"}
                                ]
                            }
                        },
                        "active",

                        // Kludge
                        // {path: "groups.groupModels.super_", hidden: true},
                        // {path: "groups.groupModels.super_", hidden: true},
                        // {path: "groups.groupModels.groupModel.id", hidden: true},
                        // {path: "groups.groupModels.modules.id", hidden: true},
                        // {path: "groups.groupModels.modules.module.id", hidden: true}
                    ],
                    onOpen: ({module, store}) => {
                        const active = module.viewMap.form.fields.find(
                            field => field.path === 'active'
                        )
                        const userStatus = active.getValue()
                        store.dispatch(changeFieldDisabled('e_active', !userStatus))
                    }
                }
            },
            filters: ["usersOnGroups", "userKNotAllowed"]
        },
        {
            //id: 21,
            name: "groupmodel-team",
            category: {
                path: "technical",
                icon: "user-check"
            },
            title: "Administrateurs du modèle",
            object: "ModelAdmin",
            viewMap: {
                dt: [
                    {path: "user", width: 200},
                    //"description",
                    {path: "super_", tKey: 'profilesAdmin', width: 170},
                    {path: "superCom_", tKey: 'comAdmin', width: 170},
                    {path: "profiles", tKey: 'profile(s)', width: 400, translateName: true},
                ],
                form: [
                    {path: "user", writable: false},
                    {path: "super_", tKey: 'profilesAdmin'},
                    {path: "superCom_", tKey: 'comAdmin'},
                    {
                        path: "profiles",
                        tKey: 'profile(s)',
                        translateName: true,
                        type: "tags",
                        display: "name",
                        filters: ["thisModel"]
                    },
                    /*
                    {
                        path: "modules",
                        type: "tags",
                        display: "translatedCategoryName",
                        filters: ["thisModel", "notKp"]
                    }
                     */
                ]
            },
            newable: false,
            removable: false
        },
        {
            name: "profiles",
            object: "Profile",
            category: {
                path: "technical",
            },
            viewMap: {
                dt: [
                    {path: "name", type: "translatedText"},
                    {path: "numberOfModules", tKey: "module(s)", placeholder: "fr::"},
                    {path: "numberOfDashboardElements", tKey: "dashboardElement(s)"},
                ],
                form: [
                    {path: "name", type: "textarea", placeholder: "fr:: nom en français\nen:: nom en Anglais\nnl:: nom en Anglais"},
                    {
                        path: "modules",
                        tKey: "module(s)",
                        type: "tags",
                        display: "translatedCategoryName",
                        filters: ["thisModel", "excludeMyAccountModule", "notKp", "authorizedInGroupModel"]
                    },
                    {
                        path: "dashboardElements",
                        tKey: "dashboardElement(s)",
                        type: "tags",
                        display: "name",
                        translateName: true,
                        filters: ["thisModel"]
                    }
                ]
            },
            filters: ["thisModel"]
        },
        {

            name: "my-account",
            category: {
                path: "technical",
                icon: "user"
            },
            objectIdentifier: 'mail',
            object: "User",
            defaultPanel: 'form',
            newable: false,
            removable: false,
            listable: false,
            viewMap: {
                dt:["firstname"],
                form: {
                    fields: [
                        {path: "mail", writable: false, placeholder: "...@..."},
                        {path: "username", noSpaces: true},
                        "firstname",
                        "lastname",
                        {path: "language"},
                        {path: "phone", tKey: "mobile", type: 'phoneNumber'},
                        {path: "signature"},
                        //"mobilePhone",
                        //"password",
                        {
                            path: "newPassword",
                            type: "password",
                            strongPassword: true,
                            subscriptions: {
                                onChange: (newValue, oldValue, {store}) => {
                                    const fields = ["e_passwordConfirmation", "e_previousPassword"]
                                    fields.forEach(field => {
                                        store.dispatch(setFieldVisibility(field, !!newValue))
                                    })
                                }
                            }
                        },
                        "passwordConfirmation",
                        "previousPassword",
                        {path: "protectedPassword", hidden: true, default: '**********', addToLog: true}
                        //{path: "password", type: "gPassword"}
                    ],
                showTitle: false,
                //launchId: function() { return viewModel.user.id(); },
                //buttons: [{type: "save"}, {type: "return", action: function() { changePage("/", true); }}]
            }},
            filters: ["connectedUser"],
            /*
            actionSubscriptions: [
                {
                    actionType: SAVE_OBJECT_SUCCESS,
                    subscription: () => {
                        const history = require('../../history').default
                        console.log(history)
                        history.push('/')
                    }
                }
            ],
             */
        },
        {
            name: "group",
            category: "Administration",
            object: "Group",
            viewMap: {
                dt: [
                    "name"
                ],
                form: [
                    "name",
                    {path: "noReply", placeholder: "...@..."},
                    {path: "useNoReply"},
                    {path: "alias"},
                    {
                        path: "images", type: 'logoPicker', placeholder: 'addLogo', maxLength: 1, fieldPath: ['id', 'name', 'user.id', 'user.name', 'date'],
                        subscriptions: {
                            onChange: (newValue, oldValue, { store }) => {
                                if (newValue && !!newValue.length) {
                                    const lastImage = _.sortBy(newValue, 'date' ).reverse()[0]
                                    store.setTheme({
                                        logo: lastImage
                                    })
                                }
                            }
                        }
                    },
                    {path: "groupModels", tKey: 'model(s)', viewMap: {
                        dt: [
                            "name",
                            "model"
                        ],
                        form: [
                            {path: "name", require: true},
                            {
                                path: "model",
                                subscriptions: {
                                    onChange: (newValue, oldValue, { store }) => {

                                        store.dispatch(
                                            generateFetchFieldListAction(
                                                "m-T-group.GroupModel_authorizedModules",
                                                store.getState,
                                                'form',
                                                {data: {model: newValue}}
                                            )
                                        )
                                    }
                                }
                            },
                            {path: "authorizedModules", display: 'translatedFullName', filters: ["byModel"]},
                            {
                                path: "defaultTheme",
                                type: "checkbox",
                                default: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store }) => {
                                        if (newValue) {
                                            const groupModelsField = module.viewMap.form.fields.find(
                                                field => field.path === 'groupModels'
                                            )
                                            const backgroundColorField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'backgroundColor'
                                            )

                                            const menuButtonsColorField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'menuButtonsColor'
                                            )

                                            const selectedColorField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'selectedColor'
                                            )
                                            const kpLogoField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'kpLogo'
                                            )

                                            backgroundColorField.setValue('#f6f9fc')
                                            menuButtonsColorField.setValue('#62626e')
                                            selectedColorField.setValue('#e94d24')
                                            kpLogoField.setValue({id: 'colored'})

                                            store.setTheme({
                                                backgroundColor: '#f6f9fc',
                                                menuButtonsColor: '#62626e',
                                                selectedColor: '#e94d24',
                                                kpLogo: 'colored'
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                path: "backgroundColor",
                                type: "colorPicker",
                                default: '#f6f9fc',
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store, formInitialize, getObjectSuccessAction }) => {
                                        if(!formInitialize && !getObjectSuccessAction && newValue !== '#f6f9fc') {
                                            const groupModelsField = module.viewMap.form.fields.find(
                                                field => field.path === 'groupModels'
                                            )
                                            const defaultThemeField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'defaultTheme'
                                            )

                                            defaultThemeField.setValue(false)
                                        }
                                        store.setTheme({backgroundColor: newValue})
                                    }
                                }
                            },
                            {
                                path: "menuButtonsColor",
                                type: "colorPicker",
                                default: '#62626e',
                                subscriptions: {
                                    onChange: (newValue, oldValue, { store, module, formInitialize, getObjectSuccessAction }) => {
                                        if(!formInitialize && !getObjectSuccessAction && newValue !== '#62626e') {
                                            const groupModelsField = module.viewMap.form.fields.find(
                                                field => field.path === 'groupModels'
                                            )
                                            const defaultThemeField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'defaultTheme'
                                            )

                                            defaultThemeField.setValue(false)
                                        }
                                        store.setTheme({menuButtonsColor: newValue})
                                    }
                                }
                            },
                            {
                                path: "selectedColor",
                                type: "colorPicker",
                                default: '#e94d24',
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store, formInitialize, getObjectSuccessAction }) => {
                                        if(!formInitialize && !getObjectSuccessAction && newValue !== '#e94d24') {
                                            const groupModelsField = module.viewMap.form.fields.find(
                                                field => field.path === 'groupModels'
                                            )
                                            const defaultThemeField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'defaultTheme'
                                            )

                                            defaultThemeField.setValue(false)
                                        }
                                        store.setTheme({selectedColor: newValue})
                                    }
                                }
                            },
                            {
                                path: "kpLogo",
                                type: "toggle",
                                subscriptions: {
                                    onChange: (newValue, oldValue, { store, module, formInitialize, getObjectSuccessAction }) => {
                                        if(!formInitialize && !getObjectSuccessAction && newValue?.id !== 'colored') {
                                            const groupModelsField = module.viewMap.form.fields.find(
                                                field => field.path === 'groupModels'
                                            )
                                            const defaultThemeField = groupModelsField.viewMap.form.fields.find(
                                                field => field.path === 'defaultTheme'
                                            )

                                            defaultThemeField.setValue(false)
                                        }
                                        if(newValue) {
                                            store.setTheme({kpLogo: newValue.id})
                                        }
                                    }
                                }
                            }
                        ]
                    }}
                ]
            },
            actionSubscriptions: [
                {
                    actionType: DT_REQUEST,
                    subscription: ({ store }) => {
                        store.setTheme({
                            backgroundColor: '#f6f9fc',
                            menuButtonsColor: '#62626e',
                            selectedColor: '#e94d24',
                            kpLogo: 'colored',
                            logo: {name: null}
                        })
                    }
                }
            ],
            stateSubscriptions: [
                {
                    statePath: 'ui.mode',
                    subscription: (newValue, previousValue, { store }) => {
                        if(newValue === 'list') {
                            store.setTheme({
                                backgroundColor: '#f6f9fc',
                                menuButtonsColor: '#62626e',
                                selectedColor: '#e94d24',
                                kpLogo: 'colored',
                                logo: {name: null}
                            })
                        }
                    }
                }
            ],
            removable: false
        },
        {
            name: "log",
            category: "Administration",
            object: "Log",
            viewMap: {
                dt: [
                    "date",
                    "type",
                    "main",
                    "isParent",
                    "objectId"
                ]
            },
            newable: false
        }
    ].map(
        module => ({...module, technical: true})
    )
};

model.entities.forEach(entity => {
    global[entity.name] = entity;
});

export default model;
