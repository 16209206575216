import {basicContext} from "../../../utils/contextUtils";
import moment from "moment";
import {addFloats} from "../utils/usefulFunctions";
import _ from "lodash";

export const entity = {
    name: 'ImputationType',
    facets: [
        {name: 'codeName', nameOptional: false, uniqueName: true, uniqueCode: true} //name unicity is used in budget to build tables
    ],
    fields: [
        'Status'
    ],
    beforeSave: async function processCsv(newObject, oldObject, context, callback) {
        const action = context.restAction && context.restAction.crudType
        try {
            if (action === 'C'){
                const bulkWritesForRevisedTable = [{ deleteOne: {filter: { _id: null }} }]
                const bulkWritesForDemand = [{ deleteOne: {filter: { _id: null }} }]

                const demandsToUpdate = await global.app.SE.Demand.find({
                    ...basicContext(context),
                    fieldPath: ["revisedTable"],
                    query: {
                        "revisedTable": { $exists: true, $ne: [] },
                        //"workflow.0": "realization"
                    }
                })
                const availableRealizationTypes = await global.app.SE.RealizationType.find({
                    ...basicContext(context),
                    fieldPath: [],
                    query: {}
                })

                demandsToUpdate.forEach(demand => {
                    const revisedTableNewIds = []
                    const revisedTable = demand.revisedTable
                    const uniqueByObjetAndBU = _.uniqWith(revisedTable, (a, b) => {
                        return a.objet === b.objet && a.entity.id.toString() === b.entity.id.toString();
                    })
                    uniqueByObjetAndBU.forEach( revisedLine => {
                        const newID = new global.ObjectID()
                        revisedTableNewIds.push(newID)
                        let list = []
                        availableRealizationTypes.forEach(type =>{
                            list.push({
                                realizationType: {id: type.id },
                                budgetColumnType: {id: '1'},  //global
                                amount: 0
                            })
                            list.push({
                                realizationType: {id: type.id },
                                budgetColumnType: {id: '2'}, //yearly
                                amount: 0
                            })
                        })
                        bulkWritesForRevisedTable.push({
                            insertOne: {
                                document: {
                                    _id: newID,
                                    objet: revisedLine.objet,
                                    entity: global.ObjectID(revisedLine.entity.id),
                                    imputationType: global.ObjectID(newObject.id),
                                    estimated: 0,
                                    validated: 0,
                                    budget: 0,
                                    revised: 0,
                                    validatedEngagement: 0,
                                    ongoingFiscalYearValidatedEngagement: 0,
                                    ongoingFiscalYearEstimated: 0,
                                    ongoingFiscalYearValidated: 0,
                                    ongoingFiscalYearBudget: 0,
                                    ongoingFiscalYearRevised: 0,
                                    amountByRealizationType: list,
                                    updated: null,
                                    user: null,
                                    //demand: global.ObjectID(newObject.relatedProject.id),
                                    group: new global.ObjectID(_.get(context, 'group.id'))
                                }
                            }
                        })
                    })
                    bulkWritesForDemand.push({
                        updateOne: {
                            filter: { _id: global.ObjectID(demand.id) },
                            update: {
                                $push: {
                                    revisedTable: { $each: revisedTableNewIds }
                                }
                            }
                        }
                    })
                })
                await global.app.SE.RevisedTable.collection.bulkWrite(bulkWritesForRevisedTable, {}, (e)=>{
                    if (e) return callback(e)})

                await global.app.SE.Demand.collection.bulkWrite(bulkWritesForDemand, {}, (e)=>{
                    if (e) return callback(e)})
                callback(null, newObject, oldObject);
            }
            else{
                callback(null, newObject, oldObject);
            }
        } catch (error) {
            callback(error)
        }
    }
}

export const module_ = {
    object: 'ImputationType',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {path: 'name', type: 'translatedText'},
            'status'
        ],
        form: [
            {path: 'code', editable: true},
            {path: 'name', type: 'textarea', unique: true},
            {path: 'status', type: 'toggle', default: {id: '1'}}
        ],
    }
}