const { generateFetchFieldListAction } = require('../../../../../apps/KpModule/actions/api')

export const contractualisation = {
    name: "bp4",
    object: "BusinessProject",
    category: {
        path: "projects",
        icon: 'briefcase'
    },
    step: "submitOffer",
    newable: false,
    removable: false,
    workflowActions: [
        'validate',
        'question',
        'lost',
        'abandoned'
    ],
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "customer", width: 200, initiallyNotVisible: true},
            {path: "typeOfOffer", width: 300, initiallyNotVisible: true},
            {path: "fullName", tKey: 'name', width: 500},
            {path: "description", type: 'richText', width: 300, initiallyNotVisible: true},
            {path: "responseMode", initiallyNotVisible: true},
            {path: "beginYear", initiallyNotVisible: true},
            {path: "expectedTurnOver", tooltip: true, width: 160},
            {path: "expectedContractCost", tooltip: true, width: 80},
            {path: "expectedContractMarginInPercent", width: 80},
            {path: "expectedNumberOfDays", tooltip: true, width: 80, initiallyNotVisible: true},
            {path: "effectiveOfferSubmissionDate", width: 150},
            {path: "estimatedResponseDate", width: 150, initiallyNotVisible: true},
            {path: "offerAcceptedDate", width: 150, initiallyNotVisible: true},
            {path: "signatureDate", width: 150, initiallyNotVisible: true},
            {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", initiallyNotVisible: true},
            {path: "currentFunctions", width: 100},
            {path: "file", tKey: "FA"}

        ],
        form: {
            fields: [
                "identifier",
                {path: "country", writable: false, hidden: true},
                /*
                {
                    path: "country", tKey: "country", display: "fullInformations", writable: false,
                    filters: ["inUserTeam"],
                    subscriptions: {
                        onChange: (newValue, oldValue, {formInitialize, module, store}) => {
                            if (newValue && newValue.id) {
                                const dataCountry = {country: {id: newValue.id}}
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-I-bp4.BusinessProject_customer",
                                        store.getState,
                                        {data: dataCountry}
                                    )
                                )
                            } else {
                                store.dispatch(setFieldListOptions("e_customer", []))
                            }
                        }
                    }
                },

                 */
                {path: "name", writable: false, required: false},
                {path: "description", type: "richTextEditor", editable: false},
                {path: "developerPitches", tKey: "developerPitch", type: "richTextEditor", writable: false},
                {path: "beginYear", editable: false},
                {path: "customer", display: "codeNameRelation", required: true},
                {path: "estimatedTurnover", editable: false},
                "globalTurnover",
                {path: "resourceTypes", required: true},
                {path: "responseMode", writable: false, required: false},

                {path: "effectiveOfferSubmissionDate", required: true},
                {path: "estimatedResponseDate", required: true},
                "offerAcceptedDate",
                {path: "signatureDate"},
                {path: "beginningOfRealisationDate"},
                {path: "projectFunding", required: true},
                {path: "compensationModes", required: true},

                {path: "contractualSchema", required: true},
                {path: "needLocalStructure"},

                {path: "partners", filters: ["partners"], required: false},
                {path: "subcontractors", filters: ["subcontractors"], required: false},
                {path: "competitors", filters: ["competitors"], required: false},

                {path: "commercialTeamMember", display: "fullName", filters: ["commercialReferent"], required: true},
                {path: "technicalTeamMember", display: "fullName", filters: ["technicalReferent"], writable: false},
                {path: "monitoringTeamMembers", tKey: "projectMonitors", display: "fullName", filters: ["projectMonitor"]},
                {path: "projectManager", writable: false},
                {path: "projectTeam", type: "textarea", writable: false},

                "comments",
                "files",

                {path:"delegatedUser", filters:["byCountry"], display: "userName"},

                {path: "buttons", hidden: true},
                {path: "workflow", hidden: true},

                {path: "businessProject", hidden: true}
            ],
            onOpen: ({state, store}) => {
                const projectCountry = {country: state.edit.object.data.country}

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-I-bp4.BusinessProject_delegatedUser",
                        store.getState,
                        'form',
                        {data: projectCountry}
                    )
                )
            }
        }
    },
    filters: [
        "byTypeOfOffer",
        {query: () => ({"workflow.step": "submitOffer"})}
    ]
}
