import { getEdfinWorkflowNotificationData } from '../../../utils/notificationData'
const _ = require('lodash')
const moment = require('moment')
const async = require("async")
const Errors = require("../../../utils/Errors").default
const { businessProjectFilters } = require('./filters')
const { businessProjectGetters } = require('./getters')
const {
    enhanceProjectComponents,
    initializeReferentialBusiness,
    businessProjectFilesGeneration,
    financialStatementCalculation,
    fieldPathBusinessProject,
    createBPForAMI,
    applyWorkflowDelegation,
    addNewActiveUserToCurrentWorkflow,
    createBRLine,
    createFirstBR,
    updateBeginYear,
    updateBeginYearToMatchBr
} = require('./utils')

const years = ['N', 'N+1', 'N+2', 'N+3', 'N+4', 'N+5', 'N+6', 'N+7', 'N+8', 'N+9']

const { basicContext } = require("../../../utils/contextUtils");

const {
    initializeWorkflowBP,
    updateStepWorkflowBP,
    bpHasAnExceptionForTeamMemberProfile
} = require('../workflow/workflow-init')

const { workflowMailing } = require('../mails/workflowMails')
const { crrMail } = require('../mails/crrMail')
const { notifyMonitors } = require('../mails/monitorsNotification')

const {
    getStatus,
    getStatusAndOrder,
    getBusinessProjectTeamMemberProfile,
    getBusinessProjectButtons
} = require("../workflow/workflow");

async function findPointDeSortieData(context) {

    const zoneId = _.get(context, 'data.zone.id')

    const businessProjectCollectionName = global.app.I.BusinessProject.collectionName
    const businessRevueCollection = global.app.I.BusinessRevue.collection
    const year = moment().year()

    const match = zoneId
        ? { $match: { 'country.zone': new global.ObjectID(zoneId) } }
        : { $match: { date: {$ne: null} } }
    const businessRevueQuery = businessRevueCollection
        .aggregate([
            {
                $match: {
                    date: {$ne: null},
                    'projectStep' : {$in: ['formX', 'buildOffer', 'submitOffer', 'realisation', 'finished']}
                }
            },
            {
                $lookup: {
                    from: businessProjectCollectionName,
                    localField: 'businessProject',
                    foreignField: '_id',
                    as: 'bp'
                }
            },
            {
                $lookup: {
                    from: 'i.country',
                    localField: 'bp.country',
                    foreignField: '_id',
                    as: 'country'
                }
            },
            match,
            {
                $project: {
                    dataYear: {$year: '$date'},
                    dataMonth: {$month: '$date'},
                    dataDay: {$dayOfMonth: '$date'},
                    executedTurnOver: 1,
                    yearN: 1,
                    probability: 1,
                    bp: { "$arrayElemAt": [ "$bp.code", 0 ] },
                    step: '$projectStep'
                }
            },
            {
                $match: {
                    dataYear: year
                }
            },
            {
                $group: {
                    _id: {bp: '$bp', mois: '$dataMonth'},
                    brs: { $push: "$$ROOT" }
                }
            },
            /*
            {
                $group: {
                    _id: {step: '$_id.step', mois: '$_id.mois'},
                    brsByStep: {$push: '$$ROOT'}
                }
            },

             */
            {
                $group: {
                    _id: '$_id.mois',
                    brsByMonth: {$push: '$$ROOT'}
                }
            }
        ]).toArray()

    const [
        businessRevues
    ] = await Promise.all([
        businessRevueQuery
    ])


    const steps = {
        'formX': 'Détecté',
        'buildOffer': 'En Montage',
        'submitOffer': 'En contractualisation',
    }

    const sortedBusinessRevues = _.sortBy(businessRevues, '_id')


    return sortedBusinessRevues.map(
        brs => brs.brsByMonth.reduce(
            (acc, value) => {
                const sortedBrs = _.sortBy(value.brs, 'dataDay').reverse();
                return !['realisation', 'finished'].includes(sortedBrs[0].step)
                    ? Object.assign(acc, {
                        [steps[sortedBrs[0].step]]: acc[steps[sortedBrs[0].step]] + (sortedBrs[0].yearN * sortedBrs[0].probability) / 100
                    })
                    : Object.assign(acc, {
                        'Réalisé': acc['Réalisé'] +  sortedBrs[0].executedTurnOver,
                        'A réaliser': acc['A réaliser'] + ((sortedBrs[0].yearN * sortedBrs[0].probability) / 100) - sortedBrs[0].executedTurnOver
                    })
            },
            {
                id: `${brs._id}`,
                date: moment().month(brs._id - 1 ).format("MMMM"),
                'Détecté': 0,
                'En Montage': 0,
                'En contractualisation': 0,
                'Réalisé': 0,
                'A réaliser': 0
            }
        )
    )
}

export async function getBusinessProjectContracts(businessProject, context) {
    const projectContractsQuery = {
        businessProjects: {$elemMatch: {$eq:  new global.ObjectID(businessProject.id)}},
    }

    const contracts = await global.app.I.ContractMonitoring.collection.find(projectContractsQuery).toArray()

    return contracts.map(contract => contract.contractFullId).join(';')
}

export const entities = [
    {
        name: "FinancialSummary",
        fields: [
            "BusinessProject",
            "nature",
            "lot",
            "description",
            {type: "ProjectNature", nullable: true},
            {type: "SummaryTableHeader", link: "OTM"},
            {
                path: "financialTurnover",
                fieldPath: ["summaryTableHeaders.id", "summaryTableHeaders.summariesUnit.id"],
                f: function () {
                    const tArray = _.find(this.summaryTableHeaders, o => o.summariesUnit.id === "1")
                    return this.summaryTableHeaders ? _.sum(_.values(_.pick(tArray, years))) : 0
                }
            },
            {
                path: "financialExpenses",
                fieldPath: ["summaryTableHeaders.id", "summaryTableHeaders.summariesUnit.id"],
                f: function () {
                    const tArray = _.find(this.summaryTableHeaders, o => o.summariesUnit.id === "2")
                    return this.summaryTableHeaders ? _.sum(_.values(_.pick(tArray, years))) : 0
                }
            },
            {
                path: "financialNumberOfDays",
                fieldPath: ["summaryTableHeaders.id", "summaryTableHeaders.summariesUnit.id"],
                f: function () {
                    const tArray = _.find(this.summaryTableHeaders, o => o.summariesUnit.id === "3")
                    return this.summaryTableHeaders ? _.sum(_.values(_.pick(tArray, years))) : 0
                }
            },

        ],
        filters: [{
            name: "byBusinessProject",
            path: "businessProject",
            object: "BusinessProject",
            display: "countryAndFullName",
            width: 12,
            clearable: false,
            filters: ["businessProjectInProgress"],
            sorters: ["byCountry"],
            client: true,
            query: function (context) {
                const businessProjectId = _.get(context.data, "businessProject.id");

                return businessProjectId && {businessProject: new global.ObjectID(businessProjectId)}
            }
        }],
        afterDelete: function (object, context, callback) {
            const businessProjectId = object.businessProject.toString()
            const contextWithFullFieldPath = {
                ...basicContext(context),
                module: context.module,
                fieldPath: fieldPathBusinessProject
            }
            async.series([
                callback => financialStatementCalculation(businessProjectId, contextWithFullFieldPath, callback),
                callback => businessProjectFilesGeneration(businessProjectId, contextWithFullFieldPath, callback)
            ], (e) => {
                if(e) return callback(e)
                callback()
            });
        },
        afterSave: function (newObject, oldObject, context, callback) {
            const businessProjectId = newObject.businessProject.id
            const contextWithFullFieldPath = {
                ...basicContext(context),
                module: context.module,
                fieldPath: fieldPathBusinessProject
            }
            async.series([
                callback => financialStatementCalculation(businessProjectId, contextWithFullFieldPath, callback),
                callback => businessProjectFilesGeneration(businessProjectId, contextWithFullFieldPath, callback)
            ], (e) => {
                if(e) return callback(e)
                callback()
            });
        }
    },
    {
        name: "SummaryTableHeader",
        type: "mongoInternal",
        fields: [
            "SummariesUnit",
            {path: "N", type: "integer"},
            {path: "N+1", type: "integer"},
            {path: "N+2", type: "integer"},
            {path: "N+3", type: "integer"},
            {path: "N+4", type: "integer"},
            {path: "N+5", type: "integer"},
            {path: "N+6", type: "integer"},
            {path: "N+7", type: "integer"},
            {path: "N+8", type: "integer"},
            {path: "N+9", type: "integer"}
        ]
    },
    {
        name: "BillingFollowUp",
        fields: [
            {path: "date", type: "date", nullable: true},
            "object",
            {path: "amount", type: "decimal", default: 0},
            {path: "invoicedeadline", type: "date", nullable: true},
            {path: "paymentdate", type: "date", nullable: true}
        ],
        filters: [{
            name: "byBusinessProject",
            query: context => {
                const businessProjectId = _.get(context, "data.businessProjectId");

                if (!businessProjectId) return {};
                return {businessProject: new global.ObjectID(businessProjectId)};
            }
        }]
    },
    {
        name: "BusinessRevue",
        fields: [
            {path: "date", type: "date", dateFormat: "YYYY-MM-DD HH:mm:ss",
                ps: {
                object: [function(date) {
                        if(! date) date = new Date()
                        return date;
                    }]
                }
            },
            {path: 'user', type: "User", nullable: true},
            {path: "executedTurnOver", type: "decimal", default: 0},
            "projectStep",
            "orderMonth",
            {path: "revised", type: "decimal", default: 0},
            {path: "yearN", type: "decimal", default: 0},
            {path: "yearNPlusOne", type: "decimal", default: 0},
            "comment",
            {path: "probability", type: "decimal", default: 100},
            {
                path: "yearP",
                f: function () {
                    return (this.yearN * this.probability) / 100
                }
            },
            {
                path: "businessProjectCountryAndFullName",
                fieldPath: ["businessProject.countryAndFullName"],
                f: function () {
                    return this.businessProject && this.businessProject.countryAndFullName
                }
            },
        ],
        sorters: [{
            name: "byDescDate",
            isDefault: true,
            query: () => ({date: -1})
        }]
    },
    {
        name: "PointDeSortie",
        fields: ['date', 'Réalisé', 'A réaliser', 'En contractualisation', 'En Montage', 'Détecté'],
        filters: [
            {
                name: "byZone",
                path: "zone",
                object: "Zone",
                display: "name",
                default: {id: null},
                client: true
            }
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else
                    findPointDeSortieData(context)
                        .then(objects => callback(null, objects))
                        .catch(error => callback(error))
            })
        }
    },
    {
        name: "ExecutedFollowUp",
        fields: [
            {path: "year", type: "Year", unique: true, uniqueWith: "name"},
            "name",
            {path: "executedTurnOver", type: "decimal", default: 0},
            {path: "notedExpenses", type: "decimal", default: 0},
            {path: "notedNumberOfDays", type: "decimal", default: 0},
        ]
    },
    {
        name: "ProjectComponent",
        fields: [
            {path: "name", notEmpty: true},
            {path: "numberOfDays", type: "integer", nullable: true},
            {path: "expectedNumberOfDays", type: "integer", nullable: true},
            {path: "cost", type: "decimal", nullable: true},
            {path: "expectedCost", type: "decimal", nullable: true},
            {path: "turnOver", type: "decimal", nullable: true},
            {path: "expectedTurnover", type: "decimal", nullable: true},
            {type: "Status", nullable: true},
            {path: "effectiveSubmissionDate", type: "date", nullable: true},
            {path: "validationDate", type: "date", nullable: true}
        ]
    },
    {
        name: "BusinessProject",
        facets: [
            "comments",
            "files",
            {name: "files", linkType: "OTO"},
            {name: "files", linkType: "OTO", path: "crrFile"},
        ],
        filters: businessProjectFilters,
        fields: [

            "financialStatement",
            "financialStatementDetection",

            //TODO
            // Potentially depreciated //

            {path: "travelCost", type: "decimal", default: 0, ps: {object: ["notNegative"]}},
            {path: "perDiem", type: "decimal", default: 0, ps: {object: ["notNegative"]}},

            /////////////////////////////
            /////////////////////////////

            {
                path: "code", unique: true, ps: {
                    object: [{
                        type: "nextSequence",
                        sequenceId: "i.businessProjectCode",
                        formatResult: result => `${result}`
                    }]
                }
            },
            "Country",
            "TypeOfOffer",
            {path: "name", notEmpty: true},
            {path: "description"},
            {path: "developerPitch"},
            {path: "developerPitch2"},
            {type: "Opportunity", link: "MTM", nullable: true},
            {type: "SecurityLevel", nullable: true},
            {type: "Risk", nullable: true},
            {type: "Customer", nullable: true},
            {type: "ProjectFunding", nullable: true},
            {path: "estimatedTurnover", type: "decimal", default: 0},
            {path: "globalTurnover", type: "decimal", default: 0},
            {type: "MarginOverContract", nullable: true},
            {path: "beginYear", type: "Year", sorters: ['byDescYear'], nullable: true},
            {type: "InterventionMode", nullable: true},
            {type: "ResponseMode", nullable: true},
            {path: "offerSubmissionDate", type: "date", nullable: true},
            {path: "effectiveOfferSubmissionDate", type: "date", nullable: true},
            {path: "offerAcceptedDate", type: "date", nullable: true},
            {path: "estimatedResponseDate", type: "date", nullable: true},
            {path: "reportedSubmissionDate", type: "date", nullable: true},
            {type: "ContractualSchema", nullable: true},

            {type: "ResourceType", link: "MTM", nullable: true},
            {type: "CompensationMode", link: "MTM", nullable: true},

            {path: "completedService", type: "boolean", default: false},
            {path: "allPaymentsReceived", type: "boolean", default: false},
            {path: "closingDocumentsReceived", type: "boolean", default: false},

            {path: "comment"},
            {path: "actuality"},
            {path: "deadlinesAndSchedule"},
            {path: "costs"},
            {path: "treasury"},
            {path: "otherRecipients"},

            {path: "revisedTCA", type: "decimal", nullable: true},
            {path: "plannedCA", type: "decimal", nullable: true},
            {path: "brOrderTakingMonth", nullable: true},
            {path: "probability", type: "decimal", nullable: true},
            {path: "plannedCAPlusOne", type: "decimal", nullable: true},

            {path: "sendReport", type: "boolean"},

            {
                path: "subcontractors", type: "Provider", nullable: true, filters: ["subcontractors"],
                link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}}
            },
            {
                path: "partners", type: "Provider", nullable: true, filters: ["partner"],
                link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}}
            },
            {
                path: "competitors", type: "Provider", nullable: true, filters: ["competitor"],
                link: {type: "MTM", oppositeField: {link: {deleteType: "block"}}}
            },

            {path: "recipients", type: "TeamMember", link: "MTM", nullable: true},
            {path: "technicalTeamMember", type: "TeamMember", nullable: true},
            {path: "commercialTeamMember", type: "TeamMember", nullable: true},
            {path: "monitoringTeamMembers", type: "TeamMember", link: "MTM", nullable: true},

            {path: "revueDate", type: "date", nullable: true},
            {path: "specialFollowUp", type: "boolean"},

            {path: "estimatedNumberOfDays", type: "decimal", default: 0, ps: {object: ["notNegative"]}},
            {path: "buildOfferNumberOfDays", type: "decimal", default: 0, ps: {object: ["notNegative"]}},

            {type: "ExecutedFollowUp", link: "OTM"},

            {type: "ProjectComponent", link: "OTM"},

            {type: "BillingFollowUp", link: "OTM"},

            {type: "BusinessRevue", link: "OTM"},

            {
                path: "creationDate", type: "date", ps: {
                    object: [{
                        $u: function (creationDate) {
                            if (this.options.$v && !creationDate) return new Date();
                            return creationDate;
                        }
                    }]
                }
            },
            {path: "modificationDate", type: "date", nullable: true},
            {path: "modificationUser", type: "User", nullable: true},

            {path: "delegatedUser", type: "TeamMember", nullable: true},

            {path: "needLocalStructure", type: "boolean"},
            {type: "BusinessProject", nullable: true},
            {type: "OriginOfTheCase", nullable: true},
            "descriptionOfOrigin",
            {type: "BusinessProjectResult", nullable: true},
            {path: "signatureDate", type: "date", nullable: true},
            {path: "beginningOfRealisationDate", type: "date", nullable: true},

            {path: "turnover", type: "decimal", default: 0},
            {path: "deliverablehanded", type: "decimal", default: 0},
            {path: "deliverablevalidated", type: "decimal", default: 0},
            {path: "resources", type: "decimal", default: 0},

            {path: "bpTurnover", type: "object", nullable: true},
            {path: "bpMarginOverContract", type: "object", nullable: true},

            "projectManager",
            "projectTeam",
            "nextDeadLine",
            "alert",
            "referentInSupport",
            {path: "workflow", type: "object", nullable: true},
            {
                path: "currentFunctions",
                fieldPath: ["workflow.profileConfigs.teamMemberProfile.abreviation"],
                $f: function (businessProject, context, callback) {
                    getBusinessProjectTeamMemberProfile(businessProject, context)
                        .then(result => callback(null, result))
                        .catch(error => callback(error))
                }
            },
            {
                path: "operationsAndServicesContracts",
                $f: function (businessProject, context, callback) {
                    getBusinessProjectContracts(businessProject, context)
                        .then(result => callback(null, result))
                        .catch(error => callback(error))
                }
            },
            {
                path: "statusAndOrder",
                fieldPath: ["workflow"],
                lazy: true,
                $f: function (businessProject, context, callback) {
                    callback(null, getStatusAndOrder(businessProject, context))
                }
            },
            {
                path: "status",
                fieldPath: ["workflow"],
                lazy: true,
                $f: function (businessProject, context, callback) {
                    callback(null, getStatus(businessProject))
                }
            },
            {
                path: "buttons",
                $f: function (businessProject, context, callback) {
                    getBusinessProjectButtons(businessProject, context)
                        .then(result => callback(null, result))
                        .catch(error => callback(error))
                }
            },

            ...businessProjectGetters
        ],
        onlyOneLineByYearLot: function(businessProject, context, callback){
            const array = businessProject.executedFollowUps
                ? businessProject.executedFollowUps.map(val => `${val.year.id}-${val.name}`)
                : []

            const hasDuplicateValue = (new Set(array)).size !== array.length
            if (hasDuplicateValue) {
                return callback(new Errors.ValidationError('onlyOneLineByYearLot'));
            }else {
                callback()
            }
        },
        shouldHaveAFinancialSynthesis: function(businessProject, oldBusinessProject, context, callback){
            const step = _.get(oldBusinessProject, 'workflow.step')

            if (["buildOffer", "submitOffer"].includes(step) &&
                _.get(context, 'action') === 'validate'
            ) {
                global.app.I.FinancialSummary.collection.find({
                    projectNature: "sale",
                    businessProject: new global.ObjectID(businessProject.id),
                    group: new global.ObjectID(context.group.id)
                }).toArray(
                    (error, fss) => {
                        if(!fss.length) return callback(new Errors.ValidationError('shouldHaveAFinancialSynthesis'))
                        callback()
                    })
            }else {
                callback()
            }
        },
        turnoverNotNull: function(businessProject, oldBusinessProject, context, callback){
            const step = _.get(oldBusinessProject, 'workflow.step')

            if (['formX', 'buildOffer', 'submitOffer'].includes(step) && _.get(context, 'action') === 'validate') {
                businessProject.estimatedTurnover && businessProject.globalTurnover
                    ? callback()
                    : callback(new Errors.ValidationError('turnoverShouldNotBeNull'))
            } else {
                callback()
            }
        },
        hasWFConfigForContry: function(businessProject, oldBusinessProject, context, callback){
            if (!oldBusinessProject) {
                global.db.collection("i.workflowConfig").findOne(
                    {
                        group: new global.ObjectID(context.group.id),
                        country: new global.ObjectID(businessProject.country.id),
                    },
                    (e, workflowConfig) => {
                        if(e) return callback(e)
                        if(!workflowConfig) {
                            return callback(new Errors.ValidationError('countryHasNoWorkflow'))
                        }
                        callback();
                    })

            } else {
                callback()
            }
        },
        validationOnLastStepOfSubmitOffer: function(businessProject, context, callback){

            const step = _.get(businessProject, 'workflow.step')
            const order = _.get(businessProject, 'workflow.order')
            const maxOrderOfTheStep = _.get(businessProject, `workflow.maxOrders.submitOffer`)
            if(step === 'submitOffer' && order === maxOrderOfTheStep && _.get(context, 'action') === 'validate') {
                if(!businessProject.signatureDate) {
                    return callback(new Errors.ValidationError('shouldBeSignedOnLastStepOfSubmitOffer'))
                } else if(!businessProject.beginningOfRealisationDate) {
                    return callback(new Errors.ValidationError('shouldHaveBeginningOfRealisationDateOnLastStepOfSubmitOffer'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        },
        isActionAllowed: function(businessProject, oldBusinessProject, context, callback){
            if(
                oldBusinessProject &&
                oldBusinessProject.workflow &&
                // exclude gestion businessRevue bp6 modules
                !['gestion', 'businessRevue', 'bp6', 'kpBusinessProject', 'projectMonitoring'].includes(_.get(context, 'module.name'))
            ){
                getBusinessProjectButtons(businessProject, context)
                    .then(btns => {
                        if(
                            btns.filter(btn => btn.action)
                                .map(btn => btn.action)
                                .some(action => action === context.action)
                        ){
                            callback()
                        }else {
                            return callback(new Errors.ValidationError("actionNotAllowed"))
                        }
                    })
                    .catch(error => callback(error))
            }else {
                callback()
            }
        },
        validateSave: function(businessProject, oldBusinessProject, context, callback) {
            if (context.action && context.action !== 'delegate' && businessProject.delegatedUser) {
                return callback(new Errors.ValidationError("selectDelegateActionToDelegate"))

            } else if (context.action && context.action === 'delegate') {
                if(businessProject.delegatedUser) {

                    bpHasAnExceptionForTeamMemberProfile(businessProject, _.get(businessProject, 'delegatedUser.teamMemberProfile'), context)
                        .then(risk => {
                            if (risk) {
                                return callback(new Errors.ValidationError('thereIsAnExceptionForThisUserMatchingOnThisBP'))
                            } else {
                                callback()
                            }
                        })
                        .catch(error => callback(error))
                } else {
                    return callback(new Errors.ValidationError("selectTheUserYouWantToDelegateTo"))
                }
            } else if (context.module && context.module.name && context.module.name !== 'adminDelegation'){
                async.series([
                    callback => this.hasWFConfigForContry(businessProject, oldBusinessProject, context, callback),
                    callback => this.validationOnLastStepOfSubmitOffer(businessProject, context, callback),
                    callback => this.isActionAllowed(businessProject, oldBusinessProject, context, callback),
                    callback => this.turnoverNotNull(businessProject, oldBusinessProject, context, callback),
                    callback => this.onlyOneLineByYearLot(businessProject, context, callback),
                    callback => this.shouldHaveAFinancialSynthesis(businessProject, oldBusinessProject, context, callback)
                ], callback);
            } else {
                callback()
            }
        },
        sorters: [
            {
                name: "byCode",
                query: () => ({code: 1})
            },
            {
                name: "byCountry",
                query: () => ({'country.name': 1})
            }
        ],
        ps: {
            object: [{
                $$u: function (businessProject, callback) {
                    if (this.options.$v) {
                        businessProject.modificationDate = new Date();
                        businessProject.modificationUser = this.options.context.user;
                    }
                    return callback(null, businessProject);
                }
            }],
            context: [{
                $$u: function (context, callback) {
                    if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "U") {
                        context.internalFieldPath = [
                            ...new Set([
                                ...context.internalFieldPath,
                                ...fieldPathBusinessProject
                            ])
                        ];
                    }
                    callback(null, context);
                }
            }]
        },
        beforeSave: (object, oldObject, context, callback) => {
            const action  = context.restAction && context.restAction.crudType
            if(action === 'C') {
                const extractedYear = object.brOrderTakingMonth && object.brOrderTakingMonth.split('-')[0]
                if(extractedYear) {
                    object.beginYear = {
                        id: parseInt(extractedYear)
                    }
                }
                if(object.revisedTCA) {
                    object.estimatedTurnover = object.revisedTCA
                }
            }
            if (context.action && context.action === 'delegate' && object.delegatedUser) {

                if(_.get(context, 'module.name') === 'adminDelegation') {
                    addNewActiveUserToCurrentWorkflow(object, object.delegatedUser, e => {
                        if(e) return callback(e);
                        object.comments.push({
                            user: _.pick(context.user, ['id', 'name']),
                            text: `${context.tc('delegateTo')} ${object.delegatedUser.userName}`,
                            date: moment().format("YYYY-MM-DD HH:mm")
                        })

                        object.delegatedUser = null
                        callback(null, object, oldObject);
                    })
                } else {
                    applyWorkflowDelegation(object, context.user, object.delegatedUser, context, e => {
                        if(e) return callback(e);
                        object.comments.push({
                            user: _.pick(context.user, ['id', 'name']),
                            text: `${context.tc('delegatedTo')} ${object.delegatedUser.userName}`,
                            date: moment().format("YYYY-MM-DD HH:mm")
                        })

                        object.delegatedUser = null
                        callback(null, object, oldObject);
                    })
                }
            } else {
                if(_.get(context, 'module.name') === 'businessRevue') {

                    // add projectStep and executedTurnover and order month to new businessRevues
                    const brIds = oldObject.businessRevues.map(o => o.id)

                    if(object.businessRevues) {

                        const query = {
                            businessProject: new global.ObjectID(object.id),
                            year: moment().year(),
                        }

                        global.app.I.ExecutedFollowUp.find(
                            {
                                group: context.group,
                                fieldPath: [
                                    "_id", "year", "executedTurnOver", "businessProject"
                                ],
                                query
                            }
                            , (e, executedFollowUps) => {
                                if(e) return callback(e);

                                const total = executedFollowUps.length !== 0 ? executedFollowUps.reduce((acc, execFollowUp) => acc + execFollowUp.executedTurnOver, 0) : 0

                                const orderMonth  = object.signatureDate
                                    ? {orderMonth: moment(object.signatureDate).format('YYYY-MM')}
                                    : {}

                                object.businessRevues = object.businessRevues.map(o => {
                                    return !brIds.includes(o.id)
                                        ? {
                                            ...o,
                                            ...orderMonth,
                                            projectStep: oldObject.workflow.step,
                                            executedTurnOver: total,
                                            user: _.pick(context.user, ['id', 'name']),
                                        }
                                        : o
                                })

                                callback(null, object, oldObject);
                            }
                        )

                    }
                }
                else {
                    callback(null, object, oldObject);
                }
            }

        },
        afterSave: function (newObject, oldObject, context, callback) {
            if (context.action && context.action !== 'delegate') {
                const contextWithFullFieldPath = {
                    ...basicContext(context),
                    module: context.module,
                    fieldPath: fieldPathBusinessProject
                }
                global.app.I.BusinessProject.get(
                    newObject.id,
                    contextWithFullFieldPath,
                    (e, businessProject) => {
                        if (e) return callback(e)

                        if (businessProject.workflow) {
                            if (context.action && context.action !== 'save') {
                                updateStepWorkflowBP(businessProject, _.pick(businessProject.workflow, ['step', 'order']), context)
                                    .then(businessProject => {
                                        async.series([
                                            callback => updateBeginYear(businessProject, oldObject, context, callback),
                                            callback => enhanceProjectComponents(businessProject, context, callback),
                                            callback => initializeReferentialBusiness(businessProject, context, callback),
                                            callback => financialStatementCalculation(businessProject.id, context, callback),
                                            callback => businessProjectFilesGeneration(businessProject.id, contextWithFullFieldPath, callback),
                                            callback => createBPForAMI(businessProject, context, callback),
                                            callback => createBRLine(businessProject, oldObject, context, callback)
                                        ], (e) => {
                                            if(e) return callback(e)
                                            workflowMailing(businessProject.id, oldObject, true, context)
                                            crrMail(businessProject.id, context)
                                            notifyMonitors(businessProject, oldObject, context).then(
                                                () => console.log('done notifying project monitors')
                                            )
                                            getEdfinWorkflowNotificationData(context.group.id).then(
                                                data => global.ioSocket.emit('afterSave', data)
                                            )

                                            callback()
                                        });
                                    })
                                    .catch(callback)
                            } else {
                                async.series([
                                    callback => updateBeginYear(businessProject, oldObject, context, callback),
                                    callback => updateBeginYearToMatchBr(businessProject, oldObject, context, callback),
                                    callback => financialStatementCalculation(businessProject.id, context, callback),
                                    callback => businessProjectFilesGeneration(businessProject.id, contextWithFullFieldPath, callback),
                                    callback => createBRLine(businessProject, oldObject, context, callback)
                                ], (e) => {
                                    if(e) return callback(e)
                                    crrMail(businessProject.id, context)
                                    notifyMonitors(businessProject, oldObject, context).then(
                                        () => console.log('done notifying project monitors')
                                    )
                                    callback()
                                });
                            }
                        } else {
                            initializeWorkflowBP(businessProject, context)
                                .then(() => {
                                    async.series([
                                        callback => financialStatementCalculation(businessProject.id, context, callback),
                                        callback => businessProjectFilesGeneration(businessProject.id, context, callback),
                                        callback => createFirstBR(businessProject, context, callback)
                                    ], (e) => {
                                        if(e) return callback(e)
                                        workflowMailing(businessProject.id, oldObject, true, context)
                                        notifyMonitors(businessProject, oldObject, context).then(
                                            () => console.log('done notifying project monitors')
                                        )
                                        callback()
                                    });
                                })
                                .catch(callback)
                        }
                    }
                )
            } else callback()
        }
    }
]
