import {returnButton, saveButton, sendButton} from "../utils/demandUtils";
import {
    changeFieldDisabled,
    processDynamicColumns,
    changeFieldProperty,
    setFormButtons, setFieldVisibility, FETCH_FORM_DATA_LISTS_DONE
} from "../../../../apps/KpModule/actions";
import {GET_OBJECT_SUCCESS} from "../../../../apps/KpModule/actions/api";
import {getDataListList} from "../../../../apps/KpModule/selectors";
import moment from "moment";
import _ from "lodash";
import {translateName} from "../../../../utils/i18n";
import {addFloats} from "../utils/usefulFunctions";
import {handleParentColumnsForTable} from "../utils/trackingUtils";

export const entities = [
    {
        name: 'RevisedTable',
        fields: [
            'objet',
            {path: 'entity', type: 'OrganizationalMesh', nullable: true},
            {path: 'imputationType', type: 'ImputationType', nullable: true},
            //'imputationTriplet',
            {path: 'validatedEngagement', type: 'decimal'},
            {path: 'budget', type: 'decimal'},
            {path: 'estimated', type: 'decimal'},
            {path: 'validated', type: 'decimal'},
            {
                path: 'amountByRealizationType',
                type: 'AmountByRealizationType',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false,
                }
            },
            {path: 'revised', type: 'decimal'},
            {path: 'ongoingFiscalYearValidatedEngagement', type: 'decimal'},
            {path: 'ongoingFiscalYearEstimated', type: 'decimal'},
            {path: 'ongoingFiscalYearValidated', type: 'decimal'},
            {path: 'ongoingFiscalYearBudget', type: 'decimal'},
            {path: 'ongoingFiscalYearRevised', type: 'decimal'},
            {path: 'updated', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
            {path: 'user', type: 'User', nullable: true},
            {
                path:'triplet',
                fieldPath: ['objet', 'entity.name', 'imputationType.name'],
                $f: (object, context, callback) => {
                    callback(null, object.objet + ' - ' + translateName(object.entity.name,  _.get(context, "language.id")) + ' - ' + translateName(object.imputationType.name,  _.get(context, "language.id")) )
                },
            }
        ],
    },
    {
        name: 'RevisedTableHistory',
        fields:[
            'objet',
            {path: 'entity', type: 'OrganizationalMesh', nullable: true},
            {path: 'imputationType', type: 'ImputationType', nullable: true},
            //'imputationTriplet',
            {path: 'validatedEngagement', type: 'decimal'},
            {path: 'budget', type: 'decimal'},
            {path: 'estimated', type: 'decimal'},
            {path: 'validated', type: 'decimal'},
            {path: 'revised', type: 'decimal'},
            {path: 'ongoingFiscalYearEstimated', type: 'decimal'},
            {path: 'ongoingFiscalYearValidated', type: 'decimal'},
            {path: 'ongoingFiscalYearBudget', type: 'decimal'},
            {path: 'ongoingFiscalYearRevised', type: 'decimal'},
            {path: 'ongoingFiscalYearValidatedEngagement', type: 'decimal'},
            {
                path: 'amountByRealizationType',
                type: 'AmountByRealizationType',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false,
                }
            },
            {path: 'updated', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
            {path: 'user', type: 'User', nullable: true}
        ],
    },
    {
        name: 'CompleteRevisedTableHistory',
        fields:[
            //{path: 'demand', type: 'Demand'},
            'objet',
            {path: 'entity', type: 'OrganizationalMesh'},
            {path: 'imputationType', type: 'ImputationType'},
            //'imputationTriplet',
            {path: 'validatedEngagement', type: 'decimal'},
            {path: 'budget', type: 'decimal'},
            {path: 'estimated', type: 'decimal'},
            {path: 'validated', type: 'decimal'},
            {path: 'revised', type: 'decimal'},
            {path: 'ongoingFiscalYearEstimated', type: 'decimal'},
            {path: 'ongoingFiscalYearValidated', type: 'decimal'},
            {path: 'ongoingFiscalYearBudget', type: 'decimal'},
            {path: 'ongoingFiscalYearRevised', type: 'decimal'},
            {path: 'ongoingFiscalYearValidatedEngagement', type: 'decimal'},
            {
                path: 'amountByRealizationType',
                type: 'AmountByRealizationType',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false,
                }
            },
            {path: 'updated', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
            {path: 'user', type: 'User', nullable: true},
            {path: 'revueDate', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', nullable: true},
        ],
    },
    {
        name: 'AmountByRealizationType',
        type: 'mongoInternal',
        fields:[
            'RealizationType',
            'BudgetColumnType', //static
            {path: 'amount', type: 'decimal'}
        ]
    }
]

export const module_ = {
    name: "projectCommittee",
    object: 'Demand',
    category: {
        path: 'tracking',
    },
    newable: false,
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: 'demandNumber', tKey: 'N° Projet'},
            'title', 'organizationAndMesh',
            {path: 'demandNature', tKey: 'category', translateName: true},
            'projectCommitteeLastUpdate',
            {path: 'noDeleteButtonAccess', hidden: true}
        ],
        form: {
            fields: [
                {path: 'demandNumber', tKey: 'N° Projet', disabled: true},
                {path: 'title', disabled: true},
                {path: 'organizationAndMesh',disabled: true},
                {path: 'demandCategory', tKey: 'processType',disabled: true, hidden: true},
                {path: 'demandNature', tKey: 'category', disabled: true, translateName: true},
                {
                    path: 'meetingsPlanning',
                    display: 'code',
                    disabled: true,
                    required: true,
                    fieldPath:['id', 'code', 'revisedReportingPeriod', 'meetingsPlanningRange'],
                },
                {
                    path: 'revueDate',
                    disabled: true
                    //endDate: moment().format('YYYY-MM-DD'),
                    /*
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            console.log("newValue", newValue)
                            const state = store.getState()

                            const previousRevisedTable = _.get(state, 'edit.object.data.revisedTable')
                            const previousRevisedTableHistory = _.get(state, 'edit.object.data.revisedTableHistory')
                            const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                            const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                            const revisedTable = revisedTableField && _.cloneDeep(revisedTableField.getValue())

                            const demandNumberField = module.viewMap.form.fields.find(field => field.path === 'demandNumber')
                            const demandNumber = demandNumberField && demandNumberField.getValue()
                            const fiscalYearsList = getDataListList(state, 'ProjectCommittee-FiscalYear')
                            const budgetsList = getDataListList(state, 'ProjectCommittee-Budget')
                            console.log('budgetsList', budgetsList)
                            const budgetsListForThisDemand = budgetsList.filter( budget => budget.analyticalMesh.attachment.filter(att => att.analyticalAxis.isProjectAxis === true).map(att => att.code).includes(demandNumber) )

                            console.log("mm", budgetsList)

                            const imputationsField = module.viewMap.form.fields.find(field => field.path === 'imputations')
                            const imputations = imputationsField && imputationsField.getValue()

                            const previousRevueDate = _.get(state, 'edit.object.data.revueDate')

                            if ( !!previousRevueDate && !!newValue && !!revisedTable && !!imputations){
                                if (previousRevueDate === newValue){
                                    //revisedTableHistoryField.setValue(previousRevisedTableHistory)
                                    revisedTableField.setValue(previousRevisedTable)
                                }
                                else {
                                    //revisedTableHistoryField.setValue(previousRevisedTable)
                                    const compatibleFiscalYear = fiscalYearsList.find( fiscalYear => moment(newValue).isBetween(fiscalYear.fiscalYearRange[0], fiscalYear.fiscalYearRange[1], 'day'))
                                    imputations.forEach( imp => {
                                        let correspondentRevisedLine = revisedTable.find(line => line.objet === imp.objet && line.entity.id.toString() === imp.organizationalMesh.id.toString() && line.imputationType.id.toString() === imp.imputationType.id.toString())
                                        const desiredMonthAndAmounts = !!compatibleFiscalYear ? imp.monthAndAmountList.filter(monthAndAmount => moment(monthAndAmount.month).isBetween(compatibleFiscalYear.fiscalYearRange[0], compatibleFiscalYear.fiscalYearRange[1], 'month', '[]')) : []
                                        const fiscalYearValidatedAmount = desiredMonthAndAmounts.reduce((acc, monthAndAmount) => acc + monthAndAmount.amount, 0)

                                        //const correspondentBudget = budgetsListForThisDemand.find( budget => budget.mesh.id.toString() === imp.organizationalMesh.id.toString() && budget.analyticalMesh.code=== imp.objet)
                                        //const desiredEstimatedBudget = correspondentBudget.estimatedBudgetFollowUp.filter( line => line.type.id.toString() === imp.imputationType.id.toString())
                                        //let groupedByFiscalYear = _.groupBy(desiredEstimatedBudget, 'fiscalYear.id')
                                        //const estimatedAmountPerFiscalList = Object.keys(groupedByFiscalYear).map(obj => {
                                         //   const
                                        //})
                                        //const manuallyInformedEstimatedBudget = desiredEstimatedBudget.filter(obj => obj.amount > 0)
                                        //const estimatedAmount = !!manuallyInformedEstimatedBudget.length? manuallyInformedEstimatedBudget.reduce((prev, current) => {
                                        //    return (prev.month > current.month) ? prev : current
                                        //}) : correspondentBudget.budgetFollowUp.find( obj => obj.fiscalYear.id.toString() === compatibleFiscalYear.id.toString() && obj.type.id.toString() === imp.imputationType.id.toString())
                                        //const ongoingEstimated = !!compatibleFiscalYear? desiredEstimatedBudget.filter( line => line.meetingsPlanning.fiscalYear.id.toString() === compatibleFiscalYear.id.toString()).reduce((acc, line) => acc + line.amount, 0) : 0
                                        correspondentRevisedLine.ongoingFiscalYearValidated = fiscalYearValidatedAmount
                                        //correspondentRevisedLine.estimated = !!compatibleFiscalYear? estimatedAmount : 0
                                        //correspondentRevisedLine.ongoingFiscalYearEstimated = ongoingEstimated
                                        //correspondentRevisedLine.updated = null
                                        //correspondentRevisedLine.user = null
                                    })
                                    revisedTableField.setValue(revisedTable)
                                }

                            }
                        }
                    }
                     */
                },
                {
                    path: 'revisedTable',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        //{path: 'imputationTriplet', width: 800, parentColumn: ''},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', type: 'editText', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50},
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.', type: 'editText', width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        {path: 'amountByRealizationType', hidden: true},
                        {path: 'updated', width: 360, tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', width: 250, parentColumn: 'Mise à jour', hidden: true}
                    ],
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store, formInitialize, getObjectSuccessAction }) {
                            const state = store.getState()
                            const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                            const previousRevisedTable = _.get(state, 'edit.object.data.revisedTable')
                            const connectedUser = _.get(state, 'user')
                            if (!!newValue?.length && !!oldValue && !formInitialize){
                                const filteredOldValue = _.cloneDeep(oldValue).filter( line =>
                                    line.estimated !== 0 ||
                                    line.budget !== 0 ||
                                    line.validated !== 0 ||
                                    line.revised !== 0 ||
                                    line.validatedEngagement !== 0 ||
                                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                                    line.ongoingFiscalYearEstimated !== 0 ||
                                    line.ongoingFiscalYearBudget !== 0 ||
                                    line.ongoingFiscalYearValidated !== 0 ||
                                    line.ongoingFiscalYearRevised !== 0 ||
                                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                                )

                                if (newValue.length === filteredOldValue.length && JSON.stringify(newValue)!==JSON.stringify(filteredOldValue)){
                                    // setting the other lines
                                    const removedTotalLine = newValue.filter(obj => {
                                        return !(obj.objet === 'Tot.' && obj.entity === null && obj.imputationType === null)
                                    })
                                    removedTotalLine.forEach(row => {
                                        const previousRow = !!previousRevisedTable && previousRevisedTable.find( line => line.objet === row.objet && line.entity.id.toString() === row.entity.id.toString() && line.imputationType.id.toString() === row.imputationType.id.toString())
                                        const oldRow = filteredOldValue.find( line => line.objet === row.objet && line.entity.id.toString() === row.entity.id.toString() && line.imputationType.id.toString() === row.imputationType.id.toString())
                                        const { updated: updatedPrevious, user: userPrevious, ...previousValues } = previousRow
                                        const { updated: updatedCurrent, user: userCurrent, ...currentValues } = row;
                                        const currentValuesToBeCompared = {revised: row.revised.toString(), ongoingFiscalYearRevised: row.ongoingFiscalYearRevised.toString()}
                                        let previousValuesToBeCompared = {revised: previousRow.revised.toString(), ongoingFiscalYearRevised: previousRow.ongoingFiscalYearRevised.toString()}
                                        if (!!previousRow && JSON.stringify(currentValuesToBeCompared)===JSON.stringify(previousValuesToBeCompared)){
                                            row.updated = previousRow.updated
                                            row.user = previousRow.user
                                            revisedTableField.setValue(removedTotalLine)
                                        }
                                        else if (JSON.stringify(row)!==JSON.stringify(oldRow)) {
                                            row.updated = moment().format("YYYY-MM-DD HH:mm:ss")
                                            row.user = connectedUser
                                            revisedTableField.setValue(removedTotalLine)
                                        }
                                    })
                                    // setting total line
                                    if (newValue.length>1){
                                        const totalLine = newValue.find( line => line.objet === 'Tot.' && line.entity === null && line.imputationType === null)
                                        totalLine.estimated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                                        totalLine.validated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                                        totalLine.revised = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                                        totalLine.ongoingFiscalYearEstimated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                                        totalLine.ongoingFiscalYearValidated = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                                        totalLine.ongoingFiscalYearRevised = removedTotalLine.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                                        removedTotalLine.push(totalLine)
                                    }
                                    removedTotalLine.forEach(line => {
                                        line.validated = _.round(line.validated)
                                        line.estimated = _.round(line.estimated)
                                        line.revised = _.round(line.revised)
                                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                                    })
                                    revisedTableField.setValue(removedTotalLine)
                                }
                            }
                        }
                    }
                },
                {
                    path: 'revisedTableHistory',
                    type: 'dtObjects',
                    parentHeader: true,
                    applyBoard: true,
                    autoGrow: true,
                    fields: [
                        {path: 'objet', parentColumn: ''},
                        {path: 'entity', display: 'code', parentColumn: '', width: 70},
                        {path: 'imputationType', tKey: 'type', display: 'code', parentColumn: '', width: 50},
                        //{path: 'imputationTriplet', width: 800, parentColumn: ''},
                        {path: 'ongoingFiscalYearBudget', tKey: 'bud.', width: 50},
                        {path: 'ongoingFiscalYearEstimated', tKey: 'est.', width: 50},
                        {path: 'ongoingFiscalYearValidated', tKey: 'prj.', width: 50},
                        {path: 'ongoingFiscalYearRevised', tKey: 'rev.', width: 50},
                        {path: 'ongoingFiscalYearValidatedEngagement', tKey: "dem.", width: 50},
                        {path: 'budget', tKey: 'bud.', width: 50},
                        {path: 'estimated', tKey: 'est.', width: 50},
                        {path: 'validated', tKey: 'prj.', width: 50},
                        {path: 'revised', tKey: 'rev.', width: 50},
                        {path: 'validatedEngagement', tKey: "dem.", width: 50},
                        {path: 'amountByRealizationType', hidden: true},
                        {path: 'dynamicAmountsTypes', dynamic: true, width: 50},
                        {path: 'updated', width: 360, tKey: 'date', parentColumn: 'Mise à jour', hidden: true},
                        {path: 'user', width: 250, parentColumn: 'Mise à jour', hidden: true}
                    ],
                },
                {
                    path: 'completeRevisedTableHistory',
                    fieldPath: ['id'],
                    hidden: true,
                },
                {
                    path: 'imputations',
                    type: 'accordion',
                    hidden: true,
                    viewMap: {
                        dt: [
                            'objet',
                            'organizationalMesh',
                            'imputationType',
                            {path: 'currency', display: 'symbol'},
                            'totalAmountPerImputation',
                        ],
                        form: {
                            fields : [
                                'objet',
                                'organizationalMesh',
                                'imputationType',
                                {path: 'currency', display: 'symbol'},
                                {
                                    path: 'monthAndAmountList',
                                    fieldPath: ['id', 'month', "amount", 'fiscalYear'],
                                    type: 'dtObjects',
                                    hidden: false,
                                    fields: [
                                       "month",
                                       "amount",
                                        {path: 'fiscalYear', hidden: true}
                                    ],
                                },
                                'totalAmountPerImputation',
                                'description'
                            ]
                        }
                    },
                },
                {path: 'synthesis', type: 'richTextEditor'},
                {path: 'news', type: 'richTextEditor'},
                {path: 'deadline', type: 'richTextEditor'},
                {path: 'cost', type: 'richTextEditor'},
                {path: 'treasury', type: 'richTextEditor'},
                {path: 'committeeMinutes', type: 'richTextEditor'},
                {
                    path : 'sendTo',
                    filters: ['isInGroupModel']
                },
                {
                    path: 'sendToOthers',
                    placeholder: "taper une adresse mail et appuyer sur entrer",
                    type: 'creatableTags'
                },
                {path: 'projectCommitteeFiles', removable: false},
                {path: 'displayEngagementData', hidden: true},
                {path: 'currentFiscalYear', hidden: true}
            ],
            onOpen: ({ module, state, store, t }) => {
                store.dispatch(setFormButtons([saveButton, sendButton, returnButton]))
                const meetingsPlanningField = module.viewMap.form.fields.find(field => field.path === 'meetingsPlanning')
                const meetingsPlannings = getDataListList(state, "m-SE-projectCommittee.Demand_meetingsPlanning")
                const ongoingMeetingPlanning = meetingsPlannings.find(obj => moment().isBetween(obj.meetingsPlanningRange[0], obj.meetingsPlanningRange[1], 'day', '[]'))
                const meetingPlanningToConsider = ongoingMeetingPlanning
                const revueDateField = module.viewMap.form.fields.find(field => field.path === 'revueDate')
                revueDateField.setValue(moment().format('YYYY-MM-DD'))
                meetingsPlanningField.setValue(meetingPlanningToConsider)


                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()
                const revisedColumn = revisedTableField.fields.find(field => field.path === 'revised')
                const ongoingFiscalYearRevisedColumn = revisedTableField.fields.find(field => field.path === 'ongoingFiscalYearRevised')

                const displayEngagementDataField =  module.viewMap.form.fields.find(field => field.path === 'displayEngagementData')
                const displayEngagementData =  !!displayEngagementDataField && displayEngagementDataField.getValue()
                const validatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementColumn = revisedTableField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')
                const validatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'validatedEngagement')
                const ongoingFiscalYearValidatedEngagementHistoryColumn = revisedTableHistoryField.fields.find(field => field.path === 'ongoingFiscalYearValidatedEngagement')

                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(validatedEngagementHistoryColumn.id, displayEngagementData))
                store.dispatch(setFieldVisibility(ongoingFiscalYearValidatedEngagementHistoryColumn.id, displayEngagementData))

                const shouldReport = !!ongoingMeetingPlanning ? moment().isBetween(ongoingMeetingPlanning.revisedReportingPeriod[0], ongoingMeetingPlanning.revisedReportingPeriod[1], 'day', '[]') : false
                store.dispatch(changeFieldDisabled(revisedColumn.id, !shouldReport))
                store.dispatch(changeFieldDisabled(ongoingFiscalYearRevisedColumn.id, !shouldReport))
                store.dispatch(setFieldVisibility(revisedTableHistoryField.id, !!revisedTableHistory && !!revisedTableHistory.length))

                const referenceCurrencies = getDataListList(state, "ProjectCommittee-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_revisedTable", 'tKey', `${t("revisedTable")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_revisedTableHistory", 'tKey', `${t("revisedTableHistory")} (k${referenceCurrency.symbol})`))
                }
            },
            dataLists: [
                "ProjectCommittee-FiscalYear",
                "ProjectCommittee-Budget",
                "ProjectCommittee-RealizationType",
                "ProjectCommittee-Currency",
            ],
        }
    },
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ module, store }) => {
                const state = store.getState()
                const language = _.get(state, 'ui.language')

                const realizationTypes = getDataListList(state, "ProjectCommittee-RealizationType")
                const sortedTypes = _.sortBy(realizationTypes, ['order']);

                const revisedTableField = module.viewMap.form.fields.find(field => field.path === 'revisedTable')
                const revisedTable = !!revisedTableField && revisedTableField.getValue()
                const revisedTableHistoryField = module.viewMap.form.fields.find(field => field.path === 'revisedTableHistory')
                const revisedTableHistory = !!revisedTableHistoryField && revisedTableHistoryField.getValue()

                const filteredRevisedTable = _.cloneDeep(revisedTable).filter( line =>
                    line.estimated !== 0 ||
                    line.budget !== 0 ||
                    line.validated !== 0 ||
                    line.revised !== 0 ||
                    line.validatedEngagement !== 0 ||
                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                    line.ongoingFiscalYearEstimated !== 0 ||
                    line.ongoingFiscalYearBudget !== 0 ||
                    line.ongoingFiscalYearValidated !== 0 ||
                    line.ongoingFiscalYearRevised !== 0 ||
                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                )

                const filteredRevisedTableHistory = _.cloneDeep(revisedTableHistory).filter( line =>
                    line.estimated !== 0 ||
                    line.budget !== 0 ||
                    line.validated !== 0 ||
                    line.revised !== 0 ||
                    line.validatedEngagement !== 0 ||
                    line.ongoingFiscalYearValidatedEngagement !== 0 ||
                    line.ongoingFiscalYearEstimated !== 0 ||
                    line.ongoingFiscalYearBudget !== 0 ||
                    line.ongoingFiscalYearValidated !== 0 ||
                    line.ongoingFiscalYearRevised !== 0 ||
                    line.amountByRealizationType.some( obj => obj.amount !== 0)
                )

                const result = []
                const result2 = []
                if (!!filteredRevisedTable && !!filteredRevisedTable.length){

                    filteredRevisedTable.forEach(line => {
                        result.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                console.log('type', type.name, language, translateName(type.name, language))
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')
                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })

                    if (filteredRevisedTable.length > 1){
                        const dynamicAmounts = result.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})
                        const budget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const validatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const ongoingFiscalYearValidatedEngagement = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearBudget = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearEstimated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            estimated,
                            validated,
                            budget,
                            validatedEngagement,
                            revised,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }

                    result.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.budget = _.round(line.budget)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    revisedTableField.setValue(result)
                }
                if (!!filteredRevisedTableHistory && !!filteredRevisedTableHistory.length){
                    filteredRevisedTableHistory.forEach(line => {
                        result2.push({
                            ...line,
                            dynamicAmountsTypes : sortedTypes.reduce((acc, type) => {
                                const correspondentGlobalAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '1')
                                const correspondentYearlyAmountByType = line.amountByRealizationType.find( obj => obj.realizationType.id.toString() === type.id.toString() && obj.budgetColumnType.id === '2')
                                return {
                                    ...acc,
                                    ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: !!correspondentYearlyAmountByType? correspondentYearlyAmountByType.amount : 0,
                                    [translateName(type.code, language)]: !!correspondentGlobalAmountByType? correspondentGlobalAmountByType.amount : 0,
                                }
                            }, {})
                        })
                    })
                    if (filteredRevisedTableHistory.length > 1){
                        const dynamicAmounts = result2.map(obj => obj.amountByRealizationType).flat(1)
                        const dynamicAmountsTotal = sortedTypes.reduce((acc, type) => {
                            const filteredByRealizationType = dynamicAmounts.filter(obj => obj.realizationType.id.toString() === type.id.toString())
                            const groupedByColumnBudgetType = _.groupBy(filteredByRealizationType, 'budgetColumnType.id')
                            const ongoingFiscalYearAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["2"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            const globalAmount = !!filteredByRealizationType.length ? groupedByColumnBudgetType["1"].reduce( (acc, obj) => acc+obj.amount, 0) : 0
                            console.log("groupedByColumnBudgetType", groupedByColumnBudgetType)
                            return {
                                ...acc,
                                ['ongoingFiscalYear'+_.upperFirst(translateName(type.code, language))]: ongoingFiscalYearAmount,
                                [translateName(type.code, language)]: globalAmount
                            }
                        }, {})

                        const validatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validatedEngagement)), 0)
                        const budget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.budget)), 0)
                        const estimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.estimated)), 0)
                        const validated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.validated)), 0)
                        const revised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.revised)), 0)
                        const ongoingFiscalYearValidatedEngagement = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidatedEngagement)), 0)
                        const ongoingFiscalYearBudget = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearBudget)), 0)
                        const ongoingFiscalYearEstimated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearEstimated)), 0)
                        const ongoingFiscalYearValidated = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearValidated)), 0)
                        const ongoingFiscalYearRevised = result2.reduce( (acc, obj) => addFloats(acc, parseFloat(obj.ongoingFiscalYearRevised)), 0)
                        result2.push({
                            objet: 'Tot.',
                            entity: null,
                            imputationType: null,
                            //imputationTriplet: "Tot.",
                            estimated,
                            budget,
                            validated,
                            revised,
                            validatedEngagement,
                            ongoingFiscalYearValidatedEngagement,
                            ongoingFiscalYearBudget,
                            ongoingFiscalYearEstimated,
                            ongoingFiscalYearValidated,
                            ongoingFiscalYearRevised,
                            dynamicAmountsTypes: dynamicAmountsTotal,
                            updated: null,
                            user: null,
                            _disabled: true
                        })
                    }
                    result2.forEach(line => {
                        line.validatedEngagement = _.round(line.validatedEngagement)
                        line.budget = _.round(line.budget)
                        line.validated = _.round(line.validated)
                        line.estimated = _.round(line.estimated)
                        line.revised = _.round(line.revised)
                        line.ongoingFiscalYearValidatedEngagement = _.round(line.ongoingFiscalYearValidatedEngagement)
                        line.ongoingFiscalYearValidated = _.round(line.ongoingFiscalYearValidated)
                        line.ongoingFiscalYearBudget = _.round(line.ongoingFiscalYearBudget)
                        line.ongoingFiscalYearEstimated = _.round(line.ongoingFiscalYearEstimated)
                        line.ongoingFiscalYearRevised = _.round(line.ongoingFiscalYearRevised)
                        line.dynamicAmountsTypes = _.mapValues(line.dynamicAmountsTypes, value => _.round(parseFloat(value)))
                    })
                    revisedTableHistoryField.setValue(result2)
                }
                store.dispatch(processDynamicColumns({revisedTable: result, revisedTableHistory: result2}))
            }
        },
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const fiscalYearsList = getDataListList(state, 'ProjectCommittee-FiscalYear')
                const ongoingFiscalYear = fiscalYearsList.find( fiscalYear => moment().isBetween(fiscalYear.fiscalYearRange[0], fiscalYear.fiscalYearRange[1], 'day', '[]'))
                const tableIds = ["e_revisedTable", "e_revisedTableHistory"]
                tableIds.forEach(tableId => handleParentColumnsForTable(store, tableId, ongoingFiscalYear))
            }
        }
    ],
    accesses: [
        {
            id: "ProjectCommittee-FiscalYear",
            entity: "FiscalYear",
            fieldPath: ['id', 'code', 'fiscalYearRange'],
            filters: []
        },
        {
            id: "ProjectCommittee-Budget",
            entity: "Budget",
            fieldPath: ['id', 'estimatedBudgetFollowUp', 'mesh', 'analyticalMesh', 'budgetFollowUp'],
            filters: []
        },
        {
            id: "ProjectCommittee-RealizationType",
            entity: "RealizationType",
            fieldPath: ['id', 'code', 'name', 'order'],
            filters: ['onlyActifOnes']
        },
        {
            id: "ProjectCommittee-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters: ['inProjectCommittee']
}
