import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {
    changeFieldDefault,
    changeFieldDisabled, changeFieldProperty, FETCH_FORM_DATA_LISTS_DONE, setFieldEndDate, setFieldStartDate,
    setFieldVisibility,
    setFormButtons
} from "../../../../../apps/KpModule/actions";
import moment from "moment";
import {fiscalYearsMonth, monthRangeToList} from "../../utils/usefulFunctions";
import _ from "lodash";
import {
    delegateButton,
    draftButton,
    returnButton,
    transmitButton,
    validateDemandeurButton,
    warnAndValidateButton
} from "../../utils/demandUtils";
import {getDataListList} from "../../../../../apps/KpModule/selectors";

export const module_ = {
    object: 'Demand',
    name: 'DemandInstruction',
    category: {
        path: 'demande',
    },
    tKey: 'demandeInstruction',
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    viewMap: {
        dt: [
            {path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'demandNumber', tKey: 'N° demande'},
            {path: 'relatedDemand', tKey: 'demande principale', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'relatedProject', tKey: 'projet', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'title'},
            {
                path: 'organizationAndMesh',
                tKey: 'organisation',
                //display: 'fullName'
            },
            {path: 'startMonth', tKey: 'début'},
            {path: 'endMonth', tKey: 'fin'},
            {path: 'totalAmount'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true},

        ],
        form: {
            fields : [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                    //hidden: true,
                    //default: {id:'1'},
                    //display: 'name2'
                },
                {path: 'demandNumber',tKey: 'N° demande', disabled: true},
                {
                    path: 'relatedProject',
                    tKey: 'projet',
                    display: 'demandNumberAndTitle',
                    fieldPath: ['id', 'demandNumberAndTitle', 'organizationAndMesh', 'demandNumber', 'demandNature'],
                    filters: ['isProject', 'approvedOrInRealization', 'noRelatedProject', 'byOrganization'],
                    subscriptions : {
                        onChange (newValue, oldValue, {module, state, store}){
                            const objectMode = store.getState().ui.objectMode
                            const relatedDemandField = module.viewMap.form.fields.find(field=>field.path==='relatedDemand')
                            const relatedDemandIsSelected = !!relatedDemandField.getValue()

                            const organizationAndMeshField = module.viewMap.form.fields.find(field=>field.path==='organizationAndMesh')
                            const demandNatureField = module.viewMap.form.fields.find(field=>field.path==='demandNature')


                            console.log("newValue", newValue)
                            if (objectMode === 'newObject'){
                                if (newValue){
                                    organizationAndMeshField.setValue( {id: newValue.organizationAndMesh.id} )
                                    store.dispatch(changeFieldDisabled(organizationAndMeshField.id, true))
                                    demandNatureField.setValue( {id: newValue.demandNature.id, code: newValue.demandNature.code, name: newValue.demandNature.name} )
                                    store.dispatch(changeFieldDisabled(demandNatureField.id, true))
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Imputation_budget",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectNumber: newValue.demandNumber,//
                                                organizationID: organizationAndMeshField.getValue()?.id,//
                                                //organizationalMeshID: newValue.id
                                            }
                                        }
                                    ))
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Demand_relatedDemand",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectOrganizationID: newValue.organizationAndMesh.id,
                                                relatedProjectID: newValue.id
                                            }
                                        }
                                    ))
                                }
                                else{
                                    if (!relatedDemandIsSelected) {
                                        organizationAndMeshField.setValue(null)
                                        store.dispatch(changeFieldDisabled(organizationAndMeshField.id, false))
                                    }
                                    demandNatureField.setValue(null)
                                    store.dispatch(changeFieldDisabled(demandNatureField.id, false))
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Imputation_budget",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectNumber: null,//
                                                organizationID: organizationAndMeshField.getValue()?.id,//
                                                //organizationalMeshID: newValue.id,
                                            }
                                        }

                                    ))
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Demand_relatedDemand",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectOrganizationID: null,
                                                relatedProjectID: null
                                            }
                                        }
                                    ))
                                }
                            }
                        }
                    }
                },
                {
                    path: 'relatedDemand',
                    tKey: 'demande principale',
                    display: 'demandNumberAndTitle',
                    fieldPath: ['id', 'demandNumberAndTitle', 'organizationAndMesh'],
                    filters: ['isDemand', 'isApproved', 'noRelatedDemand', 'byOrganization', 'byRelatedProject'],
                    subscriptions : {
                        onChange (newValue, oldValue, {module, state, store}){
                            const objectMode = store.getState().ui.objectMode
                            const organizationAndMeshField = module.viewMap.form.fields.find(field=>field.path==='organizationAndMesh')
                            const relatedProjectField = module.viewMap.form.fields.find(field=>field.path==='relatedProject')
                            const relatedProjectIsSelected = !!relatedProjectField.getValue()
                            if ( objectMode === 'newObject'){
                                if (newValue){
                                    organizationAndMeshField.setValue( {id: newValue.organizationAndMesh.id} )
                                    store.dispatch(changeFieldDisabled(organizationAndMeshField.id, true))
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Demand_relatedProject",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectOrganizationID: newValue.organizationAndMesh.id
                                            }
                                        }
                                    ))
                                }
                                else{
                                    if (!relatedProjectIsSelected) {
                                        organizationAndMeshField.setValue(null)
                                        store.dispatch(changeFieldDisabled(organizationAndMeshField.id, false))
                                    }
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Demand_relatedProject",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectOrganizationID: null
                                            }
                                        }
                                    ))
                                }
                            }
                        }
                    }
                },
                {
                    path: 'organizationAndMesh',
                    tKey: 'organisation',
                    display: 'nameWithOrganizationalAxis',
                    //editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, {module, store}) => {
                            const objectMode = store.getState().ui.objectMode
                            const imputationsField = module.viewMap.form.fields.find(field=>field.path==='imputations')
                            const relatedDemandField = module.viewMap.form.fields.find(field=>field.path==='relatedDemand')
                            const relatedProjectField = module.viewMap.form.fields.find(field=>field.path==='relatedProject')
                            const relatedDemand = relatedDemandField.getValue()
                            const relatedProject = relatedProjectField.getValue()
                            const projectNumber = relatedProject || relatedDemand
                            if (objectMode === 'newObject'){
                                imputationsField.setValue([])
                                if (newValue) {
                                    store.dispatch(
                                        generateFetchFieldListAction(
                                            "m-SE-demandInstruction.Imputation_organizationalMesh",
                                            store.getState,
                                            'form',
                                            {
                                                data: {
                                                    organization : newValue?.id // newValue? newValue.organizationalAxis : null
                                                }
                                            }
                                        )
                                    )
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Imputation_budget",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectNumber: projectNumber?.demandNumber,
                                                organizationID: newValue.id,//
                                                //organizationalMeshID: newValue.id
                                            }
                                        }

                                    ))
                                }
                                else{
                                    store.dispatch(
                                        generateFetchFieldListAction(
                                            "m-SE-demandInstruction.Imputation_organizationalMesh",
                                            store.getState,
                                            'form',
                                            {
                                                data: {
                                                    organization : null // newValue? newValue.organizationalAxis : null
                                                }
                                            }
                                        )
                                    )
                                    store.dispatch(generateFetchFieldListAction(
                                        "m-SE-demandInstruction.Imputation_budget",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                projectNumber: projectNumber?.demandNumber,
                                                organizationID: null,//
                                                //organizationalMeshID: newValue.id
                                            }
                                        }

                                    ))
                                }
                            }
                        }
                    }
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    disabled: true,
                    fieldPath: ['id', 'name', 'category.id']
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    translateName: true,
                    fieldPath: ['id', 'code', 'name'],
                },
                {
                    path: 'title',
                    required: true,
                },
                {path: 'description', type: 'richTextEditor', required: true, },
                {path: 'enjeux', type: 'richTextEditor', required: true},
                {path: 'objectifs', type: 'richTextEditor', required: true},
                {
                    path: 'startMonth',
                    tKey: 'début',
                    required: true,
                    startDate: moment().format("YYYY-MM"),
                    editable: true,
                    //endDate: '',
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const state = store.getState()
                            const endMonthField = module.viewMap.form.fields.find(field => field.path === 'endMonth')
                            const endMonth = endMonthField.getValue()
                            if (newValue) {
                                store.dispatch(setFieldStartDate(endMonthField.id, newValue))
                                if ( !!endMonth && !moment(endMonth).isSameOrAfter(newValue)){
                                    endMonthField.setValue(null)
                                }
                            }
                        }
                    }
                },
                {
                    path: 'endMonth',
                    tKey: 'fin',
                    required: true,
                    editable: true,
                    startDate: moment().format("YYYY-MM"),
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const state = store.getState()
                            const nonClosedFiscalYears = getDataListList(
                                state,
                                "DemandInstruction-FiscalYears"
                            )
                            const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                            const startMonthField = module.viewMap.form.fields.find( field => field.path === 'startMonth')
                            const imputationsMonthAndAmountListField = imputationsField.viewMap.form.fields.find( field => field.path === 'monthAndAmountList')
                            if (newValue){
                                const startMonth = startMonthField.getValue()
                                const endMonth = newValue
                                const fiscalYearForThisDemand = nonClosedFiscalYears.filter( fiscalYear => {
                                    const fiscalYearStartDate = moment(fiscalYear.fiscalYearRange[0])
                                    const fiscalYearEndDate = moment(fiscalYear.fiscalYearRange[1])
                                    const firstCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                                    const secondCondition = fiscalYearStartDate.isSameOrAfter(startMonth, 'month') && fiscalYearEndDate.isSameOrBefore(endMonth, 'month')
                                    const thirdCondition = fiscalYearStartDate.isSameOrBefore(endMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                                    const fourthCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(startMonth, 'month')
                                    return firstCondition || secondCondition || thirdCondition || fourthCondition
                                })
                                fiscalYearForThisDemand.sort((a, b) => {
                                    const startA = moment(a.fiscalYearRange[0] );
                                    const startB = moment(b.fiscalYearRange[0]);
                                    // Compare the startDates
                                    if (startA.isBefore(startB)) {
                                        return -1; // a comes first
                                    } else if (startA.isAfter(startB)) {
                                        return 1; // b comes first
                                    } else {
                                        return 0; // a and b are the same
                                    }
                                });
                                const months = fiscalYearsMonth(fiscalYearForThisDemand, startMonth, endMonth)
                                const monthAndAmountList = months.length ? months.map( month=> {
                                    //const monthAndAmountList= _.get(state, 'edit.object.data.imputations.monthAndAmountList') || []
                                    //const previousMonthAndAmountList = getMonthAndAmountList(monthAndAmountList, role)
                                    return {"month" : month.month, "amount": 0, fiscalYear: {id: month.fiscalYearID}}
                                }) : []
                                store.dispatch(changeFieldDefault(imputationsMonthAndAmountListField.id, monthAndAmountList))
                            }
                        }
                    }
                },
                {
                    path: 'imputations',
                    tKey: 'plannedCommitment',
                    type: 'accordion',
                    viewMap: {
                        dt: [
                            'objet',
                            {path: 'organizationalMesh', tKey: 'business unit'},
                            {path: 'imputationType', translateName: true},
                            //'budget'
                            {path: 'currency', display: 'symbol'},
                            {path:  'totalAmountPerImputation', tKey: 'montant (k)'},
                        ],
                        form: {
                            fields : [
                                { path: 'objet', required: true, unique: true},
                                {
                                    path: 'budget',
                                    display: 'budgetDisplayName',
                                    required: true,
                                    filters: ['budgetForDemand', 'byOrganization', 'byOrganizationalMesh' ],
                                    fieldPath: ['id', 'budgetDisplayName', 'mesh'],
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){
                                            const imputationsField = module.viewMap.form.fields.find(field=>field.path==='imputations')
                                            console.log('imputationsField',newValue , newValue?.mesh?.id)
                                            const organizationalMeshField = imputationsField.viewMap.form.fields.find(field=>field.path==='organizationalMesh')
                                            if (newValue){
                                                organizationalMeshField.setValue({id: newValue.mesh.id})
                                            }
                                            else{
                                                organizationalMeshField.setValue(null)
                                            }
                                        }
                                    }
                                },
                                {
                                    path: 'organizationalMesh',
                                    tKey: 'business unit',
                                    filters:['byOrganizationOrMesh'],
                                    disabled: true,
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){
                                            const relatedDemandField = module.viewMap.form.fields.find(field=>field.path==='relatedDemand')
                                            const relatedProjectField = module.viewMap.form.fields.find(field=>field.path==='relatedProject')
                                            const relatedDemand = relatedDemandField.getValue()
                                            const relatedProject = relatedProjectField.getValue()
                                            const projectNumber = relatedProject || relatedDemand
                                            const organizationAndMeshField = module.viewMap.form.fields.find(field=>field.path==='organizationAndMesh')
                                            const organizationID = organizationAndMeshField.getValue()?.id
                                            if (newValue){
                                                store.dispatch(generateFetchFieldListAction(
                                                    "m-SE-demandInstruction.Imputation_budget",
                                                    store.getState,
                                                    'form',
                                                    {
                                                        data: {
                                                            projectNumber: projectNumber?.demandNumber,
                                                            organizationID: organizationID,
                                                            organizationalMeshID: newValue.id//
                                                        }
                                                    }
                                                ))
                                            }
                                            else{
                                                store.dispatch(generateFetchFieldListAction(
                                                    "m-SE-demandInstruction.Imputation_budget",
                                                    store.getState,
                                                    'form',
                                                    {
                                                        data: {
                                                            projectNumber: projectNumber?.demandNumber,
                                                            organizationID: organizationID,
                                                            organizationalMeshID: null//
                                                        }
                                                    }
                                                ))
                                            }
                                        }
                                    }
                                },
                                {path: 'imputationType', required: true, translateName: true},
                                {path: 'currency', display: 'symbol'},
                                {
                                    path: 'monthAndAmountList',
                                    tKey: 'montant mensuel',
                                    //default: [{month: '2022-11', amount: 0}],
                                    fieldPath: ['id', 'month', "amount", 'fiscalYear'],
                                    type: 'dtObjects',
                                    hidden: false,
                                    fields: [
                                        {path: "month"},
                                        {path: "amount", tKey: 'Montant (k)',type: "editText"},
                                        {path: 'fiscalYear', hidden: true}
                                    ],
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){
                                            if (newValue){
                                                let total = 0
                                                newValue.forEach(object => {
                                                    total+= parseFloat(object.amount)
                                                })
                                                const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                                                const totalAmountPerImputationField = imputationsField.viewMap.form.fields.find( field => field.path === 'totalAmountPerImputation')
                                                totalAmountPerImputationField.setValue(total)
                                            }
                                        }
                                    }
                                },
                                {
                                    path: 'totalAmountPerImputation',
                                    tKey: 'montant (k)',
                                    disabled: true,
                                    //default: 0,
                                },
                                { path: 'description', type: 'textarea'},
                            ]
                        }
                    },
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            if (newValue){
                                let total = 0
                                newValue.forEach(object => {
                                    total+= parseFloat(object.totalAmountPerImputation)
                                })
                                const totalAmountField = module.viewMap.form.fields.find( field => field.path === 'totalAmount')
                                totalAmountField.setValue(total)
                            }
                        }
                    }
                },
                {
                    path: 'totalAmount',
                    tKey: 'plannedTotalAmount',
                    disabled: true,
                },
                {
                    path: 'plafond',
                    tKey: "financialBracket",
                    fieldPath: ['id', 'code', "min", "max"],
                    hidden: false,
                    display: 'code',
                    //filters: ['byAmount']
                },
                {
                    path: 'comments',
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const commentsField = module.viewMap.form.fields.find(field=>field.path==='comments')
                            if ( newValue && oldValue && newValue.length !== oldValue.length ) {
                                let commentsCopy = _.cloneDeep(newValue)
                                const commentsSorted = commentsCopy.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss'))
                                const lastComment = commentsSorted[commentsSorted.length-1]
                                lastComment.date = moment().format("YYYY-MM-DD HH:mm:ss")
                                const comments = commentsSorted.slice(0, newValue.length - 1)
                                comments.push(lastComment)
                                commentsField.setValue(comments)
                            }
                        }
                    }
                },
                'files',
                {
                    path: 'delegateToCheckBox',
                    tKey: 'je souhaite déléguer ma décision',
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const delegateToField = module.viewMap.form.fields.find(field=>field.path==='delegateTo')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(delegateToField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    store.dispatch(setFormButtons([delegateButton, returnButton]))
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(delegateToField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }
                    }
                },
                {
                    path: 'delegateTo',
                    filters: [ 'isInGroupModel', 'isNotCurrentContributor' ],
                    clearable: false
                },
                {
                    path: 'currentContributors',
                    hidden: true
                },
                {
                    path: 'workflow',
                    //fieldPath:['step', 'order'],
                    hidden: true
                },
                {
                    path: 'contributorsFunctions',
                    //fieldPath: ['id'],
                    hidden: true,
                },
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {path: 'buttons', hidden: true, default: [draftButton, validateDemandeurButton, transmitButton, returnButton]},
                {path: 'arbitratorsFunctions', hidden: true},
                {path: 'followFunctions', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'largeImputationsOption',
                    hidden: true
                    //type: 'boolean',
                    //nullable: true
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {
                    path: 'delegationOption',
                    hidden: true,
                    required: false,
                },
                {
                    path: 'startFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'endFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'category',
                    hidden: true
                }
            ],
            dataLists: [
                "DemandInstruction-FiscalYears",
                "DemandInstruction-Currency",
            ],
            onOpen: ({ module, state, store, t }) => {
                const objectMode = state.ui.objectMode
                const demandNumberField = module.viewMap.form.fields.find( field => field.path === 'demandNumber')
                const delegationOptionField = module.viewMap.form.fields.find( field => field.path === 'delegationOption')
                const delegateToCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'delegateToCheckBox')
                const statusField = module.viewMap.form.fields.find( field => field.path === 'status')

                const hasNoDelegation = delegationOptionField.getValue() && delegationOptionField.getValue().id === "1"

                const startMonthField = module.viewMap.form.fields.find( field => field.path === 'startMonth')
                const endMonthField = module.viewMap.form.fields.find( field => field.path === 'endMonth')

                delegateToCheckBoxField.setValue(false)

                const organizationAndMeshField = module.viewMap.form.fields.find( field => field.path === 'organizationAndMesh')
                const contributorsFunctionsField = module.viewMap.form.fields.find( field => field.path === 'contributorsFunctions')
                const demandOrganization = organizationAndMeshField.getValue()
                const contributorsFunctions = contributorsFunctionsField.getValue()

                const workFlowField = module.viewMap.form.fields.find( field => field.path === 'workflow')
                const workflow = workFlowField.getValue()

                const plafondField = module.viewMap.form.fields.find( field => field.path === 'plafond')
                const demandNatureField = module.viewMap.form.fields.find( field => field.path === 'demandNature')
                const demandField = module.viewMap.form.fields.find( field => field.path === 'relatedDemand')
                const categoryField = module.viewMap.form.fields.find( field => field.path === 'demandCategory')

                const relatedProjectField = module.viewMap.form.fields.find( field => field.path === 'relatedProject')
                const relatedDemandField = module.viewMap.form.fields.find( field => field.path === 'relatedDemand')

                store.dispatch(setFieldVisibility(categoryField.id, false))

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-demandInstruction.Demand_delegateTo",
                        store.getState,
                        'form',
                        {
                            data: {
                                demandOrganization : demandOrganization,
                                contributorsFunctions : contributorsFunctions
                            }
                        }
                    )
                )

                const nonClosedFiscalYears = getDataListList(
                    state,
                    "DemandInstruction-FiscalYears"
                )
                if ( !!nonClosedFiscalYears && !!nonClosedFiscalYears.length){
                    const months = nonClosedFiscalYears.map( fiscalYear => moment(fiscalYear.fiscalYearRange[1]))
                    const endMonth = moment.max(months).add(1, 'months').format('YYYY-MM')
                    store.dispatch(setFieldEndDate(startMonthField.id, endMonth))
                    store.dispatch(setFieldEndDate(endMonthField.id, endMonth))
                }

                if ( objectMode === 'newObject' || (workflow && ["draft", "approved", "refused", "closed"].includes(workflow[0]) || hasNoDelegation)
                    //    || userIsInDelegateTo
                ) {
                    store.dispatch(setFieldVisibility(delegateToCheckBoxField.id, false))
                }
                else {
                    store.dispatch(setFieldVisibility(delegateToCheckBoxField.id, true))
                }

                store.dispatch(setFieldVisibility(statusField.id, objectMode !== 'newObject'))
                store.dispatch(setFieldVisibility(demandNumberField.id, objectMode !== 'newObject'))

                store.dispatch(changeFieldDisabled(plafondField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(organizationAndMeshField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(demandNatureField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(demandField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(relatedProjectField.id, objectMode !== 'newObject'))
                store.dispatch(changeFieldDisabled(relatedDemandField.id, objectMode !== 'newObject'))

                if(objectMode === 'newObject') {
                    const categories = getDataListList(
                        state,
                        'm-SE-demandInstruction.Demand_demandCategory'
                    )
                    console.log("categories", categories)
                    const demandCategory = categories.find(category => _.get(category, 'category.id') === '2')
                    categoryField.setValue(demandCategory)
                }

                const referenceCurrencies = getDataListList(state, "DemandInstruction-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_totalAmount", 'tKey', `${t("plannedTotalAmount")} (k${referenceCurrency.symbol})`))
                }
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({ store }) => {
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "DemandInstruction-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("l_totalAmount", 'tKey', `total (k${referenceCurrency.symbol})`))
                }
            }
        },
    ],
    accesses: [
        {
            id: "DemandInstruction-FiscalYears",
            entity: "FiscalYear",
            fieldPath: ['id', 'code', 'fiscalYearRange'],
            filters: ['nonClosed']
        },
        {
            id: "DemandInstruction-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters: ["byModuleStep"]
}
