import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './QuillTextEditor.css'
import ValidationUI from "../ValidationUI";

const Quill = ReactQuill.Quill
const Font = Quill.import('formats/font')
Font.whitelist = ['', 'Arial', 'Georgia', 'Helvetica', 'Tahoma', 'Times', 'Trebuchet', 'Verdana']
Quill.register(Font, true)

class Editor extends React.Component {
    constructor (props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange (html) {
        this.props.onChange(html)
    }

    render() {
        const { value, touched, error, path, required, t, readOnly, disabled, placeholder } = this.props

        if(readOnly) {
            return (
                <div className="ql-container ql-snow ql-disabled" style={{border: 'none'}}>
                    <div className="ql-editor" data-gramm="false" contentEditable="false">
                        <div dangerouslySetInnerHTML={{__html: value}} />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <label className="QuillTextEditor-label">
                    {t(path)} {required && "*"}
                </label>
                <div style={{padding: '5px'}}>
                    <ReactQuill
                        theme={'snow'}
                        readOnly={disabled}
                        onChange={this.handleChange}
                        value={value || ""}
                        modules={Editor.modules}
                        formats={Editor.formats}
                        placeholder={placeholder}
                    />
                </div>
                {touched === 'true' && (error && <ValidationUI error={error} />)}
            </div>
        )
    }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': Font.whitelist }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}, { 'align': [] }],
        ['link'
        //    , 'image'
        ],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background', 'list', 'bullet', 'indent', 'align',
    'link',
    //'image', 'video'
]

export default Editor
