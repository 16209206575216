const _ = require('lodash');
const { fieldPathJoinGetter } = require("../../../utils/kp3Utils");

export const businessProjectGetters = [
    {
        path: "expectedTurnOver",
        f: function () {
            return !this.financialStatement || this.financialStatement.turnOver.total === 0
                ? this.estimatedTurnover
                : this.financialStatement.turnOver.total;
        }
    },
    {
        path: "expectedContractCost",
        f: function () {
            return this.financialStatement
                ? this.financialStatement.chargeOnContract.total
                : 0;
        }
    },
    {
        path: "expectedContractMarginInPercent",
        f: function () {
            return this.financialStatement
                ? this.financialStatement.margeOnContractInPercent.total
                : 0;
        }
    },
    {
        path: "expectedNumberOfDays",
        f: function () {
            return this.financialStatement
                ? this.financialStatement.numberOfDays.total
                : 0;
        }
    },
    {
        path: "detectionTurnOver",
        f: function () {
            return this.financialStatementDetection
                ? this.financialStatementDetection.turnOver.total
                : 0;
        }
    },
    {
        path: "buildOfferTurnOver",
        f: function () {
            return this.financialStatementBuildOffer
                ? this.financialStatementBuildOffer.turnOver.total
                : 0;
        }
    },
    {
        path: "contractualizationTurnOver",
        f: function () {
            return this.financialStatementContractualization
                ? this.financialStatementContractualization.turnOver.total
                : 0;
        }
    },
    {
        path: "sumMarginOverContract",
        f: function () {
            return this.bpMarginOverContract ? this.bpMarginOverContract.total : 0;
        }
    },
    {
        path: "projectComponentsLength",
        fieldPath: ["projectComponents.id"],
        f: function () {
            return this.projectComponents ? this.projectComponents.length : 0;
        }
    },
    {
        path: "sumCommittedExpenses",
        fieldPath: ["projectComponents.id"],
        f: function () {
            return this.projectComponents ? _.reduce(this.projectComponents, (sum, o) => {
                return sum + o.cost
            }, 0) : 0
        }
    },
    {
        path: "sumCommittedNumberOfDays",
        fieldPath: ["projectComponents.id"],
        f: function () {
            return this.projectComponents ? _.reduce(this.projectComponents, (sum, o) => {
                return sum + o.numberOfDays
            }, 0) : 0
        }
    },
    {
        path: "red",
        fieldPath: ["projectComponents.id", "projectComponents.status.id"],
        f: function () {
            return this.projectComponents ? this.projectComponents.filter(o => o.status && o.status.id === "red").length : 0
        }
    },
    {
        path: "blue",
        fieldPath: ["projectComponents.id", "projectComponents.status.id"],
        f: function () {
            return this.projectComponents ? this.projectComponents.filter(o => o.status && o.status.id === "blue").length : 0
        }
    },
    {
        path: "green",
        fieldPath: ["projectComponents.id", "projectComponents.status.id"],
        f: function () {
            return this.projectComponents ? this.projectComponents.filter(o => o.status && o.status.id === 'green').length : 0
        }
    },
    {
        path: "actualMarginInPercent",
        fieldPath: ["sumActualTurnOver", "sumActualContractExpenses"],
        f: function () {
            return this.sumActualTurnOver !== 0 ? _.round(100 * (this.sumActualTurnOver - this.sumActualContractExpenses) / this.sumActualTurnOver, 2) : 0
        }
    },
    {
        path: "committedMarginInPercent",
        fieldPath: ["sumActualTurnOver", "sumCommittedExpenses"],
        f: function () {
            return this.sumActualTurnOver !== 0 ? _.round(100 * (this.sumActualTurnOver - this.sumCommittedExpenses) / this.sumActualTurnOver, 2) : 0
        }
    },
    {
        path: "sumActualTurnOver",
        fieldPath: ["executedFollowUps.id"],
        f: function () {
            return this.executedFollowUps ? _.reduce(this.executedFollowUps, (sum, o) => sum + o.executedTurnOver, 0) : 0
        }
    },
    {
        path: "sumActualContractExpenses",
        fieldPath: ["executedFollowUps.id"],
        f: function () {
            return this.executedFollowUps ? _.reduce(this.executedFollowUps, (sum, o) => sum + o.notedExpenses, 0) : 0
        }
    },
    {
        path: "sumActualNumberOfDays",
        fieldPath: ["executedFollowUps.id"],
        f: function () {
            return this.executedFollowUps ? _.reduce(this.executedFollowUps, (sum, o) => sum + o.notedNumberOfDays, 0) : 0
        }
    },
    {
        path: "sumActualBilled",
        fieldPath: ["billingFollowUps.id"],
        f: function () {
            return this.billingFollowUps ? _.reduce(this.billingFollowUps, (sum, o) => {
                return sum + (o.date ? o.amount : 0)
            }, 0) : 0
        }
    },
    {
        path: "sumActualCashed",
        fieldPath: ["billingFollowUps.id"],
        f: function () {
            return this.billingFollowUps ? _.reduce(this.billingFollowUps, (sum, o) => {
                return sum + (o.paymentdate ? o.amount : 0)
            }, 0) : 0
        }
    },
    {
        path: "billedMinusCommitted",
        fieldPath: ["sumActualBilled", "sumCommittedExpenses"],
        f: function () {
            return this.sumActualBilled - this.sumCommittedExpenses
        }
    },
    {
        path: "orderTakingMonth",
        fieldPath: ["businessRevues.id"],
        f: function () {
            return this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).orderMonth : ''
        }
    },
    {
        path: "businessRevueCurrentTurnOverP",
        fieldPath: ["signatureDate", "workflow", "workflow.step", "businessRevues.id"],
        f: function () {
            const revisedTurnOver = this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).revised : 0
            const probability = this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).probability : 0

            return this.signatureDate || (this.workflow && this.workflow.step === 'realisation')
                ? revisedTurnOver
                : (revisedTurnOver * probability) / 100
        }
    },
    {
        path: "businessRevueCurrentTurnOver",
        fieldPath: ["businessRevues.id"],
        f: function () {
            return this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).revised : 0
        }
    },
    {
        path: "businessRevueExecutedTurnOver",
        fieldPath: ["businessRevues.id"],
        f: function () {
            return this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).executedTurnOver : 0
        }
    },
    {
        path: "businessRevueCurrentComment",
        fieldPath: ["businessRevues.id"],
        f: function () {
            return this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).comment : 0
        }
    },
    {
        path: "businessRevueCurrentYearProbability",
        fieldPath: ["businessRevues.id", "businessRevues.yearP"],
        f: function () {
            return this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).yearP : 0
        }
    },
    {
        path: "businessRevueCurrentProbability",
        fieldPath: ["businessRevues.id"],
        f: function () {
            return this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).probability : 0
        }
    },
    {
        path: "businessRevueNextYearTurnover",
        fieldPath: ["businessRevues.id"],
        f: function () {
            return this.businessRevues && this.businessRevues.length > 0 ? _.last(_.orderBy(this.businessRevues, 'date')).yearNPlusOne : 0
        }
    },
    {
        path: "partnersName",
        fieldPath: ["partners.name"],
        f: function () {
            return this.partners ? this.partners.map(o => o.name).join() : ""
        }
    },
    {
        path: "subcontractorsName",
        fieldPath: ["subcontractors.name"],
        f: function () {
            return this.subcontractors ? this.subcontractors.map(o => o.name).join() : ""
        }
    },
    {
        path: "competitorsName",
        fieldPath: ["competitors.name"],
        f: function () {
            return this.competitors ? this.competitors.map(o => o.name).join() : ""
        }
    },
    {
        path: "developerPitches",
        fieldPath: ["developerPitch", "developerPitch2"],
        f: function () {
            return `<p><strong>Argumentaire Détection :</strong></p><br>${this.developerPitch}<br><p><strong>Argumentaire Montage :</strong></p><br>${this.developerPitch2}`
        }
    },
    fieldPathJoinGetter({
        path: "turnoverDifference",
        fieldPath: ["expectedTurnOver", "sumActualTurnOver"],
        joinString: " / "
    }),
    fieldPathJoinGetter({
        path: "expensesDifference",
        fieldPath: ["expectedContractCost", "sumActualContractExpenses"],
        joinString: " / "
    }),
    fieldPathJoinGetter({
        path: "numberOfDaysDifference",
        fieldPath: ["expectedNumberOfDays", "sumActualNumberOfDays"],
        joinString: " / "
    }),

    fieldPathJoinGetter({
        path: "expected",
        fieldPath: ["expectedTurnOver", "expectedContractCost", "expectedNumberOfDays"],
        joinString: " / "
    }),

    fieldPathJoinGetter({
        path: "identifier",
        fieldPath: ["code", "country.name", "typeOfOffer.name", "customer.name"],
        joinString: " / "
    }),

    fieldPathJoinGetter({
        path: "fullName",
        fieldPath: ["code", "name"]
    }),

    fieldPathJoinGetter({
        path: "countryAndFullName",
        fieldPath: ["country.name", "code", "name"]
    }),

    fieldPathJoinGetter({
        path: "beginYearAndProject",
        fieldPath: ["beginYear.id", "name"]
    })
]
