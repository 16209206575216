import {setFieldVisibility} from "../../../../apps/KpModule/actions";
import _ from "lodash";
import {basicContext} from "../../../utils/contextUtils";
import {generateAnalysis} from "../../crossModels/utils/surveyUtils";
import {translateName} from "../../../../utils/i18n";
import moment from "moment/moment";


async function getAmountsByMonth(context) {
    const stackOption = _.get(context, 'data.stackOption')
    const organizationsAndMeshes = _.get(context, 'data.organizationsAndMeshes')
    const selectedOrganizationsAndMeshesIDs = !!organizationsAndMeshes && !!organizationsAndMeshes.length ?
        organizationsAndMeshes.map( obj => obj.id)
        : []
    console.log('organizationOrMeshIDs', organizationsAndMeshes)
    const projects = _.get(context, 'data.projects')
    const selectedProjectsIDs = !!projects && !!projects.length ?
        projects.map( obj => obj.id)
        : []
    const projectQuery = !!selectedProjectsIDs && !!selectedProjectsIDs.length ?
        {"project": {$in: selectedProjectsIDs.map(id => global.ObjectID(id))}}
        : {}
    const imputationTypes = _.get(context, 'data.imputationTypes')
    const selectedImputationTypesIDs = !!imputationTypes && !!imputationTypes.length ?
        imputationTypes.map( obj => obj.id)
        : []

    const allRevisedLines = await global.app.SE.RevisedTable.find({
        ...basicContext(context),
        fieldPath: ['entity.id', 'imputationType.id'],
        query: {}
    })

    const linesFilteredByImputationTypes = allRevisedLines.filter(line => selectedImputationTypesIDs.includes(line.imputationType.id))

    const linesFilteredByOrganizationsAndMeshes = allRevisedLines.filter(line =>
        selectedOrganizationsAndMeshesIDs.includes(line.entity.id) || line.entity.attachments.some(att => selectedOrganizationsAndMeshesIDs.map(id=>id.toString()).includes(att.id).toString())
    )
    const imputationTypeQuery = !!selectedImputationTypesIDs && !!selectedImputationTypesIDs.length ?
        {"revisedLine": {$in: linesFilteredByImputationTypes.map(obj => global.ObjectID(obj.id))}}
        : {}
    const organizationsAndMeshesQuery = !!selectedOrganizationsAndMeshesIDs && !!selectedOrganizationsAndMeshesIDs.length ?
        {"revisedLine": {$in: linesFilteredByOrganizationsAndMeshes.map(obj => global.ObjectID(obj.id))}}
        : {}
    const amountsByMonth = await global.app.SE.TrackingByMonth.find({
        ...basicContext(context),
        fieldPath: ['month', 'validated', 'project.demandNumberAndTitle', 'project.organizationAndMesh.name', 'revisedLine.imputationType.id', 'revisedLine.entity.nameWithOrganizationalAxis'],
        query: {
            ...projectQuery,
            ...imputationTypeQuery,
            ...organizationsAndMeshesQuery
        }
    })

    const groupedByMonth = _.groupBy(amountsByMonth, 'project.organizationAndMesh.name');

    const result = _.map(groupedByMonth, (items, month) => {

        const groupedByProject = _.groupBy(items, stackOption.path);

        const validatedByProject = _.map(groupedByProject, (projectItems, project) => {
            const totalValidated = _.sumBy(projectItems, 'validated'); // Sum the 'price' for each month within the product group

            return {
                stackAxis: stackOption.translateName ? translateName(project, _.get(context, "language.id")) : project,
                validated: totalValidated,
            };
        });

        return {
            id: new global.ObjectID(),
            date: month,
            validatedByProject: validatedByProject.reduce((acc, object) => {
                return {
                    ...acc,
                    [object.stackAxis]: object.validated
                }
            },{}),
            totalValidated: validatedByProject.reduce((acc, object) => acc+object.validated, 0),
            noDeleteButtonAccess: true
        };
    });

    return result.reverse()
}
export const entities = [
    {
        name: 'AnalyzeTrackingByMonth',
        fields: [
            //{path: 'project', type: 'Demand'},
            //{path: 'revisedLine', type: 'RevisedTable'},
            /*
            {
                path:'imputationType',
                fieldPath: ['revisedLine.imputationType.name'],
                $f: (object, context, callback) => {
                    callback(null, translateName(object.revisedLine.imputationType.name,  _.get(context, "language.id")))
                },
            },

             */
            //{path: 'budget'},
            //{path: 'currency', type: 'Currency'},
            {path: 'date'},
            //{path: 'validatedEngagement', type: 'decimal'},
            //{path: 'budget', type: 'decimal'},
            //{path: 'estimated', type: 'decimal'},

            {path: 'validatedByProject', list: true},
            {
                path: 'totalValidated'
            },
            //{path: 'revised', type: 'decimal'},
            /*
            {
                path: 'amountByRealizationType',
                type: 'AmountByRealizationType',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false,
                }
            },
             */
            //'FiscalYear'
        ],
        find: function(context, callback) {
            this.prepareContext(context, 'L', (error, context) => {
                if (error) callback(error)
                else getAmountsByMonth(context)
                    .then(objects => callback(null, objects))
                    .catch(e => callback(e))
            })
        },
        filters: [
            {  //verified
                name: 'byProject',
                tKey: 'project(s)',
                type: 'tags',
                isDefault: false,
                client: true,
                path: 'projects',
                object: 'Demand',
                filters:[
                    'isProject',
                    {
                        name: "byProjectStatus",
                        tKey: 'statut',
                        isDefault: false,
                        //autoFetch: false,
                        client: true,
                        path: 'projectStatuses',
                        object: 'DemandStatus',
                        display: 'name2',
                        clearable: true,
                        fieldPath: ['name', 'name2'],
                        type: 'tags',
                        default: [{id: '7'}],
                        query: function (context) {
                            const selectedStatuses = _.get(context, 'data.projectStatuses') || []
                            let auxiliaryQuery = !!selectedStatuses.length ? { 'status' : { $in: selectedStatuses.map( status => status.id )} } : {}
                            return auxiliaryQuery
                        }
                    },
                ],
                display: 'demandNumberAndTitle',
                clearable: true,
                default: []
            },
            {  //verified
                name: 'byImputationType',
                tKey: 'imputationType',
                type: 'tags',
                isDefault: false,
                client: true,
                path: 'imputationTypes',
                object: 'ImputationType',
                display: 'name',
                translateName: true,
                clearable: true,
                default: []
            },
            {  //verified
                name: 'byOrganizationAndMesh',
                type: 'tags',
                tKey: 'organizationAndMesh',
                isDefault: false,
                client: true,
                path: 'organizationsAndMeshes',
                object: 'OrganizationAndMesh',
                display: 'fullName',
                clearable: true,
                default: []
            },
            {  //verified
                name: 'stackedBy',
                tKey: 'stackOption',
                isDefault: false,
                client: true,
                path: 'stackOption',
                object: 'StackOptions',
                translate: true,
                display: 'name',
                fieldPath: ['path', 'translateName'],
                clearable: false,
                default: {id: '1'}
            },
            {  //verified
                name: 'byAnalysisOption',
                tKey: 'analysis',
                isDefault: false,
                client: true,
                path: 'analysisOption',
                object: 'AnalysisOption',
                translate: true,
                display: 'name',
                default: {id: '1'},
                //fieldPath: ['path', 'translateName'],
                clearable: true,
            },
            {  //verified
                name: 'byFiscalYear',
                tKey: 'fiscalYear',
                type: 'tags',
                isDefault: false,
                client: true,
                path: 'fiscalYear',
                object: 'FiscalYear',
                display: 'code',
                clearable: true,
                default: []
            },
            {
                name: "displayedAmounts",
                tKey: 'display',
                type: 'tags',
                isDefault: false,
                client: true,
                translate: true,
                path: 'displayedAmounts',
                object: 'DisplayedAmount',
                display: 'name',
                clearable: true,
                default: [{id: '3'}]
            }
        ]
    },
    {
    name: 'TrackingByMonth',
    // for each demand, each month we should have one line that have the monthly and the accumulated total amount by type
    //types : validated, estimated, revised and realizationTypes
    fields: [
        {path: 'project', type: 'Demand'},
        {path: 'revisedLine', type: 'RevisedTable'},
        /*
        {
            path:'imputationType',
            fieldPath: ['revisedLine.imputationType.name'],
            $f: (object, context, callback) => {
                callback(null, translateName(object.revisedLine.imputationType.name,  _.get(context, "language.id")))
            },
        },

         */
        //{path: 'budget'},
        //{path: 'currency', type: 'Currency'},
        {path: 'month', type: 'month'},
        //{path: 'validatedEngagement', type: 'decimal'},
        //{path: 'budget', type: 'decimal'},
        //{path: 'estimated', type: 'decimal'},
        {path: 'validated', type: 'decimal'},
        //{path: 'revised', type: 'decimal'},
        /*
        {
            path: 'amountByRealizationType',
            type: 'AmountByRealizationType',
            link: {
                type: "OTM",
                onParent: true,
                onChild: false,
            }æ
        },
         */
        //'FiscalYear'
    ]
}]


export const module_ = {
    object: 'AnalyzeTrackingByMonth',
    category: {
        path: `tracking`,
    },
    newable: false,
    //defaultPanel: 'chart',
    chart: {
        keys: [
            {path: 'validatedByProject', dynamic: true}
        ],
        type: "barChart",
        title: ``
    },
    viewMap: {
        dt: [
            //{path: 'project', display: 'demandNumberAndTitle'},
            //{path: 'revisedLine', tKey: 'triplet', display: 'triplet'},
            //'imputationType',
            //{path: 'revisedLine', display: 'entity'},
            //{path: 'revisedLine', display: 'imputationType'},
            {path: 'date', tKey: 'month', width: 30},
            {path: 'totalValidated', width: 20},
            {path: 'validatedByProject', dynamic: true},
            //{path: 'validatedByProject', tKey: 'project(s)', display: 'stackAxis', type: 'objectArray', width: 700},
            {path: 'noDeleteButtonAccess', hidden: true},
        ],
        chart: ['date', 'validatedByProject']
    },
    filters: [
        'byProject', 'byProjectStatus',
        'byOrganizationAndMesh', 'byImputationType',
        'displayedAmounts', 'stackedBy',
        'byFiscalYear', 'byAnalysisOption'
    ]
}