import {basicContext} from "../../../../utils/contextUtils";

const {fieldPathJoinGetter} = require( "../../../../utils/kp3Utils")
const _ = require('lodash')


export const entities = [
    {
        name: "FollowUpByMonth",
        type: "mongoInternal",
        fields: [
            {path: "projectQuadruplet"},
            {path: 'month'},
            {path: "budget", type: 'decimal' },
            {path: "validated", type: 'decimal'},
            {path: "estimated", type: 'decimal'},
            {path: "revised", type: 'decimal'},
            {path: "engaged", type: 'decimal'},
            {path: "ongoing", type: 'decimal'},
        ]
    },
    {
        name: "FollowTable",
        type: "mongoInternal",
        //il cree les champs dans le composant père, pour eviter la création des collections pour cette entité
        fields: [
            //en se basant sur les steps
            {path: 'analyticalSubAxis', type: 'AnalyticalSubAxis'},
            {path: 'analyticalMesh', type: 'AnalyticalMesh'},
            {path: 'entity', type: 'OrganizationalMesh'},
            {path: 'imputationType', type: 'ImputationType'},
            {
                path: "projectQuadruplet",
            },
            //identifier: `${code} - ${budget.analyticalMesh.code} - ${budget.mesh.code} - ${typeObject.code}`,
            {path: "followUpByMonth", type: "FollowUpByMonth", link: {type: "OTM"}},
            {path: "budget", type: "decimal"},
            {path: "validated", type: "decimal"},
            {path: "estimated", type: "decimal"},
            {path: "revised", type: "decimal"},
            {path: "engaged", type: "decimal"},
            {path: "ongoing", type: "decimal"},
        ]
    },
    {
        name: "FollowUpByMonth2",
        type: "mongoInternal",
        fields: [
            {path: "imputationTriplet"},
            {path: 'month'},
            {path: "estimated", type: 'decimal'},
            {path: "validated", type: 'decimal'},
            {path: "revised", type: 'decimal'},
        ]
    },
    {
        name: "FollowTable2",
        type: "mongoInternal",
        //il cree les champs dans le composant père, pour eviter la création des collections pour cette entité
        fields: [
            {path: 'correspondentImputation', type: 'Imputation'}, // display
            {path: "followUpByMonth", type: "FollowUpByMonth2", link: {type: "OTM"}},
        ]
    },
    {
        name: 'ArbitrateTextList',
        type: 'mongoInternal',
        fields: [
            {
                path: 'user',
                type:'User'
            },
            'step',
            'order',
            "ArbitrationType",
            {path: 'motif'},
            {
                path: "date",
                type: 'date',
                dateFormat: 'YYYY-MM-DD HH:mm'
            },
        ]
    },
    {
        name: 'AnalyticalSubAxis',
        facets: [
            {name: 'codeName', nameOptional: false, uniqueName: false, uniqueCode: true}
        ],
        fields: [
            { path: 'analyticalAxis', type:'AnalyticalAxis', link:'MTO'},
            fieldPathJoinGetter({
                path: 'nameWithAnalyticalAxis',
                fieldPath: ['analyticalAxis.name', 'name'],
                joinString: ' : '
            }),
            /*
            {
                path: 'attachedDemand',
                type: 'Demand',
                $f: function(object, context, callback) {
                    global.app.SE.Demand.get({demandNumber: object.code},
                        {
                            ...basicContext(context),
                            fieldPath: ['id', 'status', 'imputations.id', 'imputations.monthAndAmountList', 'startFiscalYear', 'endFiscalYear'],
                        },(e, demand) => {
                            return callback(null, demand)
                        }
                    )
                }
            },
            {
                path: 'totalBudget',
                type: "decimal",
                fieldPath: ['organizationalAxis.name', 'name'],
                $f: function(object, context, callback) {
                    global.app.SE.AnalyticalMesh.find(
                        {
                            group: global.ObjectID(context.group.id),
                            fieldPath: [],
                            query: {
                                attachment : {$elemMatch: {$eq :global.ObjectID(object.id)}},
                            },
                        }, (error, analyticalMeshes) => {
                            if (error) return callback(error)
                            if ( !!analyticalMeshes.length ){
                                const analyticalMeshesIds = analyticalMeshes.map( mesh => global.ObjectID(mesh.id))
                                global.app.SE.Budget.find(
                                    {
                                        group: global.ObjectID(context.group.id),
                                        fieldPath: ['budgetFollowUp.id', 'budgetFollowUp.amount'],
                                        query: {
                                            analyticalMesh : {$in:analyticalMeshesIds},
                                        },
                                    }, (error, budgets) => {
                                        if (error) return callback(error)
                                        if ( !!budgets.length ){
                                            const totalBudget = budgets.map( budget => budget.budgetFollowUp).flat(1).reduce((acc, object) => acc + object.amount, 0)
                                            return callback(null, totalBudget)
                                        }
                                        else{
                                            return callback(null, 0)
                                        }
                                    }
                                )
                            }
                            else{
                                return callback(null, 0)
                            }
                        }
                    )
                }
            },
            {
                path: 'totalEstimated',
                type: "decimal",
                f: function(){
                    return this.followTable && !!this.followTable.length
                        ? this.followTable.reduce((acc, row) => acc + row.estimated, 0)
                        : 0
                }
            },
            {
                path: 'totalValidated',
                type: "decimal",
                fieldPath: ['attachedDemand.status', 'attachedDemand.imputations'],
                f: function(){
                    if(!!this.attachedDemand?.imputations?.length  && ["6", "7", "8"].includes(this.attachedDemand.status.id) ) {
                        return this.attachedDemand.imputations.reduce((acc, imputation) => {
                            return acc + parseFloat(imputation.totalAmountPerImputation)
                        }, 0)
                    }
                    return 0
                }
            },
            {
                path: 'totalRevised',
                type: "decimal",
                f: function(){
                    return this.followTable && !!this.followTable.length
                        ? this.followTable.reduce((acc, row) => acc + row.revised, 0)
                        : 0
                }
            },
            {
                path: 'totalEngaged',
                type: "decimal",
                f: function(){
                    return this.followTable && !!this.followTable.length
                        ? this.followTable.reduce((acc, row) => acc + row.engaged, 0)
                        : 0
                }
            },
            {
                path: 'totalOngoing',
                type: "decimal",
                f: function() {
                    return this.followTable && !!this.followTable.length
                        ? this.followTable.reduce((acc, row) => acc + row.ongoing, 0)
                        : 0
                }
            },
            {
                path: 'followTable',
                type: 'FollowTable',
                lazy: true,
                fieldPath: [
                ],
                list: true,
            },

             */
        ],
        filters: [
            {
                name: "analyticalAxisIsProjectAxis",
                isDefault: false,
                async: true,
                query: function(context, callback) {
                    global.app.SE.AnalyticalAxis.find(
                        {
                            ...basicContext(context),
                            fieldPath: [],
                            query: {
                                isProjectAxis: true
                            }
                        },(e, axes) => {
                            if (e) return callback(e)
                            if (!!axes.length ){
                                const axesInBudgetAxes = axes.map( axes => global.ObjectID(axes.id))
                                callback(e, {"analyticalAxis": {$in: axesInBudgetAxes}})
                            }
                            else{
                                callback(e, {_id: null})
                            }
                        }
                    )
                }
            },
            {
                name: 'byAnalyticalAxis',
                tKey: 'analyticalAxis',
                isDefault: false,
                client: true,
                path: 'analyticalAxis',
                object: 'AnalyticalAxis',
                display: 'name',
                clearable: true,
                query: function(context) {
                    const analyticalAxisID = _.get(context, 'data.analyticalAxis.id')
                    return analyticalAxisID ? {"analyticalAxis": global.ObjectID(analyticalAxisID)} : {}
                }
            },
            /*
            {
                name: 'followUpProjects',
                isDefault: false,
                objectFieldPath: ['attachedDemand.status'],
                match: object => {
                    const statusId = _.get(object, 'attachedDemand.status.id')
                    return !object.attachedDemand || ["6", "7", "8"].includes(statusId)
                }
            },
             */
        ]
    }
]
export const module_ = {
    object: 'AnalyticalSubAxis',
    category: {
        path: 'referencial',
        icon: 'briefcase'
    },
    tKey: 'analyticalSubAxis',
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            'code',
            {path: 'name', type: 'translatedText'}
        ],
        form: [
            {path: 'analyticalAxis', display: 'name', editable: false},
            {path: 'code', editable: false},
            {path: 'name', type: 'textarea', unique : true},
            //{path: 'attachedDemand', hidden: true}
        ],
    },
    filters: ['byAnalyticalAxis']
}
