export const entity = {
    name: 'FiscalYear',
    fields: [
        {path: 'code', unique: true},
        {path: 'fiscalYearRange', type: 'dateRange'},
        {path: 'fiscalYearStatus', type: 'FiscalYearStatus', fieldPath:["id"]},
    ],
    filters:[
        {
            name: "onlyOngoing",
            isDefault: false,
            query: function() {
                return {"fiscalYearStatus": "1"}
            }
        },
        {
            name: "nonClosed",
            isDefault: false,
            query: function () {
                return {"fiscalYearStatus": {$in: ["1", "3"]}}
            }
        },
        {
            name: "ongoingAndClosed",
            isDefault: false,
            query: function () {
                return {"fiscalYearStatus": {$in: ["1", "2"]}}
            }
        }
    ]
}

export const module_ = {
    object: 'FiscalYear',
    category: {
        path: 'paramétrage',
        icon: 'settings'
    },
    tKey: 'exercice',
    viewMap: {
        dt: [
            'code',
            {path: 'fiscalYearRange'},
            {path: 'fiscalYearStatus', tKey: 'status'}
        ],
        form: {
            fields: [
                {path: 'code', required: true},
                {path: 'fiscalYearRange', tKey: "Plage de l'exercice", required: true},
                {path: 'fiscalYearStatus', tKey: 'status', type: 'toggle', default: {id: '1'} },
            ],
        }
    }
}
