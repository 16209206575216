import _ from "lodash";
import moment from "moment";

export const entities = [
    {
        name: "Status",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Actif"},
            {id:'2', name: "Inactif"},
        ]
    },
    {
        name: "Nature",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "référence"},
            {id:'2', name: "étrangère"},
        ]
    },
    {
        name: 'FiscalYearStatus',
        type: 'static',
        fields: [
            { path: 'id', type: 'string' },
            'name'
        ],
        objects: [
            { id: '1', name: 'en cours' },
            { id: '2', name: 'clôturé' },
            { id: '3', name: 'ouverture provisoire' }
        ]
    },
    {
        name: "Mesh",
        type: "static",
        fields: [
            //"mongoOperator",
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'000000000000000000000001', name: "business unit"},  //entités
            {id:'000000000000000000000002', name: "Groupe"},
        ]
    },
    {
        name: "DemandStatus",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
            'name2',
        ],
        objects: [
            {id:'1', name: "draft", name2: "Brouillon" },
            {id:'2', name: "control", name2: "Contrôle"},
            {id:'3', name: "validation", name2: "Validation"},
            {id:'4', name: 'information request', name2: `Demande d'information`},
            {id:'5', name: "refused", name2: "Refusées"},
            {id:'6', name: "approved", name2: "Validées"},
            {id:'7', name: "realization", name2: "Réalisation"},
            {id:'8', name: "closed", name2: "Terminées"},
            {id:'9', name: "abandoned", name2: "Abandonnées"},
            {id:'10',name: "demand", name2: "Instruction" },
            {id:'11', name: "arbitration", name2: "Arbitrage"},
            {id:'12', name: "preValidation", name2: "Pré-validation"},
        ]
    },
    {
        name: "DemandStep",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
            'name2',
        ],
        objects: [
            {id:'1', name: "demand", name2: "Instruction" },
            {id:'2', name: "control", name2: "Contrôle"},
            {id:'3', name: "validation", name2: "Validation"},
            {id:'4', name: 'informationRequest', name2: `Demande d'information`},
            {id:'5', name: "approved", name2: "Validé"},
            {id:'6', name: "realization", name2: "Réalisation"},
            {id:'7', name: "closed", name2: "Terminé"},
            //{id:'8', name: "my actions", name2: "Mes actions"},
            {id:'9', name: "abandoned", name2: "Abandonné"},
            {id:'10', name: "draft", name2: "Mes brouillons" },
            {id:'11', name: "arbitration", name2: "Arbitrage"},
            {id:'12', name: "refused", name2: "Refusé"},
            {id:'13', name: "preValidation", name2: "Pré-validation"},
        ]
    },
    {
        name: "DemandStep2",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
            'name2',
        ],
        objects: [
            {id:'8', name: "my actions", name2: "Mes actions"},
            {id:'10', name: "draft", name2: "Mes brouillons" },
            {id:'4', name: 'information request', name2: `Demande d'information`},
            {id:'11', name: "arbitration", name2: "Arbitrage"},
            {id:'12', name: "preValidation", name2: "pré-validation"},
        ]
    },
    {
        name: "InstructionModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'instruction', name: "Instruction" }
        ]
    },
    {
        name: "ControlModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'control', name: "control" }
        ]
    },
    {
        name: "ValidationModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'validation', name: "Validation" }
        ]
    },
    {
        name: "RealizationModuleFilter",
        type: "static",
        fields: [
            {path:"id", type:'string'},
            'name',
        ],
        objects: [
            {id:'myActions', name: "Mes actions"},
            {id:'realization', name: "realization" }
        ]
    },
    {
        name: "Category",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "projet"},
            {id:'2', name: "engagement"},
            //{id:'3', name: "3"}
        ]
    },
    {
        name: "ArbitrationType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Arbitrage multiple"},
            {id:'2', name: "Arbitrage final"}
        ]
    },
    {
        name: "DelegationOption",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Sans délégation"},
            {id:'2', name: "Avec délégation"}
        ]
    },
    {
        name: "FiscalYearFilter",
        type: "static",
        fields: [
            {path: "id", type: "integer"},
            {path: "name"}
        ],
        objects: _.range(moment().year() - 3, moment().year() + 4).map(
            year => ({id: year, name: `${year}`})
        )
    },
    {
        name: "BudgetColumnType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "Global"},
            {id:'2', name: "Yearly"},
            // names should be unique !!!
        ]
    },
    {
        name: "StackOptions",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name', 'path', {path: 'translateName', type: 'boolean'}
        ],
        objects: [
            {id:'1', name: "project", path: 'project.demandNumberAndTitle', translateName: false},
            {id:'2', name: "organizationAndMesh", path: 'revisedLine.entity.nameWithOrganizationalAxis', translateName: false},
            {id:'3', name: "imputationType", path: 'revisedLine.imputationType.name', translateName: true},
            {id:'4', name: "noStacking", path: '', translateName: false},
        ]
    },
    {
        name: "AnalysisOption",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name'
        ],
        objects: [
            {id:'1', name: "temporalMonthly"},
            {id:'2', name: "temporalYearly"},
            {id:'3', name: "organizational"},
            {id:'4', name: "imputationType"},
        ]
    },
    {
        name: "DisplayedAmount",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name'
        ],
        objects: [
            {id:'1', name: "budget"},
            {id:'2', name: "estimated"},
            {id:'3', name: "projectValidated"},
            {id:'4', name: "revised"},
            {id:'5', name: "demandValidated"},
            {id:'6', name: "realizationTypes"},
        ]
    },

    /*
    {
        name: "PaymentType",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "engaged"},
            {id:'2', name: "received"},
            {id:'3', name: "billed"},
        ]
    },

     */
    /*
    {
        name: "revisedTableColumn",
        type: "static",
        fields: [
            {path: "id", type: "string"},
            'name',
        ],
        objects: [
            {id:'1', name: "engaged"},
            {id:'2', name: "received"},
            {id:'3', name: "billed"},
        ]
    },

     */
]
