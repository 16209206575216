const crypto = require('crypto')

const algorithm = 'aes-256-ctr'
const iv = 'AruFKaX9N2xunQnK'
const key = '3Cu6FkB3Tp9mkMunu48v8paKCV4XTAXJ'

export function decrypt(text) {
    const decipher = crypto.createDecipheriv(algorithm, key, iv)
    return decipher.update(text, 'hex', 'utf8') + decipher.final('utf8')
}

export function encrypt(text) {
    const cipher = crypto.createCipheriv(algorithm, key, iv)
    return cipher.update(text, 'utf8', 'hex') + cipher.final('hex')
}
