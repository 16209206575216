const _ = require('lodash')
/* standard form
const workflowConfig = {
    steps: [
        {
            name: "intervention",
            actions: [
                { name: "validate", nextStep: "validated" },
                { name: "refuse", nextStep: "refused" }

            ]
        },
        { name: "validated" },
        { name: "refused" }
    ],
    profiles: [
        {
            name: "validator",
            stepProfiles: [
                { step: "intervention", active: true },
                { step: "validated", active: false },
                { step: "refused", active: false }
            ]
        },
        {
            name: "controller",
            stepProfiles: [
                { step: "validated", active: false },
                { step: "refused", active: false }
            ]
        }
    ]
}
* */

// inspect the data entry match the form we expect.
function validateConfigShape(config) {
    // read some property as we need
    const { steps, profiles } = config

    // check steps whether is an array or not.
    if (!Array.isArray(steps)) {
        throw new Error(
            'The config should have a property "steps" of type string'
        )
    }

    // check profiles whether is an array or not
    if (!Array.isArray(profiles)) {
        throw new Error(
            'The config should have a property "profiles" of type string'
        )
    }

    // regroup the name of the step as a new array
    const stepNames = steps.map(step => step.name)

    steps.forEach(step => {
        // check the type of step name
        if (!_.isString(step.name)) {
            throw new Error(
                'Each step should have a property "name" of type string'
            )
        }

        if (step.actions) {
            // if step is valid, we get it's 'actions' to execute following function
            if (!Array.isArray(step.actions)) {
                throw new Error(
                    'The property "actions" of a step should be an array'
                )
            }

            // check each element of the action are included in array stepNames
            step.actions.forEach(action => {
                if (!stepNames.includes(action.nextStep)) {
                    throw new Error(
                        'The nextStep property should correspond to a step name'
                    )
                }
            })
        }
    })

    profiles.forEach(profile => {
        if (!_.isString(profile.name)) {
            throw new Error(
                'Each profile should have a property "name" of type string'
            )
        }
        if (!Array.isArray(profile.stepProfiles)) {
            throw new Error(
                'Each profile should have property "stepProfiles" of type array'
            )
        } else {
            const everyStepExist = profile.stepProfiles.every(stepProfile =>
                stepNames.includes(stepProfile.step)
            )
            if (!everyStepExist) {
                throw new Error(
                    'Each stepProfiles of step should have a property "step" corresponding to a stepName'
                )
            }
        }
    })

    const firstStepActions = steps[0].actions
    if (!firstStepActions || firstStepActions.length === 0) {
        throw new Error('The first step should have at least one action')
    }
}

/***
 * workflow object
 * {
 *     step: step3,
 *     order: 0,
 *     maxOrders: {
 *         step1: 3,
 *         step2: 2
 *     }
 * }
 */

function create(config) {
    // checking programme.
    validateConfigShape(config)

    const { steps, profiles } = config

    // gets initial object
    function getInitialStatus() {
        return {
            step: steps[0].name,
            order: steps[0].multiOrder ? 1 : 0
        }
    }

    // get next status
    function getNextStatus(stepInput, actionInput, maxOrder) {
        const stepName = stepInput.step

        /*
        * TODO write the code without checking for the existence of maxOrder in the beggining (this is leading to repeated code)
        * Maybe you will need to create some abstraction with functions to simplify your code.
        * Some ideas :
        *  - recover the step and the action, validading that it's all ok
        *  - check if the step is multi-order, in this case validate that the max order contains the value for the step
        *  - compute the next status, adapting in case we are multi order / ignore order / etc
        * */

        if (!maxOrder) {
            // TODO create a map to simplify all steps.find
            const step = steps.find(step => step.name === stepName)

            // TODO do not repeat this operation
            if (!step) throw new Error(`Step ${stepInput.step} does not exist.`)

            // check if actionInput is legal, if yes then return the object we expect,if not return undefined
            const action = step.actions.find(
                action => action.name === actionInput
            )

            if (!step.multiOrder) {
                return {
                    step: action.nextStep,
                    order: 0
                }
            } else {
                throw new Error('should declare max order with multi order')
            }
        }

        if (maxOrder[stepName]) {
            // console.log(stepName)

            if (stepInput.order === maxOrder[stepName]) {
                const step = steps.find(step => step.name === stepName)

                const action = step.actions.find(
                    action => action.name === actionInput
                )

                const nextStep = steps.find(step => step.name === action.nextStep)

                return {
                    step: action.nextStep,
                    order: nextStep.multiOrder ? 1 : 0
                }
            }
        }

        if (
            !maxOrder[stepName] &&
            !steps.some(step => step.name === Object.keys(maxOrder)[0])
        ) {
            throw new Error(
                'There are not a property in "max order", which match with those property in "steps"'
            )
        }

        if (0 <= stepInput.order && (!maxOrder[stepName] || stepInput.order < maxOrder[stepName])) {
            const step = steps.find(step => step.name === stepName)

            if (!step) {
                throw new Error(`Step ${stepInput.step} does not exist.`)
            }

            const action = step.actions.find(
                action => action.name === actionInput
            )

            if (!action) {
                throw new Error('action input is incorrect')
            } else {
                /*if enable property witch called ignoreOrder, program will pass all judgement bellowing,
                response an result refused directly. normal, we add this property for action refuse.
                programme bellowing is check if this property is enable.*/
                if (!action.ignoreOrder) {
                    if (stepInput.order === 0) {
                        return {
                            step: action.nextStep,
                            order: stepInput.order + 1
                        }
                    }
                    if (stepInput.order !== 0) {
                        stepInput.order = stepInput.order + 1
                        return stepInput
                    }
                } else {
                    const nextStep = steps.find(step => step.name === action.nextStep)
                    return {
                        step: action.nextStep,
                        order: nextStep.multiOrder ? 1 : 0
                    }
                }
            }
        } else {
            throw new Error('current order input is incorrect')
        }
    }

    // check if this profile has this step
    function isProfileInStep(stepInput, profileInput) {
        const stepName = stepInput.step

        const profile = profiles.find(profile => profile.name === profileInput)

        if (!profile) throw new Error(`Profile ${profileInput} does not exist.`)

        // checking program,if any exception occur, return undefined.
        // for example, stepProfiles is undefined.
        return profile.stepProfiles.some(status => status.step === stepName)
    }

    // check this profile have the action of this step.
    function isProfileActiveInStep(stepInput, profileInput) {
        const stepName = stepInput.step

        const profile = profiles.find(profile => profile.name === profileInput)

        // to ensure profile is existed
        if (!profile) throw new Error(`Profile ${profileInput} does not exist.`)

        // make sure the step is existed
        const stepProfile = profile.stepProfiles.find(
            status => status.step === stepName
        )

        if (!stepProfile) throw new Error(`Step ${stepName} no found.`)

        return stepProfile.active
    }

    return {
        getInitialStatus,
        getNextStatus,
        isProfileInStep,
        isProfileActiveInStep
    }
}

export default create
