const { generateFetchFieldListAction } = require('../../../../../apps/KpModule/actions/api')

export const adminDelegation = {
    name: "adminDelegation",
    object: "BusinessProject",
    category: {
        path: "configuration",
        icon: 'settings'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "fullName", tKey: 'name', width: 500},
            {path: "beginYear", width: 100},

            {path: "currentFunctions", width: 100},
            {path: "file", tKey: "FA"}
        ],
        form: {
            fields: [
                {
                    path: "country", tKey: "country", display: "fullInformations", writable: false,
                    filters: ["inUserTeam"]
                },
                {path: "name", writable: false},

                {path: "comments", tKey: "comment_plural", editeable: false},

                {path:"delegatedUser", filters:["byCountry"], display: "userName"},

                {path: "buttons", hidden: true},
                {path: "workflow", hidden: true},

                {path: "businessProject", hidden: true}
            ],
            onOpen: ({state, store}) => {
                const projectCountry = {country: state.edit.object.data.country}

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-I-adminDelegation.BusinessProject_delegatedUser",
                        store.getState,
                        'form',
                        {data: projectCountry}
                    )
                )
            }
        }
    },
    filters: [
        "byTypeOfOffer",
        {query: () => ({"workflow.step": {$in: ['buildOffer', 'submitOffer', 'realisation'], $exists: true}})}
    ]
}
