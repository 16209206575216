const _ = require('lodash');
const moment = require('moment');
const { workflowMailing } = require('../mails/workflowMails')
const {
    workflowManager,
    updateStatusUsers,
    getWorkflowConfigs,
    initializeWorkflowData,
    getWorkflowObject
} = require("../workflow/workflow");

const { basicContext } = require("../../../utils/contextUtils");

const {
    enhanceProjectComponents,
    initializeReferentialBusiness,
    financialStatementCalculation,
    createBPForAMI
} = require('../businessFollowUp/utils')

export const initializeWorkflowBP = async function(businessProject, context) {

    const workflowConfigs = await getWorkflowConfigs(businessProject.country.id.toString(), context);
    const workflowObject = getWorkflowObject(workflowConfigs);

    if (workflowObject) {

        // create an alert with recomposed ids as strings to be used by the workflow function
        const recomposedBP = {
            ...businessProject,
            id: businessProject.id.toString(),
            country: {id: businessProject.country.id.toString()},
            workflow: workflowObject
        }

        const [staticWorkflows, statusUsers] = initializeWorkflowData(recomposedBP, workflowObject, workflowConfigs);

        // enhance objects after generation
        const enhanceStaticWorkflows = object => ({
            ...object,
            businessProject: new global.ObjectID(businessProject.id),
            teamMember: new global.ObjectID(object.teamMember.id),
            country: new global.ObjectID(businessProject.country.id),
            group: new global.ObjectID(context.group.id)
        });

        const enhanceStatusUsers = object => ({
            ...object,
            businessProject: new global.ObjectID(businessProject.id),
            teamMember: new global.ObjectID(object.teamMember.id),
            country: new global.ObjectID(businessProject.country.id),
            group: new global.ObjectID(context.group.id),
            user: new global.ObjectID(object.user.id)
        });


        await Promise.all([
            staticWorkflows.length && global.db.collection("i.staticWorkflow").insertMany(staticWorkflows.map(enhanceStaticWorkflows)),
            statusUsers.length && global.db.collection("i.statusUser").insertMany(statusUsers.map(enhanceStatusUsers))
        ])

        await global.db.collection("i.businessProject").updateOne(
            {_id: new global.ObjectID(businessProject.id)},
            {$set: {workflow: workflowObject}}
        );

        // const nextStatus = workflowManager.getNextStatus(
        //     _.pick(workflowObject, ['step', 'order']),
        //     'validate',
        //     workflowObject.maxOrders
        // )
        return handleWorkflowException(
            { ...businessProject, workflow: workflowObject },
            _.pick(workflowObject, 'step', 'order'),
            {...context, action:'validate'}
        )
    }
    return { ...businessProject, workflow: workflowObject }
}

export const updateStepWorkflowBP = async function(businessProject, currentStatus, context) {

    const user = context.user && {id: context.user.id, name: context.user.name}
    const date = moment().format("YYYY-MM-DD HH:mm")
    const text = `${context.tc(context.action)} ${context.tc(currentStatus.step)}`
    const group = new global.ObjectID(context.group.id)

    let query = {
        comments: [
            ...businessProject.comments || [],
            { user, date, text, group , _id: new global.ObjectID()}
        ]
    }
    let nextStatus = {}
    if(context.action !== 'save') {
        nextStatus = workflowManager.getNextStatus(
            currentStatus,
            context.action,
            businessProject.workflow.maxOrders
        )

        await updateStatusUsers(businessProject, nextStatus, context)

        query["workflow.step"] = nextStatus.step
        query["workflow.order"] = nextStatus.order
        query["workflow.lastUpdate"] = new Date()

        businessProject.workflow = {
            ...businessProject.workflow,
            ...nextStatus
        }
    }

    // directly set the new status on the BusinessProject collection
    const businessProjectCollection = global.app.I.BusinessProject.collection
    await businessProjectCollection.updateOne(
        { _id: new global.ObjectID(businessProject.id) },
        { $set: query }
    )

    if(context.action !== 'save') {
        return handleWorkflowException(businessProject, nextStatus, context)
    }
    return businessProject
}

const handleWorkflowException = async function(businessProject, nextStatus, context) {

    const hasAnException = await bpHasAnExceptionForThisStep(businessProject, nextStatus, context)

    if(hasAnException) {
        await new Promise((resolve, reject) => {
            enhanceProjectComponents(businessProject, context, e => {
                if (e) reject(e);
                else resolve();
            })
        })

        await new Promise((resolve, reject) => {
            initializeReferentialBusiness(businessProject, context, e => {
                if (e) reject(e);
                else resolve();
            })
        });

        await new Promise((resolve, reject) => {
            financialStatementCalculation(businessProject.id, context, e => {
                if (e) reject(e);
                else resolve();
            })
        });

        await new Promise((resolve, reject) => {
            createBPForAMI(businessProject, context, e => {
                if (e) reject(e);
                else resolve();
            })
        });

        await workflowMailing(businessProject.id, false, false, context)

        return updateStepWorkflowBP(
            {
                ...businessProject,
                workflow: {
                    ...businessProject.workflow,
                    step: nextStatus.step,
                    order: nextStatus.order
                }},
            _.pick(businessProject.workflow, ['step', 'order']),
            {...context, action: 'validate'})
    }
    return businessProject
}

export const bpHasAnExceptionForThisStep = async function(businessProject, status, context) {
    const wfExceptionQuery = {
        ...basicContext(context),
        query: {
            country: new global.ObjectID(businessProject.country.id),
            minTurnover: { $lte: businessProject.estimatedTurnover},
            maxTurnover: { $gte: businessProject.estimatedTurnover},
            typeOfOffers: {$eq: new global.ObjectID(businessProject.typeOfOffer.id)},
            'workflowExceptionSteps.activeProjectStep': status.step
        }
    }

    const wfExceptions = await global.app.I.WorkflowException.find(wfExceptionQuery)

    if(wfExceptions.length) {
        const workflowExceptionStep = wfExceptions[0].workflowExceptionSteps.find(o => o.activeProjectStep.id === status.step)

        const teamMemberQuery = {
            ...basicContext(context),
            query: {
                teamMemberProfile: {$in: workflowExceptionStep.teamMemberProfiles.map(o => new global.ObjectID(o.id))}
            }
        }
        const teamMembers = await global.app.I.TeamMember.find(teamMemberQuery)

        const staticWFQuery = {
            ...basicContext(context),
            query: {
                businessProject: new global.ObjectID(businessProject.id),
                teamMember: {$nin: teamMembers.map(o => new global.ObjectID(o.id))},
                active: true,
                step: status.step,
                order: status.order
            }
        }

        const staticWF = await global.app.I.StatusUser.find(staticWFQuery)

        if (!staticWF.length) {
            return true
        }
    }
    return false
}

export const bpHasAnExceptionForTeamMemberProfile = async function(businessProject, teamMemberProfile, context) {
    const wfExceptionQuery = {
        ...basicContext(context),
        query: {
            country: new global.ObjectID(businessProject.country.id),
            minTurnover: { $lte: businessProject.estimatedTurnover},
            maxTurnover: { $gte: businessProject.estimatedTurnover},
            typeOfOffers: {$eq: new global.ObjectID(businessProject.typeOfOffer.id)},
            workflowExceptionSteps: { "$elemMatch": {
                    activeProjectStep: _.get(businessProject, 'workflow.step'),
                    teamMemberProfiles: new global.ObjectID(teamMemberProfile.id)
                }}
        }
    }

    const wfExceptions = await global.app.I.WorkflowException.find(wfExceptionQuery)

    return !!wfExceptions.length;
}

