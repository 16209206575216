const _ = require('lodash')
const { directoryPath } = require('../templates/index')
const { getCurrentUsers } = require('../workflow/workflow')
const { basicContext } = require("../../../utils/contextUtils");
const { decrypt } = require("../../../utils/crypto");

export async function workflowMailing (businessProjectId, oldObject, sendMailToCurrentUsers, context) {

    const businessProject = await global.app.I.BusinessProject.get(businessProjectId, {...basicContext(context)})
    if (
        !oldObject ||
        !oldObject.workflow ||
        businessProject.workflow.step !== oldObject.workflow.step ||
        businessProject.workflow.order !== oldObject.workflow.order
    ) {

        const currentUsers = await getCurrentUsers(businessProject, context)

        const attachments = ['file', 'excel'].reduce((acc, element) => {
            if (businessProject[element])
                acc.push({
                    id: new global.ObjectID(businessProject[element].id),
                    filename: businessProject[element].filename
                })
            return acc
        }, [])

        const project = {
            status: context.tc(businessProject.workflow.step),
            name: businessProject.name,
            beginYear: businessProject.beginYear?.id,
            country: businessProject.country.name,
            typeOfOffer: businessProject.typeOfOffer.name,
            expectedTurnOver: businessProject.expectedTurnOver,
            commercialTeamMember: businessProject.commercialTeamMember ? businessProject.commercialTeamMember.fullName : "",
            technicalTeamMember: businessProject.technicalTeamMember ? businessProject.technicalTeamMember.fullName : ""
        }

        if(sendMailToCurrentUsers) {
            sendMail({
                users: currentUsers,
                project,
                template: 'forCurrentUsersInWorkflow.html',
                subject: `Pour décision "${project.name} / ${project.country}"`,
                contextUser: context.user,
                attachments
            })
        }

        if ([0, 1].includes(businessProject.workflow.order)) {
            global.app.I.TeamMember.find(
                {
                    data: {country: {id: businessProject.country.id}},
                    group: context.group,
                    filters: ['byCountryAndwillReceiveMails']
                },
                (e, teamMembers) => {
                    if (e) console.log(e)
                    sendMail({
                        users: teamMembers
                            .filter(teamMember => {
                                return !currentUsers.some(
                                    u => u.mail === teamMember.email
                                )
                            }),
                        project,
                        subject: `Information "${project.name} / ${project.country}"`,
                        template: 'forTeamMembers.html',
                        contextUser: context.user,
                        attachments
                    })
                }
            )
        }
    }
}

export async function delegationMailing (businessProjectId, users, context) {

    const businessProject = await global.app.I.BusinessProject.get(businessProjectId, {...basicContext(context)})

    const attachments = ['file', 'excel'].reduce((acc, element) => {
        if (businessProject[element])
            acc.push({
                id: new global.ObjectID(businessProject[element].id),
                filename: businessProject[element].filename
            })
        return acc
    }, [])

    const project = {
        status: context.tc(businessProject.workflow.step),
        name: businessProject.name,
        beginYear: businessProject.beginYear?.id,
        country: businessProject.country.name,
        typeOfOffer: businessProject.typeOfOffer.name,
        expectedTurnOver: businessProject.expectedTurnOver,
        commercialTeamMember: businessProject.commercialTeamMember ? businessProject.commercialTeamMember.fullName : "",
        technicalTeamMember: businessProject.technicalTeamMember ? businessProject.technicalTeamMember.fullName : ""
    }

    sendMail({
        users,
        project,
        template: 'forCurrentUsersInWorkflow.html',
        contextUser: context.user,
        subject: `Pour décision "${project.name} / ${project.country}"`,
        attachments
    })
}

const sendMail = ({ users, project, template, contextUser, attachments, subject }) => {
    const logMessage = () => {
        console.log('...workflow Mailing')
    }
    users.forEach(user => {
        const mail = _.defaults(
            {
                to: decrypt(user.email),
                context: {
                    user: user,
                    contextUser,
                    project
                },
                content: template,
                subject: {
                    template: subject
                },
                templateDir: directoryPath.mails,
                attachments
            },
            defaultMail
        )

        global.mailer.sendMail(mail, logMessage)
    })
}

const defaultMail = {
    from: '"EDFIN" <edfin@keenpoint.com>',
    replyTo: 'edfin@keenpoint.com',
    verbose: {
        general: true
    }
}
