import React from 'react'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import _ from 'lodash'
import Pie from './DonutChart/Pie'
import GraphBoard from './DonutChart/GraphBoard'
import './ChartConainer.css'
import './DonutChart/Graph.css'
import StackedBarChart from '../../components/Dashboard/StackedBarChart'
import LineChart from '../../components/Dashboard/LineChart'
import DynamicStackedBarChart from '../Dashboard/DynamicStackedBarChart'

const maxSize = 6

class ChartContainer extends React.Component {
    constructor(props) {
        super(props)
        const allIds = props.allIds || []
        this.state = {
            allIds: allIds,
            // objects: data,
            objects: allIds.map(id => props.objects[id]),
            axis: props.axis,
            keys: props.keys,
            filters: props.filters,
            currentFilter: props.filters && props.filters[0],
            size: 3
        }
        this.getKey = this.getKey.bind(this)
        this.setCriteria = this.setCriteria.bind(this)
        this.setSize = this.setSize.bind(this)
    }

    setCriteria(value) {
        this.setState({
            currentFilter: value
        })
    }
    getKey(d) {
        return this.state.keys.map(key => d.data[key]).join(', ')
    }

    // gets sum for one criteria
    getSum() {
        const {objects, filters, currentFilter, axis} = this.state
        const filteredObjects = filters
            ? objects.filter(
                o => o.value === currentFilter.value
            )
            : objects
        return _.reduce(
            filteredObjects,
            (acc, o) => acc + Number(o[axis]),
            0
        )
    }

    // sets the number of pies in the donut chart
    setSize(key) {
        const size = this.state.size
        this.setState({
            size:
                key === 'plus'
                    ? size === maxSize ? size : size + 1
                    : size === 0 ? 0 : size - 1
        })
    }

    render() {
        const { setFiltersOpened, filtersOpened, type, title, t } = this.props
        const {filters, objects, currentFilter, size, axis, keys} = this.state

        const filteredObjects = filters
            ? objects.filter(
                o => o.value === currentFilter.value
            )
            : objects

        const sortedObjects = _.sortBy(filteredObjects, o =>
            Number(o[axis])
        ).reverse()

        const finalObjects =
            size + 1 < sortedObjects.length
                ? sortedObjects.slice(0, size)
                : sortedObjects

        // we create "others" when objects length is superior to the pie size
        if (size + 1 < sortedObjects.length) {
            finalObjects.push({
                id: 'others',
                [keys[0]]: 'others',
                [axis]: _.reduce(
                    sortedObjects.slice(size),
                    (acc, o) => acc + Number(o[axis]),
                    0
                )
            })
        }

        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <GraphBoard
                    size={this.state.size}
                    currentFilter={this.state.currentFilter}
                    setCriteria={this.setCriteria}
                    setSize={this.setSize}
                    setFiltersOpened={setFiltersOpened}
                    filtersOpened={filtersOpened}
                    filters={this.state.filters}
                    type={type}
                />
                <div style={{ height: '100%' }}>
                    {
                        title
                            ? type === "dynamicBarChart"
                                ? <h4 className="ChartContainerTitle"> {title} / {_.get(this.props.formValues, 'indicator.name')} </h4>
                                : <h4 className="ChartContainerTitle"> {title} </h4>
                            : ''
                    }
                    <AutoSizer>
                        {({ height, width }) => {
                            const radius = 3 * height / 10 - 20
                            const x = width / 2
                            const y = height / 2


                            if(type === 'lineChart') {
                                return <LineChart
                                    w={width}
                                    h={height}
                                    title={title}
                                    data={this.state.objects[0]?.data}
                                    dataKeys={this.state.objects[0]?.dataKeys}
                                    t={this.props.t}
                                />
                            }

                            if(type === 'donutChart') {
                                return <svg
                                    className="donutChart"
                                    width={width}
                                    height={height}
                                >
                                    <Pie
                                        x={x}
                                        y={y}
                                        radius={radius}
                                        data={finalObjects}
                                        axis={this.state.axis}
                                        sum={this.getSum()}
                                        getKey={this.getKey}
                                        criteria={this.state.axis}
                                    />
                                </svg>
                            }

                            if(type === "barChart") {
                                return <StackedBarChart
                                    w={width}
                                    h={height}
                                    title={title}
                                    data={this.state.objects}
                                    keys={this.state.keys}
                                    t={t}
                                />
                            }

                            if(type === "dynamicBarChart") {
                                return <DynamicStackedBarChart
                                    w={width}
                                    h={height}
                                    title={title}
                                    data={this.state.objects}
                                    keys={this.state.keys}
                                    formValues={this.props.formValues}
                                    t={this.props.t}
                                />
                            }
                        }}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

export default ChartContainer
