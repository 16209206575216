import {translateName} from "../../../../../utils/i18n";

const _ = require('lodash')

const getElementFullName = (object, context) => {
    if (object.joinedEntity === 'Organization') {
        return `${translateName(object.organizationalAxis.name, _.get(context, "language.id"))} : ${object.name}`
    } else {
        return ` Business unit : ${object.name}  ( ${(object.attachments || []).map( att => att.name).join(', ')} )`
    }
}

export const entity = require('../../../../utils/entityJoin').joinEntities(
    {
        name: 'OrganizationAndMesh',
        fields: [
            'name',
            {
                path: 'fullName',
                fieldPath: ['organizationalAxis.name', 'attachments.name'],
                $f: (object, context, callback) => {
                    callback(null, getElementFullName(object, context))
                }
            }
        ],
        filters: [
            {
                name: 'inWorkflowEnabledAxe',
                isDefault: false,
                fieldPath: ['organizationalAxis.workflowEnabledAxe'],
                match: function(object) {
                    const isInWorkflowEnabledAxe = !!_.get(object, 'organizationalAxis.workflowEnabledAxe')
                    return isInWorkflowEnabledAxe && (object.joinedEntity === 'Organization')
                }
            }
        ]
    },
    ['Organization', 'OrganizationalMesh']
)
