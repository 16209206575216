import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {
    changeFieldDefault,
    changeFieldDisabled, changeFieldProperty, FETCH_FORM_DATA_LISTS_DONE, setFieldStartDate,
    setFieldVisibility,
    setFormButtons
} from "../../../../../apps/KpModule/actions";
import moment from "moment";
import {monthRangeToList, isArrayInArrayOfArrays, fiscalYearsMonth} from "../../utils/usefulFunctions";
import _ from "lodash";
import {
    arbitrateButton,
    delegateButton,
    draftButton,
    returnButton,
    transmitButton,
    validateDemandeurButton,
    warnAndValidateButton
} from "../../utils/demandUtils";
import {getDataListList} from "../../../../../apps/KpModule/selectors";

export const module_ = {
    object: 'Demand',
    name: 'Instruction',
    category: {
        path: 'Projet',
    },
    tKey: 'Instruction',
    defaultSortBy: 'demandNumber',
    defaultSortDirection: 'DESC',
    newable: false,
    viewMap: {
        dt: [
            { path: 'status', tKey: 'statut', display: 'name2'},
            {path: 'demandNumber', tKey: 'N° Projet'},
            {path: 'relatedProject', tKey: 'projet principal', initiallyNotVisible: true, display: 'demandNumberAndTitle'},
            {path: 'title'},
            {
                path: 'organizationAndMesh',
                tKey: 'organisation',
                //display: 'fullName'
            },
            {path: 'startMonth', tKey: 'début'},
            {path: 'endMonth', tKey: 'fin'},
            {path: 'largeImputationsTotalAmount', width: 230},
            {path: 'totalAmount'},
            {path: 'noDeleteButtonAccess', hidden: true},
            {path: 'greenStyledRow', hidden: true},
        ],
        form: {
            fields : [
                {
                    path: 'status',
                    tKey: 'statut',
                    display: 'name2',
                    fieldPath: ['id', 'name2'],
                    disabled: true,
                    //hidden: true,
                    //default: {id:'1'},
                    //display: 'name2'
                },
                {path: 'demandNumber',tKey: 'N° Projet', disabled: true},
                {path: 'relatedProject', tKey: 'projet principal', display: 'demandNumberAndTitle', disabled: true},
                {
                    path: 'organizationAndMesh',
                    tKey: 'organisation',
                    display: 'nameWithOrganizationalAxis',
                    disabled: true,
                    //editable: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, {store}) => {
                            if (newValue) {
                                store.dispatch(
                                    generateFetchFieldListAction(
                                        "m-SE-instruction.Imputation_organizationalMesh",
                                        store.getState,
                                        'form',
                                        {
                                            data: {
                                                organization : newValue?.id // newValue? newValue.organizationalAxis : null
                                            }
                                        }
                                    )
                                )
                            }
                        }
                    }
                },
                {
                    path: 'demandCategory',
                    tKey: 'processType',
                    disabled: true
                },
                {
                    path: 'demandNature',
                    tKey: 'category',
                    fieldPath: ['id', 'code', 'name'],
                    disabled: true,
                    translateName: true,
                },
                {
                    path: 'title',
                    required: true,
                },
                {path: 'description', type: 'richTextEditor', required: true, },
                {path: 'enjeux', type: 'richTextEditor', required: true},
                {path: 'objectifs', type: 'richTextEditor', required: true},
                {path: 'pointsOfAttention', tKey: `points d'attention`},
                {
                    path: 'startMonth',
                    tKey: 'début',
                    required: true,
                    startDate: moment().format("YYYY-MM"),
                    editable: true,
                    //endDate: '',
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const state = store.getState()
                            const endMonthField = module.viewMap.form.fields.find(field => field.path === 'endMonth')
                            const endMonth = endMonthField.getValue()
                            if (newValue) {
                                store.dispatch(setFieldStartDate(endMonthField.id, newValue))
                                if ( !!endMonth && !moment(endMonth).isSameOrAfter(newValue)){
                                    endMonthField.setValue(null)
                                }
                            }
                        }
                    }
                },
                {
                    path: 'endMonth',
                    tKey: 'fin',
                    required: true,
                    editable: true,
                    startDate: moment().format("YYYY-MM"),
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const state = store.getState()
                            const nonClosedFiscalYears = getDataListList(
                                state,
                                "Instruction-FiscalYears"
                            )
                            const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                            const startMonthField = module.viewMap.form.fields.find( field => field.path === 'startMonth')
                            const imputationsMonthAndAmountListField = imputationsField.viewMap.form.fields.find( field => field.path === 'monthAndAmountList')
                            if (newValue){
                                const startMonth = startMonthField.getValue()
                                const endMonth = newValue
                                const fiscalYearForThisDemand = nonClosedFiscalYears.filter( fiscalYear => {
                                    const fiscalYearStartDate = moment(fiscalYear.fiscalYearRange[0])
                                    const fiscalYearEndDate = moment(fiscalYear.fiscalYearRange[1])
                                    const firstCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                                    const secondCondition = fiscalYearStartDate.isSameOrAfter(startMonth, 'month') && fiscalYearEndDate.isSameOrBefore(endMonth, 'month')
                                    const thirdCondition = fiscalYearStartDate.isSameOrBefore(endMonth, 'month') && fiscalYearEndDate.isSameOrAfter(endMonth, 'month')
                                    const fourthCondition = fiscalYearStartDate.isSameOrBefore(startMonth, 'month') && fiscalYearEndDate.isSameOrAfter(startMonth, 'month')
                                    return firstCondition || secondCondition || thirdCondition || fourthCondition
                                })
                                fiscalYearForThisDemand.sort((a, b) => {
                                    const startA = moment(a.fiscalYearRange[0] );
                                    const startB = moment(b.fiscalYearRange[0]);
                                    // Compare the startDates
                                    if (startA.isBefore(startB)) {
                                        return -1; // a comes first
                                    } else if (startA.isAfter(startB)) {
                                        return 1; // b comes first
                                    } else {
                                        return 0; // a and b are the same
                                    }
                                });
                                const months = fiscalYearsMonth(fiscalYearForThisDemand, startMonth, endMonth)
                                const monthAndAmountList = months.length ? months.map( month=> {
                                    //const monthAndAmountList= _.get(state, 'edit.object.data.imputations.monthAndAmountList') || []
                                    //const previousMonthAndAmountList = getMonthAndAmountList(monthAndAmountList, role)
                                    return {"month" : month.month, "amount": 0, fiscalYear: {id: month.fiscalYearID}}
                                }) : []
                                store.dispatch(changeFieldDefault(imputationsMonthAndAmountListField.id, monthAndAmountList))
                            }
                        }
                    }
                },
                {
                    path: 'largeImputations',
                    disabled: true,
                    type: 'dtObjects',
                    fields: [
                        {path: "organization", tKey: 'direction', display: 'name', translateName: true},
                        {path: "amount", type: "editText"}
                    ]
                },
                {
                    path: 'largeImputationsTotalAmount',
                    disabled: true
                },
                {
                    path: 'imputations',
                    tKey: 'plannedCommitment',
                    type: 'accordion',
                    viewMap: {
                        dt: [
                            'objet',
                            {path: 'organizationalMesh', tKey: 'business unit'},
                            {path: 'imputationType', translateName: true},
                            {path: 'currency', display: 'symbol'},
                            {path: 'totalAmountPerImputation', tKey: 'montant (k)',},
                        ],
                        form: {
                            fields : [
                                {
                                    path: 'objet',
                                    required: true,
                                    validations: [
                                        function(formValues) {
                                            return function validation(value, values) {
                                                const alreadyExistingImputationsButThisOne = formValues.imputations.filter(imp => imp.id.toString() !== values.id?.toString())
                                                const alreadyExistingImputationsIdentifiers = alreadyExistingImputationsButThisOne.map( imp => [imp.objet, imp.organizationalMesh.id.toString(), imp.imputationType.id.toString()])
                                                const thisImputationIdentifiers = [values.objet, values.organizationalMesh?.id.toString(), values.imputationType?.id.toString()]
                                                const thisCombinaisonAlreadyExists = isArrayInArrayOfArrays(thisImputationIdentifiers, alreadyExistingImputationsIdentifiers)
                                                return thisCombinaisonAlreadyExists ? 'la combinaison objet-BU-Type est déjà utilisée dans ce projet' : undefined
                                            }
                                        }
                                    ]
                                },
                                {
                                    path: 'organizationalMesh',
                                    tKey: 'business unit',
                                    filters:['byOrganizationOrMesh'],
                                    validations: [
                                        function(formValues) {
                                            return function validation(value, values) {
                                                    const alreadyExistingImputationsButThisOne = formValues.imputations.filter(imp => imp.id.toString() !== values.id?.toString())
                                                    const alreadyExistingImputationsIdentifiers = alreadyExistingImputationsButThisOne.map( imp => [imp.objet, imp.organizationalMesh.id.toString(), imp.imputationType.id.toString()])
                                                    const thisImputationIdentifiers = [values.objet, values.organizationalMesh?.id.toString(), values.imputationType?.id.toString()]
                                                    const thisCombinaisonAlreadyExists = isArrayInArrayOfArrays(thisImputationIdentifiers, alreadyExistingImputationsIdentifiers)
                                                    return thisCombinaisonAlreadyExists ? 'la combinaison objet-BU-Type est déjà utilisée dans ce projet' : undefined
                                            }
                                        }
                                    ]
                                },

                                    {
                                        path: 'imputationType',
                                        required: true,
                                        translateName: true,
                                        validations: [
                                            function (formValues) {
                                                return function validation(value, values) {
                                                    const alreadyExistingImputationsButThisOne = formValues.imputations.filter(imp => imp.id.toString() !== values.id?.toString())
                                                    const alreadyExistingImputationsIdentifiers = alreadyExistingImputationsButThisOne.map( imp => [imp.objet, imp.organizationalMesh.id.toString(), imp.imputationType.id.toString()])
                                                    const thisImputationIdentifiers = [values.objet, values.organizationalMesh?.id.toString(), values.imputationType?.id.toString()]
                                                    const thisCombinaisonAlreadyExists = isArrayInArrayOfArrays(thisImputationIdentifiers, alreadyExistingImputationsIdentifiers)
                                                    return thisCombinaisonAlreadyExists ? 'la combinaison objet-BU-Type est déjà utilisée dans ce projet' : undefined
                                                }
                                            }]
                                    },
                                {path: 'currency', display: 'symbol'},
                                {
                                    path: 'monthAndAmountList',
                                    tKey: 'montant mensuel',
                                    //default: [{month: '2022-11', amount: 0}],
                                    fieldPath: ['id', 'month', "amount", "fiscalYear"],
                                    type: 'dtObjects',
                                    hidden: false,
                                    fields: [
                                        {path: "month"},
                                        {path: "amount", tKey: 'Montant (k)',type: "editText"},
                                        {path: 'fiscalYear', display: 'code', hidden: true}
                                    ],
                                    subscriptions: {
                                        onChange (newValue, oldValue, {module, store}){
                                            if (newValue){
                                                let total = 0
                                                newValue.forEach(object => {
                                                    total+= parseFloat(object.amount)
                                                })
                                                const imputationsField = module.viewMap.form.fields.find( field => field.path === 'imputations')
                                                const totalAmountPerImputationField = imputationsField.viewMap.form.fields.find( field => field.path === 'totalAmountPerImputation')
                                                totalAmountPerImputationField.setValue(total)
                                            }
                                        }
                                    }
                                },
                                {
                                    path: 'totalAmountPerImputation',
                                    tKey: 'montant (k)',
                                    disabled: true,
                                    //default: 0,
                                },
                                { path: 'description', type: 'textarea'},
                            ],
                        }
                    },

                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            if (newValue){
                                let total = 0
                                newValue.forEach(object => {
                                    total+= parseFloat(object.totalAmountPerImputation)
                                })
                                const totalAmountField = module.viewMap.form.fields.find( field => field.path === 'totalAmount')
                                totalAmountField.setValue(total)
                            }
                        }
                    },
                    /*
                    validations: [
                        function(formValues) {
                            return function validation(value) {
                                console.log("ok", formValues)
                                console.log("okok", value)
                                return 'toto'
                            }
                        }
                    ]

                     */

                },
                {
                    path: 'totalAmount',
                    tKey: 'plannedTotalAmount',
                    disabled: true,
                },
                {
                    path: 'plafond',
                    tKey: "estimatedFinancialBracket",
                    fieldPath: ['id', 'code', "min", "max"],
                    hidden: false,
                    display: 'code',
                    disabled: true
                    //filters: ['byAmount']
                },
                {
                    path: 'indicatorKeys',
                    type: 'accordion',
                    removable: true,
                    viewMap: {
                        dt: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur'},
                            'objectif'
                        ],
                        form: [
                            {path: 'indicatorKeyList', tKey: 'Indicateur'},
                            {path: 'objectif', required: true}
                        ]
                    }
                },
                {
                    path: 'comments',
                    subscriptions: {
                        onChange(newValue, oldValue, {module, store}) {
                            const commentsField = module.viewMap.form.fields.find(field=>field.path==='comments')
                            if ( newValue && oldValue && newValue.length !== oldValue.length ) {
                                let commentsCopy = _.cloneDeep(newValue)
                                const commentsSorted = commentsCopy.sort((a, b) => moment(a.date).format('YYYY-MM-DD HH:MM:ss') - moment(b.date).format('YYYY-MM-DD HH:MM:ss'))
                                const lastComment = commentsSorted[commentsSorted.length-1]
                                lastComment.date = moment().format("YYYY-MM-DD HH:mm:ss")
                                const comments = commentsSorted.slice(0, newValue.length - 1)
                                comments.push(lastComment)
                                commentsField.setValue(comments)
                            }
                        }
                    }
                },
                'files',
                {
                    path: 'delegateToCheckBox',
                    tKey: 'je souhaite déléguer ma décision',
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const delegateToField = module.viewMap.form.fields.find(field=>field.path==='delegateTo')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(delegateToField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    store.dispatch(setFormButtons([delegateButton, returnButton]))
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(delegateToField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }}
                },
                {
                    path: 'delegateTo',
                    filters: [ 'isInGroupModel', 'isNotCurrentContributor' ],
                    clearable: false
                },
                {
                    path: 'currentContributors',
                    hidden: true
                },
                {
                    path: 'arbitrateCheckBox',
                    tKey: `je souhaite demander un arbitrage`,
                    subscriptions : {
                        onChange (newValue, oldValue, {module, store}){
                            const arbitrateField = module.viewMap.form.fields.find(field=>field.path==='arbitrateText')
                            const arbitrationTypeField = module.viewMap.form.fields.find(field=>field.path==='arbitrationType')
                            const buttonsField = module.viewMap.form.fields.find(field=>field.path==='buttons')
                            const hasOnlyReturnButton = buttonsField.getValue() && buttonsField.getValue().length === 1
                            const arbitrationType = arbitrationTypeField.getValue()
                            if (newValue===true){
                                store.dispatch(setFieldVisibility(arbitrateField.id, true))
                                if (hasOnlyReturnButton){
                                    store.dispatch(setFormButtons(buttonsField.getValue()))
                                }
                                else {
                                    if ( arbitrationType && arbitrationType.id === "1" ){
                                        store.dispatch(setFormButtons([arbitrateButton, returnButton]))
                                    }
                                    else if ( arbitrationType && arbitrationType.id === "2"){
                                        store.dispatch(setFormButtons([warnAndValidateButton, returnButton]))
                                    }
                                }
                            }
                            else{
                                store.dispatch(setFieldVisibility(arbitrateField.id, false))
                                store.dispatch(setFormButtons(buttonsField.getValue() || [ draftButton, validateDemandeurButton, transmitButton, returnButton] ))
                            }
                        }}
                },
                {
                    path : 'arbitrateText',
                    tKey: `justification`,
                    type: 'richTextEditor'
                },
                {
                    path: 'workflow',
                    //fieldPath:['step', 'order'],
                    hidden: true
                },
                {
                    path: 'contributorsFunctions',
                    //fieldPath: ['id'],
                    hidden: true,
                },
                {
                    path: 'consultantsFunctions',
                    hidden: true,
                },
                {
                    path: 'allArbitratorsFunctions',
                    hidden: true,
                },
                {
                    path: 'delegationHistory',
                    hidden: true
                },
                {path: 'buttons', hidden: true, default: [draftButton, validateDemandeurButton, transmitButton, returnButton]},
                {
                    path: 'arbitrateTextList',
                    //fieldPath:['step', 'order'],
                    //hidden: true
                    type: 'accordion',
                    viewMap: {
                        dt: [
                            {path: 'user', display: 'name'
                            },
                            'step',
                            'order',
                            "arbitrationType",
                            "date"
                        ],
                        form: [
                            {
                                path: 'user',
                                display: 'name',
                            },
                            'step',
                            'order',
                            {path: 'motif', type: 'richTextEditor'},
                            "arbitrationType",
                            "date"
                        ]
                    },
                    hidden: true
                },
                {path: 'arbitratorsFunctions', hidden: true},
                {path: 'followFunctions', hidden: true},
                {path: 'alreadyTreatedByFunctions', hidden: true},
                {
                    path: 'arbitorNotYetActive',
                    hidden: true,
                    default : false,
                    required: false
                },
                {
                    path: 'largeImputationsOption',
                    hidden: true
                    //type: 'boolean',
                    //nullable: true
                },
                {
                    path: 'arbitrationType',
                    hidden: true,
                    required: false,
                    default: null
                },
                {
                    path: 'delegationOption',
                    hidden: true,
                    required: false,
                },
                {
                    path: 'startFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'endFiscalYear',
                    tKey: 'Exercice',
                    hidden: true
                },
                {
                    path: 'category',
                    hidden: true
                }
            ],
            dataLists: [
                "Instruction-FiscalYears",
                "Instruction-Currency"
            ],
            onOpen: ({ module, state, store, t }) => {
                const objectMode = state.ui.objectMode
                const demandNumberField = module.viewMap.form.fields.find( field => field.path === 'demandNumber')
                const arbitrateCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'arbitrateCheckBox')
                const delegationOptionField = module.viewMap.form.fields.find( field => field.path === 'delegationOption')
                const delegateToCheckBoxField = module.viewMap.form.fields.find( field => field.path === 'delegateToCheckBox')
                const statusField = module.viewMap.form.fields.find( field => field.path === 'status')
                const largeImputationsField = module.viewMap.form.fields.find( field => field.path === 'largeImputations')
                const largeImputationsTotalAmountField = module.viewMap.form.fields.find( field => field.path === 'largeImputationsTotalAmount')

                const atLeastOneLargeImputations = !!largeImputationsField && !!largeImputationsField.getValue() && !!largeImputationsField.getValue().length
                const hasNoDelegation = delegationOptionField.getValue() && delegationOptionField.getValue().id === "1"

                delegateToCheckBoxField.setValue(false)
                arbitrateCheckBoxField.setValue(false)

                const organizationAndMeshField = module.viewMap.form.fields.find( field => field.path === 'organizationAndMesh')
                const contributorsFunctionsField = module.viewMap.form.fields.find( field => field.path === 'contributorsFunctions')
                const demandOrganization = organizationAndMeshField.getValue()
                const contributorsFunctions = contributorsFunctionsField.getValue()

                const demandCategoryField = module.viewMap.form.fields.find( field => field.path === 'demandCategory')
                store.dispatch(setFieldVisibility(demandCategoryField.id, false))

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-SE-instruction.Demand_delegateTo",
                        store.getState,
                        'form',
                        {
                            data: {
                                demandOrganization : demandOrganization,
                                contributorsFunctions : contributorsFunctions
                            }
                        }
                    )
                )

                store.dispatch(setFieldVisibility(delegateToCheckBoxField.id, !hasNoDelegation))
                store.dispatch(setFieldVisibility(largeImputationsField.id, atLeastOneLargeImputations))
                store.dispatch(setFieldVisibility(arbitrateCheckBoxField.id, false))
                store.dispatch(setFieldVisibility(largeImputationsTotalAmountField.id, atLeastOneLargeImputations))
                store.dispatch(setFieldVisibility(statusField.id, objectMode !== 'newObject'))
                store.dispatch(setFieldVisibility(demandNumberField.id, objectMode !== 'newObject'))

                const referenceCurrencies = getDataListList(state, "Instruction-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("e_largeImputationsTotalAmount", 'tKey', `${t("largeImputationsTotalAmount")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_largeImputations.l_amount", 'tKey', `${t("amount")} (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("e_totalAmount", 'tKey', `${t("plannedTotalAmount")} (k${referenceCurrency.symbol})`))
                    //store.dispatch(changeFieldProperty("e_imputations.l_totalAmountPerImputation", 'tKey', `${t("amount")} (k${referenceCurrency.symbol})`))
                    //store.dispatch(changeFieldProperty("e_imputations.e_monthAndAmountList.l_amount", 'tKey', `${t("largeImputationsTotalAmount")} (k${referenceCurrency.symbol})`))
                }
            }
        }
    },
    actionSubscriptions: [
        {
            actionType: FETCH_FORM_DATA_LISTS_DONE,
            subscription: ({ store }) => {
                const state = store.getState()
                const referenceCurrencies = getDataListList(state, "Instruction-Currency")
                if (!!referenceCurrencies && referenceCurrencies.length === 1){
                    const referenceCurrency = referenceCurrencies[0]
                    store.dispatch(changeFieldProperty("l_totalAmount", 'tKey', `engagement (k${referenceCurrency.symbol})`))
                    store.dispatch(changeFieldProperty("l_largeImputationsTotalAmount", 'tKey', `engagement estimé (k${referenceCurrency.symbol})`))
                }
            }
        },
    ],
    accesses: [
        {
            id: "Instruction-FiscalYears",
            entity: "FiscalYear",
            fieldPath: ['id', 'code', 'fiscalYearRange'],
            filters: ['nonClosed']
        },
        {
            id: "Instruction-Currency",
            entity: "Currency",
            fieldPath: ['id', 'symbol'],
            filters: ['isReferenceCurrency']
        }
    ],
    filters: ["byModuleStep"]
}
